.container {
  margin: 72px 0px;
  background: radial-gradient(
    79.61% 165.55% at -7.98% -35.48%,
    #727272 0%,
    #2d2d2d 100%
  );
  /* height: 280px; */
  padding: 48px;
  border-radius: 24px;

  > h1 {
    font-family: DM Sans;
    font-size: 42px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #ffffff;
  }

  > p {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--black-50);
  }
}

.TalkToUsBTn {
  display: flex;
  justify-content: center;
  margin-top: 48px;

  > button {
    width: 119px;
    height: 48px;
    padding: 12px 24px;
    border-radius: 40px;
    cursor: pointer;

    > span {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--black-500);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    margin: 48px 16px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    margin: 48px 16px;
    padding: 32px 16px 16px 16px;

    > h1 {
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }

    > p {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .TalkToUsBTn {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    > button {
      width: 100%;
      height: 40px;
      padding: 10px 24px;
      border-radius: 40px;

      > span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
