.footer-container {
  padding: 5% 5% 2% 5%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #1b243b;

  ul {
    padding: 0;
    color: #fff; /* Keep white color for lists */
  }

  p,
  h2,
  h1,
  ul,
  b {
    color: #eaeaea; /* Default text color */
    font-size: 16px;
    line-height: 18px;
    margin: 0;
    padding: 8px;
  }

  article {
    color: #eaeaea; /* Default text color for articles */
    line-height: 22px;
    font-weight: 300;
  }

  .footer-header {
    display: flex;
    justify-content: space-between;
  }

  .nav-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -20px;
  }

  .nav-link-container a {
    color: #eaeaea;
  }

  .footer-logo {
    width: 13%;
  }

  .dashed-line {
    padding: 0px;
    border: 1px dashed #41558d;
    margin: 40px 0px;
  }
}

.accordion_footer {
  margin-left: -10px;
  background-color: transparent;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fav-icons-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding: 8px;
}

.accordion-sign {
  display: none;
}

#copyrightssection {
  font-size: 16px;
  text-align: center;
  margin: 20px 0px 6px 0px;
}

/* Mobile view styles */
@media (max-width: 768px) {
  .accordion-sign {
    display: inline-block;
    color: white;
  }

  .footer-container {
    padding: 5%;
    flex-direction: column;
    background-color: #1b243b;
    color: #eaeaea;
    font-size: 16px;
    line-height: 1.5;
  }

  .footer-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-item {
    margin-bottom: 20px;
  }

  .footer-item ul {
    list-style: none;
    padding: 0;
  }

  .fav-icons-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding: 8px;
  }

  .nav-link-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .accordion-item {
    width: 100%;
  }

  .accordion_footer {
    background-color: #1b243b;
    padding: 8px 12px;
    text-align: left;
    outline: none;
    transition: background-color 0.3s ease;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
  }

  .accordion_footer .footer_active {
    background-color: #7499d6;
  }

  .panel_footer {
    display: none;
    padding: 0 18px;
    background-color: #1b243b;
    overflow: hidden;
  }

  .panel_footer.show {
    display: block;
  }

  .panel_footer ul {
    padding: 8px 0;
  }

  .dashed-line {
    border: 1px dashed #41558d;
    margin: 40px 0;
  }
}
