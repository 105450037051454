.tabs {
  display: flex;
  position: relative;
  padding: 0.15rem;
  border-radius: 50px;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  color: #41558d;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  font-size: 1rem;
  font-weight: 200;
  border-radius: 12px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  color: #6e6e6e;
}

.glider {
  position: absolute;
  display: flex;
  height: 50px;
  width: 33.3%;
  background-color: #e7edfc;
  z-index: 1;
  border-radius: 12px;
  transition: 0.25s ease-out;
  box-shadow: 0px 4px 4px 0px #41558d0a;
  border: 2px solid #41558d;
}
