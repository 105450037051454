.content {
  max-width: 1128px;
  display: flex;
  gap: 20px;
}

.card {
  width: 362.67px;
  height: 577.33px;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}

.heading {
  height: 88.33px;

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    gap: 12px;

    > div {
      height: 28.33px;
      width: 28.33px;
      border-radius: 50%;
      background-color: #e8efff;
      display: flex;
      justify-content: center;
      align-items: center;

      > span {
        color: #6780c6;
        font-size: 16.19px;
      }
    }
    > p {
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      letter-spacing: -0.04em;
    }
  }

  > p {
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
    color: #575757;
  }
}

.planFor {
  height: 201px;
  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.04em;
  }

  > div {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    > div {
      display: flex;
      gap: 8px;

      > div:nth-of-type(1) {
        height: 21px;
        width: 21px;

        > span {
          color: #80b870;
        }
      }

      > div:nth-of-type(2) {
        color: #575757;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}

.pricing {
  height: 72px;
  > div {
    display: flex;
    gap: 8px;
    align-items: end;
    height: 48px;
    > p:nth-of-type(1) {
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      letter-spacing: -0.04em;
    }

    > p:nth-of-type(2) {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.04em;
      text-align: left;
      text-decoration-line: line-through;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #636363;
    }

    > p:nth-of-type(3) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.04em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #242f4e;
    }
  }

  > p {
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.addCharge {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #777777;

  > span {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #41558d;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
    background-color: #41558d;
    color: #ffffff;
    height: 48px;
    border-radius: 7px;
    cursor: pointer;
  }
}

.reccomended {
  width: 117px;
  height: 34px;
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #242f4e;
  border-radius: 31.47px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -17px;
  left: 35%;

  > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 17.38px;
    letter-spacing: -0.04em;
    color: #ffffff;
  }
}

@media screen and (max-width: 1023px) {
  .content {
    /* border: 1px solid red; */
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .card {
    width: 320px;
    box-sizing: border-box;
  }
}
