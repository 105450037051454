.container {
  background-color: white;
  height: 587px;
  border-radius: 32px;
  border: 1px solid #d3dbec;
}

.margin_container {
  margin: 32px;
  display: flex;
  gap: 96px;
  max-height: 532px;
  justify-content: space-between;
}

.inner_container1 {
  width: 611px;
}

.inner_container1 > h1 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.04em;
}

.inner_container2 {
  width: 349px;
  max-height: 468px;
  /* display: fl  ex; */
  /* flex-direction: column; */
  /* flex-grow: 1; */
  /* justify-content: space-between; */
}

.sliderTitle {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6e6e6e;
}

.consistency {
  max-height: 50px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #41558d;
  background-color: #e7edfc;
  display: flex;
  align-items: center;
}

.spanContent {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #41558d;
}

.spanContent > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.graph_container {
  border: 1px solid #d3dbec;
  border-radius: 24px;
  margin-bottom: 16px;
  padding: 24px;
}

.graph_container > h1 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.04em;
  text-align: center;
}

.donut {
  display: flex;
  justify-content: center;
}

.startInvesting {
  background-color: #41558d;
  width: 100%;
  height: 48px;
  padding: 12px 12px 12px 16px;
  gap: 8px;
  border-radius: 12px;
}

.btnContent {
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.legend {
  margin-top: 54px;
}

.l1 {
  display: flex;
  justify-content: space-between;
}

.l1_left {
  display: flex;
  gap: 8px;
}

.colorLegend1 {
  background-color: #41558d;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.legendTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.legendPrice {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.l2 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 16px;
}

.colorLegend2 {
  background-color: #82b770;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.horizontalRule {
  color: #d3dbec;
}

.FinalAmount {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 12px 0px 0px 0px;
}

.FinalAmountTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@media screen and (min-width: 868px) and (max-width: 1023px) {
  .container {
    background-color: white;
    max-height: 1116px;
    border-radius: 32px;
    border: 1px solid #d3dbec;
  }

  .margin_container {
    margin: 32px;
    display: flex;
    gap: 26px;
    max-height: 532px;
    justify-content: space-between;
  }

  .inner_container1 {
    width: 611px;
  }

  .inner_container1 > h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.04em;
  }

  .inner_container2 {
    width: 349px;
    max-height: 468px;
  }

  .sliderTitle {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6e6e6e;
  }

  .consistency {
    max-height: 50px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #41558d;
    background-color: #e7edfc;
    display: flex;
    align-items: center;
  }

  .spanContent {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #41558d;
  }

  .spanContent > span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .graph_container {
    border: 1px solid #d3dbec;
    border-radius: 24px;
    margin-bottom: 16px;
    padding: 24px;
  }

  .graph_container > h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.04em;
    text-align: center;
  }

  .donut {
    display: flex;
    justify-content: center;
  }

  .startInvesting {
    background-color: #41558d;
    width: 100%;
    height: 48px;
    padding: 12px 12px 12px 16px;
    gap: 8px;
    border-radius: 12px;
  }

  .btnContent {
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .legend {
    margin-top: 54px;
  }

  .l1 {
    display: flex;
    justify-content: space-between;
  }

  .l1_left {
    display: flex;
    gap: 8px;
  }

  .colorLegend1 {
    background-color: #41558d;
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  .legendTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .legendPrice {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .l2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .colorLegend2 {
    background-color: #82b770;
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  .horizontalRule {
    color: #d3dbec;
  }

  .FinalAmount {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 12px 0px 0px 0px;
  }

  .FinalAmountTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

/* Mobile View */
@media screen and (max-width: 867px) {
  .container {
    background-color: white;
    max-height: 100%;
    height: 1136px;
    border-radius: 0px;
    border: 1px solid #d3dbec;
  }

  .margin_container {
    margin: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    max-height: 532px;
    /* justify-content: space-between; */
  }

  .inner_container1 {
    width: 100%;
  }

  .inner_container1 > h1 {
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.04em;
  }

  .inner_container2 {
    width: 100%;
    max-height: 468px;
  }

  .sliderTitle {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6e6e6e;
  }

  .consistency {
    max-height: 84px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #41558d;
    background-color: #e7edfc;
    display: flex;
    align-items: center;
  }

  .spanContent {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #41558d;
  }

  .spanContent > span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .graph_container {
    border: 1px solid #d3dbec;
    border-radius: 24px;
    margin-bottom: 16px;
    padding: 24px;
  }

  .graph_container > h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.04em;
    text-align: center;
  }

  .donut {
    display: flex;
    justify-content: center;
  }

  .startInvesting {
    background-color: #41558d;
    width: 100%;
    height: 48px;
    padding: 12px 12px 12px 16px;
    gap: 8px;
    border-radius: 12px;
  }

  .btnContent {
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .legend {
    margin-top: 54px;
  }

  .l1 {
    display: flex;
    justify-content: space-between;
  }

  .l1_left {
    display: flex;
    gap: 8px;
  }

  .colorLegend1 {
    background-color: #41558d;
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  .legendTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .legendPrice {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .l2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .colorLegend2 {
    background-color: #82b770;
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  .horizontalRule {
    color: #d3dbec;
  }

  .FinalAmount {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 12px 0px 0px 0px;
  }

  .FinalAmountTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}
