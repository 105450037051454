.escalate-matrix-container {
  width: 100%; /* Ensures full width within its container */
  overflow-x: auto; /* Enables horizontal scroll */
  margin: 20px 0px 10px 0px;
}

.escalate-matrix {
  border-collapse: collapse;
  width: 80%; /* Adjusts table width to content */
  table-layout: auto; /* Fixes column widths for scrolling */
  margin: 10px auto; /* Centers table horizontally */
}

.escalate-matrix th,
.escalate-matrix td {
  padding: 10px;
  border: 1px solid #a7aaaf;
  height: 50px;
  /* width: 150px; Removed fixed width for default */
}

.title {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.bold-row th {
  font-weight: bold;
  font-size: 16px;
  background-color: #f5f5f5;
}

.heading {
  font-weight: normal;
  font-size: 16px !important;
}

.thead {
  font-weight: normal !important;
}

.content {
  text-align: center;
}

/* Centering links within table cells */
.escalate-matrix-container {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 20px;
}
.content a {
  display: block;
  margin: 0 auto;
}

/* Mobile-specific adjustments */
@media (max-width: 676px) {
  .escalate-matrix {
    width: 100%; /* Adjusts table width to be full on mobile */
  }

  .escalate-matrix th,
  .escalate-matrix td {
    padding: 20px; /* Minimal padding for height */
    height: auto; /* Allow height to adjust based on content */
  }

  .escalate-matrix th,
  .escalate-matrix td {
    white-space: nowrap; /* Prevent text wrapping */
  }
}
