/* #taxFiling {
  margin-top: 60px;
} */

.heading {
  font-size: 2rem;
  font-weight: 600;
}

.sub-heading {
  color: #2d2d2d;
}

.text-center {
  text-align: center;
}

.font-semibold {
  font-weight: 600;
}

button {
  padding: 10px 24px;
  border-radius: 20px;
  border: none;
}

.button--primary {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

.button--secoundary {
  background-color: white;
  color: #3b4d80;
  padding: 10px 24px;
  font-size: 14px;
}

.taxFiling__referral {
  position: relative; /* Ensure the tax_container is positioned relative for the pseudo-element */
  background-color: #242f4e;
  overflow: hidden; /* Ensures the pseudo-element doesn't overflow */
  padding: 5% 192px;
  box-sizing: border-box;
}

.taxFiling__referral::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/TaxFiling/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  z-index: 0;
}

/* Ensure any content inside the tax_container is above the pseudo-element */
.taxFiling__referral > * {
  position: relative;
  z-index: 1;
}
.taxFiling__referral-context h1 {
  font-size: 4rem;
}

.taxFiling__referral-background {
  max-width: 100%;
  opacity: 0.5;
  object-fit: cover;
  mix-blend-mode: color-burn;
}

.taxFiling__referral-context {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  z-index: 0;
}

.taxFiling__referral-context > div:first-child {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 40px;
  row-gap: 10px;
}

.taxFiling__referral-context > div > div {
  display: flex;
  column-gap: 5px;
  margin-top: 20px;
}

.taxFiling__referral-context > div > span {
  background-color: #2e3c64;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
}

.text-white {
  color: white;
}

.taxFiling__referral-image {
  height: 100%;
}

.taxFiling__referral-image > img {
  max-width: 100%;
  margin-bottom: -5px;
}

.taxFiling__about {
  padding: 72px 192px;
}

.taxFiling__about--carousel {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.taxFiling__about--carousel .taxFiling__about--carouselMobile {
  display: none;
}

.taxFiling__about > h1 {
  margin-bottom: 8px;
}

.taxFiling__about--carouselDesktop {
  border: 1px solid #d3dbec;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex: 1;
  margin-top: 50px;
  max-width: 300px;
}

.taxFiling__about--carousel img {
  height: 56px;
  width: 56px;
  margin-left: -10px;
}

.taxFiling__about--fabits p {
  margin-top: 10px;
}

.taxFiling__about--video iframe {
  border-radius: 16px;
  margin-top: 50px;
}

.taxFiling__about--props {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 32px;
}

.taxFiling__about--props > div {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.taxFiling__about--props > div img {
  height: 124px;
  width: 124px;
  object-fit: contain;
}

.taxFiling__fno {
  padding: 5%;
}

.taxFiling__fno > div {
  padding: 5%;
}

.taxFiling__bottom {
  padding: 5%;
  background-color: #41558d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
}

.taxFiling__bottom h1 {
  font-size: 36px;
  color: white;
}

.taxFiling__bottom p {
  color: #c4cadc;
}

.taxFiling__bottom button {
  margin-top: 48px;
}

.taxFiling__about--fabits {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  margin-top: 72px;
}

.taxFiling__about--fabits > div {
  flex: 1;
}

@media all and (max-width: 768px) {
  p {
    font-size: 0.9rem;
  }

  button {
    padding: 8px 16px;
  }

  .heading {
    font-size: 2rem;
    font-weight: 600;
  }

  .taxFiling__referral {
    padding: 5%;
  }

  .taxFiling__referral-context {
    flex-direction: column;
  }

  .taxFiling__referral-context > div:first-child {
    width: 100%;
  }

  .taxFiling__referral-context h1 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .taxFiling__referral-context > div > div {
    margin-top: 30px;
  }

  .taxFiling__referral-image {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .taxFiling__referral-image img {
    width: 80%;
  }

  .taxFiling__about--carousel {
    display: block;
    padding: 5% 2px;
  }

  .taxFiling__about {
    padding: 5%;
  }

  .taxFiling__about--carousel .taxFiling__about--carouselDesktop {
    display: none;
  }

  .taxFiling__about--carousel .taxFiling__about--carouselMobile {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
  }

  .taxFiling__about--fabits {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 50px;
  }

  .taxFiling__about--video {
    width: 100%;
  }

  .taxFiling__about--video iframe {
    max-width: 100%;
    height: 250px;
  }

  .taxFiling__bottom h1 {
    font-size: 24px;
    color: white;
  }
}

/* Plans cards css */

.tax_container {
  background: #f4f7ff;
  padding: 5%;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.about--heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0a4545;
  background-color: none;
  line-height: 2.625rem;
}

.subtitle {
  color: #427c7c;
  font-weight: 500;
}

.plans-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .taxFiling__referral {
    padding: 5%;
  }

  .tax_container {
    padding: 5%;
  }

  .plans-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .about--heading {
    font-size: 2rem;
  }
}

@media (min-width: 1025px) {
  .taxFiling__referral {
    padding: 72px 192px;
  }

  .tax_container {
    padding: 72px 192px;
  }

  .plans-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  .button--secoundary {
    background-color: white;
    color: #3b4d80;
    padding: 12px 48px;
    font-size: 16px;
  }

  .select-button {
    font-size: 16px;
  }
}

.plan-card {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}

.plan-card.basic {
  border: 1px solid #cddeff;
  box-shadow: 0px 4px 24px 0px #0d337b14;
  background: white;
}

.plan-card.advanced {
  border: 1px solid #ecd0ed;
  box-shadow: 0px 4px 24px 0px #8a108c14;
  background: white;
}

.plan-card.pro {
  border: 1px solid #e4c7b7;
  box-shadow: 0px 4px 24px 0px #832f0014;
  background: white;
}

.plan-header {
  display: flex;
  align-items: start;
  justify-content: start;
  text-align: start;
  gap: 16px;
  margin-bottom: 16px;
}

.plan-level {
  font-size: 1.25rem;
  font-weight: 600;
}

.plan-for {
  font-size: 0.875rem;
  font-weight: 500;
}

.plan-description {
  font-size: 1rem;
  font-weight: 600;
  text-align: start;
}

.plan-props {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  padding: 8px 0;
  /* max-height: 150px; */
  height: 150px;
}

.plan-prop {
  display: flex;
  align-items: start;
  text-align: start;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #2d2d2d;
  margin: 0px;
}

.plan-icon {
  margin-top: 2px;
}

.plan-footer {
  display: flex;
  align-items: end;
  gap: 12px;
}

.plan-price {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
}

.plan-actual-price {
  position: relative;
}

.cross-icon {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plan-discount {
  color: #c32525;
  font-weight: 600;
}

.plan-fee {
  color: #4263a1;
  text-align: start;
}

.select-button {
  width: 100%;
  border-radius: 12px;
  padding: 10px 24px;
  font-size: 14px;
  margin-top: 20px;
  color: #ffffff;
  display: block;
}

.select-button.basic {
  background-color: #0d337b;
}

.select-button.advanced {
  background-color: #8a108c;
}

.select-button.pro {
  background-color: #832f00;
}

.basic-text {
  color: #0d337b;
}

.advanced-text {
  color: #8a108c;
}

.pro-text {
  color: #832f00;
}

.plan-for.basic-text,
.plan-fee.basic-text {
  color: #4263a1;
}

.plan-for.advanced-text,
.plan-fee.advanced-text {
  color: #d66cd8;
}

.plan-for.pro-text,
.plan-fee.pro-text {
  color: #bd6a3c;
}

.dialog-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: solid 1px #ccc;
  border-radius: 32px;
  padding: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  min-width: 540px;
  z-index: 10;
}

::backdrop {
  background-color: #f4f7fe;
  opacity: 0.75;
}

.close-button {
  border-radius: 8px;
  color: #41558d;
  border: 1px solid #d3dbec;
  padding: 10px 24px;
  font-size: 14px;
}

.callback-button {
  background-color: #41558d;
  color: white;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 14px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.form-grid input {
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: solid 1px #8d8d8d;
  letter-spacing: 1px;
  width: 100%;
  box-sizing: border-box;
}

.form-group {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.seprator {
  border-top: 1px solid #d3dbec;
  margin: 24px auto;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}

.button_container button[type="submit"]:disabled {
  background: #808db3;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
  font-size: 12px;
}

.checkbox-wrapper-46 {
  margin-top: 16px;
}

.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checkbox-wrapper-46 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
}
.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #41558d;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: #41558d;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: #41558d;
  border-color: #41558d;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
}

@media all and (max-width: 767px) {
  .dialog-container p,
  .dialog-container input,
  .dialog-container label {
    font-size: 14px;
  }

  .dialog-container {
    min-width: 80%;
  }

  form {
    width: 100%;
  }

  .form-grid {
    grid-template-columns: 100%;
  }

  .modal-btn-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
