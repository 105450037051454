/* Desktop and Tablet styles */
.midContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 506px;
    margin-top: 65px;
    padding: 30px;
}

.LeftContent>iframe {
    margin-top: -239px;
    border-radius: 40px;
    position: absolute;
    color: linear-gradient(0deg, rgba(6, 53, 82, 0.08), rgba(6, 53, 82, 0.08)),
        linear-gradient(180deg, rgba(6, 53, 82, 0) 74.47%, rgba(6, 53, 82, 0.72) 101.42%),
        linear-gradient(182.25deg, rgba(6, 53, 82, 0.72) 3.44%, rgba(6, 53, 82, 0) 30.1%);
}

.rcontent {
    width: 642px;
    padding: 46px 18px 44px 313px;
    border-radius: 24px;
    background: linear-gradient(0deg, #2E3C64, #2E3C64),
        linear-gradient(167.4deg, #6777A4 -12.27%, rgba(103, 119, 164, 0) 84.33%);

}

.heading {
    margin-bottom: 24px;
}

.heading span {
    font-family: DM Sans, sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 42px;
    color: rgba(236, 238, 244, 1);
}

.para span {
    font-family: DM Sans, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: rgba(242, 248, 241, 1);
}

/* Mobile styles */
@media only screen and (max-width: 767px) {
    .midContainer {
        flex-direction: column;
        padding: 20px;
        height: auto;
        align-items: center;
        margin-top: 136px;
    }

    .LeftContent {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        position: relative;
    }

    .LeftContent>iframe {
        width: 80%;
        height: 180px;
        border-radius: 20px;
        margin-top: -64px;
    }

    .rcontent {
        width: 90%;
        padding: 122px 30px 24px 35px;
        border-radius: 20px;
        background: linear-gradient(0deg, #2E3C64, #2E3C64),
            linear-gradient(167.4deg, #6777A4 -12.27%, rgba(103, 119, 164, 0) 84.33%);

        text-align: center;
    }

    .heading span {
        font-size: 22px;
        line-height: 32px;
    }

    .para span {
        font-size: 14px;
        line-height: 22px;
    }
}