.carouselMain {
  width: 100% !important;
  margin: 0;
  height: 80px;
  z-index: 0;
}

.carousel {
  background: transparent;
  border-radius: 0px;
  padding: 0;
  border: none;
  position: relative;
  height: 80px;
}

.innerContent {
  font-family: DM Sans;
  font-size: 64px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .carouselMain {
    height: 84px;
  }

  .innerContent {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #ffffff;
    position: static;
  }

  .carousel {
    height: 84px;
  }
}
