/* Base Styles */
.container {
    padding: 60px 464px 60px 82px;
}

.container>h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: center;
    color: var(--black-500);
    font-family: "DM Sans", sans-serif;
    margin-block-end: 27px;
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.cardContainer>div {
    padding: 29px;
    border-radius: 24px;
    border: 1px solid #d3dbec;
    box-shadow: 0px 4px 24px rgba(65, 85, 141, 0.12);
    position: relative;
    /* To allow absolute positioning of icon */
}

.cardContent {
    display: flex;
    align-items: center;
    /* Align icon and text horizontally */
}

.icon {
    width: 24px;
    /* Smaller icon size */
    height: 24px;
    margin-right: 10px;
    /* Space between icon and text */
}

/* Responsive Styles for Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
        padding: 40px 20px;
        text-align: center;
    }

    .container>h1 {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
    }

    .cardContainer {
        gap: 15px;
        width: 80%;
        margin: 0 auto;
    }
}

/* Responsive Styles for Mobile */
@media screen and (max-width: 767px) {
    .container {
        padding: 20px;
        text-align: center;
        /* padding-top: 616px; */
    }

    .container>h1 {
        font-size: 24px;
        line-height: 32px;
        /* margin-top: 57px; */
    }

    .cardContainer {
        grid-template-columns: 1fr;
        gap: 15px;
        width: 100%;
        margin: 0 auto;
    }

    .cardContainer>div {
        padding: 20px;
    }
}