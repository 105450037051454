.container {
  margin: 0;
  padding: 0;
  background: linear-gradient(
      90deg,
      rgba(45, 45, 45, 0.4) 38.63%,
      rgba(45, 45, 45, 0.2) 54.72%
    ),
    url("../../../../assets/Marketing_landing_Pages/HeroSection/image.png")
      center/cover no-repeat;
  height: 584px;
}

.content {
  width: 90%;
  max-width: 1129px;
  margin: auto;
  padding-top: 192px;
  height: 296px;

  > div:nth-of-type(1) {
    > button {
      width: 237px;
      height: 32px;
      padding: 6px 16px;
      border-radius: 20px;
      background-color: var(--black-700);

      > p {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.04em;
        /* text-align: center; */
        color: var(--black-100);
      }
    }

    > p {
      font-family: DM Sans;
      font-size: 32px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      color: #f6f6f6;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    width: 463px;
    justify-content: space-between;
    margin-top: 72px;
    > button {
      width: 254px;
      height: 48px;
      padding: 12px 48px;
      border-radius: 40px;
      cursor: pointer;

      > span {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.04em;
        text-align: left;
        color: var(--black-500);
      }
    }

    > div {
      > p {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: var(--black-50);
      }
    }
  }
}

/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    margin: 0;
    padding: 0;
    background: linear-gradient(
        90deg,
        rgba(45, 45, 45, 0.4) 38.63%,
        rgba(45, 45, 45, 0.2) 54.72%
      ),
      url("../../../../assets/Marketing_landing_Pages/HeroSection/image.png")
        center/cover no-repeat;
    height: 584px;
    background-position: 75% 85%;
  }
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .container {
    margin: 0;
    padding: 0;
    background: linear-gradient(
        0deg,
        rgba(45, 45, 45, 0.6) 47.83%,
        rgba(45, 45, 45, 0.2) 60.18%
      ),
      url("../../../../assets/Marketing_landing_Pages/HeroSection/image.png")
        center/cover no-repeat;
    height: 688px;
    background-position: 60% 75%;
  }

  .content {
    width: 90%;
    max-width: 1129px;
    margin: auto;
    padding-top: 288px;
    height: 296px;

    > div:nth-of-type(1) {
      > button {
        display: none;
      }

      > p {
        font-family: DM Sans;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        color: #f6f6f6;
        margin-top: 8px;
      }
    }

    > div:nth-of-type(2) {
      display: grid;
      justify-items: center;
      justify-content: center;
      gap: 24px;
      width: 100%;
      margin-top: 48px;
      /* border: 1px solid red; */

      > button {
        width: 100%;
        height: 48px;
        padding: 12px 48px;
        border-radius: 40px;

        > span {
          font-family: DM Sans;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.04em;
          text-align: left;
          color: var(--black-500);
        }
      }

      > div {
        > p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
          color: var(--black-50);
        }
      }
    }
  }
}
