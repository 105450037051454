.container {
  padding: 72px 0px;

  > h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: center;
    color: var(--black-500);
    font-family: DM Sans;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
    text-align: center;
    color: var(--black-500);
    font-family: DM Sans;
  }
}

.cardContainer {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  padding-top: 48px;
  width: 100%;

  > div {
    width: 264px;
    /* height: 258px; */
    padding: 24px;
    border-radius: 24px;
    border: 1px solid #d3dbec;
    box-shadow: 0px 4px 24px rgba(65, 85, 141, 0.12);

    > div:nth-of-type(1) {
      background-color: var(--primary-600);
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 12px 0px #41558d29;

      > span {
        font-size: 34px;
        color: var(--secondary-200);
      }
    }

    > div:nth-of-type(2) {
      font-family: DM Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      margin-top: 24px;

      > span {
        font-weight: 600;
      }
    }
  }
}

/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    padding: 48px 0px 48px 16px;
  }
  .cardContainer {
    width: 100%;
    display: flex;
    overflow-x: auto; /* Allow horizontal scrolling if cards overflow */
    scroll-snap-type: x mandatory; /* Optional: For smooth scrolling */

    > div:nth-of-type(4) {
      margin-right: 16px;
    }
  }

  .cardContainer > div {
    min-width: 304px; /* Ensure cards do not shrink too much */
    flex-shrink: 0; /* Prevent cards from shrinking */
    height: 212px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 48px 0px 48px 16px;
  }
  .cardContainer {
    width: 100%;
    display: flex;
    overflow-x: auto; /* Allow horizontal scrolling if cards overflow */
    scroll-snap-type: x mandatory; /* Optional: For smooth scrolling */

    > div:nth-of-type(4) {
      margin-right: 16px;
    }
  }

  .cardContainer > div {
    /* min-width: 304px; Ensure cards do not shrink too much */
    flex-shrink: 0; /* Prevent cards from shrinking */
    height: 212px;
  }
}
