.container {
    box-sizing: border-box;
    padding: 72px 0px;
    background: rgba(65, 85, 141, 1);


    >h1 {
        font-family: DM Sans;
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.04em;
        text-align: center;
        color: #ffffff;
    }
}

.btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    >button {
        width: 345px;
        height: 60px;
        padding: 19px 23px;
        gap: 10px;
        border-radius: 40px;
        background-color: #f6f6f6;
        cursor: pointer;

        >span {
            font-family: DM Sans;
            font-size: 30px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.04em;
            text-align: center;
            color: #3b4d80;
        }
    }
}