.container {
  padding: 72px 0px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 0;
}

.iframe {
  border-radius: 24px;
  background: linear-gradient(
      0deg,
      rgba(6, 53, 82, 0.08),
      rgba(6, 53, 82, 0.08)
    ),
    linear-gradient(
      180deg,
      rgba(6, 53, 82, 0) 74.47%,
      rgba(6, 53, 82, 0.72) 101.42%
    ),
    linear-gradient(
      182.25deg,
      rgba(6, 53, 82, 0.72) 3.44%,
      rgba(6, 53, 82, 0) 30.1%
    );
  box-shadow: 0px 32px 32px -32px #13131352;
  margin-bottom: -80px;
  z-index: 0;
  /* width: 100%; */
}

.readMore {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  text-align: left;
  color: var(--secondary-50);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.content {
  background: radial-gradient(
    77.22% 101.96% at 50% 23.96%,
    #74a766 0%,
    #364d2f 100%
  );
  width: 100%;
  border-radius: 24px;

  > div {
    padding: 96px 48px 48px 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 96px;
    > h1 {
      /* width: 292px; */
      font-family: DM Sans;
      font-size: 32px;
      font-weight: 600;
      line-height: 42px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--primary-50);
    }

    > div > p {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: var(--secondary-50);
      width: 644px;
    }
  }
}

.openNpsBtn {
  > button {
    width: 254px;
    height: 48px;
    padding: 12px 48px;
    gap: 8px;
    border-radius: 40px;
    cursor: pointer;

    > span {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--black-500);
    }
  }
}

/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    padding: 72px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: end; */
    gap: 0;
  }

  .iframe {
    border-radius: 24px;
    width: 668px;
    background: linear-gradient(
        0deg,
        rgba(6, 53, 82, 0.08),
        rgba(6, 53, 82, 0.08)
      ),
      linear-gradient(
        180deg,
        rgba(6, 53, 82, 0) 74.47%,
        rgba(6, 53, 82, 0.72) 101.42%
      ),
      linear-gradient(
        182.25deg,
        rgba(6, 53, 82, 0.72) 3.44%,
        rgba(6, 53, 82, 0) 30.1%
      );
    box-shadow: 0px 32px 32px -32px #13131352;
    /* position: absolute;
    top: 0;
    left: 0; */
    margin-bottom: -50px;
  }

  .content {
    background: radial-gradient(
      77.22% 101.96% at 50% 23.96%,
      #74a766 0%,
      #364d2f 100%
    );
    border-radius: 0px;
    > div {
      padding: 96px 48px 48px 48px;
      display: block;
      > h1 {
        width: 100%;
        font-family: DM Sans;
        font-size: 32px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.04em;
        text-align: left;
        color: var(--primary-50);
      }

      > div > p {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--secondary-50);
        width: 100%;
      }
    }
  }
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .container {
    padding: 48px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: end; */
    gap: 0;
  }

  .iframe {
    border-radius: 24px;
    width: 328px;
    background: linear-gradient(
        0deg,
        rgba(6, 53, 82, 0.08),
        rgba(6, 53, 82, 0.08)
      ),
      linear-gradient(
        180deg,
        rgba(6, 53, 82, 0) 74.47%,
        rgba(6, 53, 82, 0.72) 101.42%
      ),
      linear-gradient(
        182.25deg,
        rgba(6, 53, 82, 0.72) 3.44%,
        rgba(6, 53, 82, 0) 30.1%
      );
    box-shadow: 0px 32px 32px -32px #13131352;
    /* position: absolute;
    top: 0;
    left: 0; */
    margin-bottom: -50px;
  }

  .content {
    background: radial-gradient(
      77.22% 101.96% at 50% 23.96%,
      #74a766 0%,
      #364d2f 100%
    );
    border-radius: 0px;

    > div {
      padding: 96px 16px 48px 16px;
      display: block;
      justify-content: space-between;
      align-items: center;
      > h1 {
        width: 100%;
        font-family: DM Sans;
        font-size: 32px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -0.04em;
        text-align: left;
        color: var(--primary-50);
      }

      > div > p {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: var(--secondary-50);
        width: 100%;
      }
    }
  }

  .openNpsBtn {
    > button {
      width: 100%;
      height: 44px;
      padding: 12px 48px;
      gap: 8px;
      border-radius: 40px;

      > span {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.04em;
        text-align: left;
        color: var(--black-500);
      }
    }
  }
}
