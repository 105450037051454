.content {
  /* border: 1px solid red; */
  max-width: 1128px;
  margin: auto;
}

.heading {
  padding-top: 72px;
  > h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: #1b243b;
    text-align: center;
  }

  > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #242f4e;
    margin-top: 16px;
    text-align: center;
  }

  > div {
    display: flex;
    gap: 40px;
    justify-content: center;

    > div {
      display: flex;
      gap: 8px;
      margin-top: 40px;

      > p {
        color: #242f4e75;
        font-weight: 600;
        line-height: 32px;
        font-size: 16px;
      }
    }
  }
}

.card {
  display: flex;
  gap: 20px;
  margin-top: 80px;
  > div {
    height: 449px;
    width: 363px;
    background-color: white;
    border-radius: 32px;
    padding: 32px 24px;
    border: 1px solid #c4cadc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div:nth-of-type(1) {
      > div {
        display: flex;
        justify-content: center;
        > div {
          padding: 5.98px 8px;
          width: 40px;
          height: 37.96px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f4f7fe;
          border-radius: 3.95px;
          align-self: center;
          font-size: 18.98px;
          font-weight: 600;
          line-height: 25.31px;
          letter-spacing: -0.04em;
          color: #242F4E;
        }
      }

      > h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.04em;
        color: #131313;
        text-align: center;
        margin-top: 12px;
      }

      > p {
        text-align: center;
        color: #575757;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: end;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  padding-bottom: 72px;

  > a > button {
    background-color: #41558d;
    color: white;
    padding: 23.5px 122.5px;
    border-radius: 40px;
    margin-top: 80px;
    font-size: 28px;
    line-height: 24px;
    letter-spacing: -0.04em;
    cursor: pointer;
  }

  > a > button:hover {
    background-color: #242f4e;
  }
}

.card1 {
  position: relative;
}

.card1 .img1 {
  position: absolute;
  bottom: 42.49px;
  left: 50%;
  transform: translateX(-50%);
}

.card1 .img2 {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.card1 .img3 {
  position: absolute;
  bottom: 50.23px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1023px) {
  .content {
    max-width: 320px;
    /* border: 1px solid red; */
    margin: auto;
  }

  .heading {
    padding-top: 72px;
    > h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.04em;
      color: #1b243b;
      text-align: center;
    }

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20.83px;
      color: #242f4e;
      margin-top: 16px;
      text-align: center;
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0;
      justify-content: start;

      > div {
        display: flex;
        gap: 8px;
        margin-top: 20px;

        > img {
          height: 21px;
          width: 21px;
        }

        > p {
          color: #242f4e75;
          font-weight: 600;
          line-height: 28px;
          font-size: 14px;
        }
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 48px;
    > div {
      /* height: 373px; */
      width: 100%;
      box-sizing: border-box;
      background-color: white;
      border-radius: 32px;
      padding: 32px 24px;
      border: 1px solid #c4cadc;

      > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > div {
          padding: 6px 10px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 18px;
          background-color: #f4f7fe;
          border-radius: 4px;
          align-self: center;
        }
      }

      > h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.04em;
        color: #242f4e;
        text-align: center;
        margin-top: 12px;
      }

      > p {
        text-align: center;
        color: #575757;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
      }

      > div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        align-items: end;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    padding-bottom: 72px;

    > a > button {
      background-color: #41558d;
      color: white;
      padding: 12px 28px;
      border-radius: 40px;
      margin-top: 80px;
      font-size: 17.37px;
      line-height: 24.81px;
      letter-spacing: -0.04em;
    }
  }
}
