.accordion {
  background-color: black;
  color: white;
  cursor: pointer;
  padding: 14px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-radius: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.active,
.accordion:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.panel {
  /* padding: 0 18px; */
  display: none;
  background-color: white;
  overflow: hidden;
}

.show {
  display: block;
}
