/* .container {
  margin-top: 24px;
} */

.tab {
  overflow: hidden;
  background-color: #f1f1f1;
  height: 36px;
  width: 229px;
  border-radius: 24px;
  margin: 24px 0px;
  display: flex;
  align-items: center;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  margin-left: 2px;

  height: 32px;

  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #6e6e6e;

  display: flex;
  justify-content: center;
  align-items: center;
}

.tab button.active {
  background-color: white;
  color: #2d2d2d;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
