.SliderContainer {
  position: relative;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

input[type="range"] {
  width: 100%;
  appearance: none;
  background: transparent;
  position: relative;
}

.tooltip {
  position: absolute;
  top: -40px; /* Adjusted to stay above the slider thumb */
  left: 20px;
  transform: translateX(-50%);
  background-color: #fff;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10; /* Ensures the tooltip stays on top */
}

.pricingslidersection {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #c4cadc;
}

.sliderspan {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--primary-100);
}

/* Slider CSS */

/* Style the slider track */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 1.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

/* Style the slided part of the track */
input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    var(--secondary-600) 0%,
    var(--secondary-600) var(--progress),
    #ddd var(--progress),
    #ddd 100%
  );
  height: 8px;
  border-radius: 5px;
}

/* For Firefox */
input[type="range"]::-moz-range-progress {
  background: var(--secondary-600);
  height: 8px;
}

input[type="range"]::-moz-range-track {
  background: #ddd;
  height: 8px;
  border-radius: 5px;
}

/* Style the slider thumb (button) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--secondary-600);
  border: 6px solid #ffffff;
  box-shadow: 0px 1.5px 4px 0px #0000001f;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: -10px;
}

/* Style the slider thumb (button) for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--secondary-600);
  border: 6px solid #ffffff;
  box-shadow: 0px 1.5px 4px 0px #0000001f;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: -6px;
}

/* Optional: Style the slider track when it has focus */
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    var(--secondary-600) 0%,
    var(--secondary-600) var(--progress),
    #ccc var(--progress),
    #ccc 100%
  );
  height: 8px;
  border-radius: 5px;
}
