.tab {
  overflow: hidden;
  background-color: white;
}

.tab button {
  background-color: black;
  color: white;
  border-radius: 0%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.4s;
  font-size: 17px;
  margin: 10px;
  width: 100%;
}

.tablinks.active {
  border: 1px solid black;
  background-color: #f9f9f9;
  color: black;
  box-sizing: border-box;
}

.tabcontent {
  display: none;
  /* padding: 6px 12px; */
}

.tabcontent.active {
  display: block;
  border: none;
}

.tabBtn {
  display: flex;
  justify-content: space-evenly;
}

.linksandbuttonsofincvestorcomplaints {
  display: flex;
  flex-wrap: wrap;
}

@media all and (max-width: 768px) {
  .tab button {
    padding: 10px;
    font-size: 14px;
    margin: 0px 2px;
  }
}
