.container {
  width: 90%;
  max-width: 1128px;
  margin: auto;
  /* height: 698px; */
  /* border: 1px solid red; */
}

.contentContainer {
  padding: 192px 0px 132px 0px;
}

.content {
  height: 374px;
  background-color: #1b243b;
  border-radius: 24px;
  /* margin-top: 192px; */
  display: flex;
  align-items: end;
  position: relative;

  > div:nth-of-type(1) {
    margin-left: 72px;
    margin-bottom: 54px;
    color: #f6f6f6;
  }

  > div:nth-of-type(2) {
    position: absolute;
    bottom: -5px;
    right: 110px;
  }
}

.doodle {
  position: absolute;
  top: 0px;
  border-radius: 24px 0px 0px 0px;
}

.emailInput {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  /* width: 594px; */

  > input {
    background: transparent;
    border: 1px solid var(--primary-200);
    border-radius: 40px;
    width: 60%;
    padding: 8px 16px;
    color: white;
  }

  > button {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    > a {
      color: var(--primary-600);
    }
  }
}

@media screen and (max-width: 1023px) {
  .container {
    width: 90%;
    max-width: 1128px;
    margin: auto;
    /* height: 698px; */
    /* border: 1px solid red; */
    position: relative;
    z-index: 0;
  }

  .contentContainer {
    padding: 92px 0px 60px 0px;
  }

  .content {
    height: 374px;
    /* background-color: var(--primary-500); */
    /* border-radius: 24px; */
    /* margin-top: 192px; */
    /* display: flex; */
    align-items: center;
    justify-content: center;

    > div:nth-of-type(1) {
      margin-left: 72px;
      margin-bottom: 54px;
      color: #f6f6f6;
    }

    > div:nth-of-type(2) {
      display: none;
    }
  }

  .doodle {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 24px 0px 0px 0px;
    /* z-index: -1; */
  }

  .emailInput {
    display: flex;
    gap: 8px;
    margin-top: 24px;

    > input {
      background: transparent;
      border: 1px solid var(--primary-200);
      border-radius: 40px;
      width: 60%;
      padding: 8px 16px;
      /* width: 60%; */
    }

    > button {
      color: var(--primary-600);
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1023px) {
  .container {
    width: 90%;
    max-width: 1128px;
    margin: auto;
    z-index: 0;
    /* height: 698px; */
    /* border: 1px solid red; */
  }

  .doodle {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 24px 0px 0px 0px;
    /* z-index: -1; */
  }

  .contentContainer {
    padding: 48px 20px 48px 20px;
    position: relative;
  }

  .content {
    height: 224px;
    background-color: #1b243b;
    border-radius: 24px;
    /* margin-top: 192px; */
    display: flex;
    align-items: center;
    padding: 20px;

    > div:nth-of-type(1) {
      margin-left: 0;
      margin-bottom: 0;
      color: #f6f6f6;
      z-index: 2;

      > h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.04em;
      }

      > p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .emailInput {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    /* width: 594px; */

    /* > input {
      background: transparent;
      border: 1px solid var(--primary-200);
      border-radius: 40px;
      width: 87%;
      padding: 8px 16px;
    } */

    > button {
      color: var(--primary-600);
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
