.tooltip {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  transform: translateY(-100%);
}
