.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1128px;
  /* height: 712px; */
  margin: 0 auto;
  padding: 4rem 2rem;
  position: relative;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  width: 100%;
}

.quoteSection {
  flex: 1;
  /* padding: 2rem; */
  max-width: 530px;
}

.quote {
  font-size: 44px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.04em;
}

.profileSection {
  width: 320px;
  text-align: center;
}

.profileImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  filter: grayscale(1);
}

.profileImage:hover {
  filter: grayscale(0);
}

.profileDiv {
  box-shadow: 0px 4px 16px 0px #2d2d2d1f;
  padding: 12px 16px;
  border-radius: 16px;
}

.name {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.title {
  color: #575757;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}

.navigationButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: 1px solid #eee;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #41558d;

  > span {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #41558d;
  }
}

.navigationButton:hover {
  background: #f5f5f5;
}

.prevButton {
  left: 0px;
}

.nextButton {
  right: 0px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1023px) {
  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 350px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    height: 800px;
  }

  .slide {
    display: block;
  }

  .quoteSection {
    flex: 1;
    /* padding: 2rem; */
    max-width: 530px;
    /* margin-bottom: 48px; */
    height: 320px;
  }

  .quote {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.04em;
  }

  .profileSection {
    width: 100%;
    text-align: center;
  }

  .profileImage {
    /* width: 350px; */
    /* height: 260px; */
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .profileDiv {
    box-shadow: 0px 2.97px 11.88px 0px #2d2d2d1f;
    padding: 8.91px 11.91px;
    border-radius: 16px;
    /* width: 100%; */
  }

  .name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.5rem 0;
  }

  .title {
    color: #666;
    margin: 0;
  }

  .navigationButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: 1px solid #41558d;
    background-color: var(--black-50);
    /* color: #ffffff; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      display: flex;
      align-items: center;
      font-size: 20px;
      /* color: #ffffff; */
    }
  }

  /* .navigationButton:hover {
    background: transparent;
  } */

  .prevButton {
    left: 42px;
    top: 65%;
  }

  .nextButton {
    right: 66px;
    top: 65%;
  }

  .hidden {
    display: none;
  }
}
