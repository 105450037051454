/* src/Card.module.css */

/* src/Card.module.css */

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content; /* Keeps the container to the size of the cards */
    margin: 0; /* Remove any margins */
    padding: 0; /* Remove padding */
    gap: 0px; /* Ensures no space between cards */
}

.cardContainer {
    perspective: 1000px;
    width: 264px;
    height: 300px;
    margin: 0; /* Remove any gaps */
}


.card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 16px;

    &:hover {
        transform: rotateY(180deg);
    }
}

.front,
.back {
    position: absolute;
    backface-visibility: hidden;
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.Image > img {
    width: 210px;
    height: 210px;
}

.front {
    display: flex;
    background-size: cover;
    border: 1px solid rgb(15, 13, 13);
    justify-content: space-evenly;
}

.back {
    background: rgba(36, 47, 78, 1);
    transform: rotateY(180deg);
    padding: 16px;
    box-sizing: border-box;

    > span {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: var(--secondary-50);
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    display: flex;
    align-items: end;

    > div {
        padding: 16px;
        width: 100%;

        > div {
            display: flex;
            align-items: center;

            > h1 {
                font-family: DM Sans;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                margin-right: 10px;
                text-align: left;
            }
        }
    }
}
