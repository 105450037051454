.container {
  padding: 72px 0px;

  > h1 {
    font-family: DM Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: center;
    color: var(--black-500);
  }

  > p {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--black-500);
  }

  > div {
    margin-top: 48px;
    display: flex;
    justify-content: center;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .container {
    padding: 0px 16px 48px 16px;

    > h1 {
      font-size: 24px;
      line-height: 32px;
    }

    > p {
      font-size: 14px;
      line-height: 20px;
    }

    > div {
      margin-top: 48px;
      display: flex;
      justify-content: center;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
