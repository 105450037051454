.content {
  max-width: 1128px;
  /* border: 1px solid red; */
  margin: auto;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.card {
  width: 100%;
  height: 577.33px;
  padding: 24px 24px 40px 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border: 1px solid #aaaaaa;
}

.heading {
  /* height: 384px; */
  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    gap: 12px;
    > div {
      height: 28.33px;
      width: 28.33px;
      background-color: #e8efff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      > span {
        font-size: 16.19px;
        color: #6780c6;
      }
    }
    > p {
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      letter-spacing: -0.04em;
    }
  }

  > p {
    height: 168px;
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
    color: #575757;
  }
}

.img {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  > img {
    width: 279.66px;
    height: 175.17px;
  }
}

.callback {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 108.83px;

  > button {
    background-color: #41558d;
    padding: 12px 16px;
    border-radius: 7px;
    height: 48px;
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    cursor: pointer;
  }
}

@media screen and (max-width: 1023px) {
  .content {
    max-width: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .card {
    /* width: 350px; */
    box-sizing: border-box;
    padding: 24px;
    gap: 20px;
  }

  .heading {
    height: 228.33px;
  }
}
