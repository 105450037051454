@keyframes moveLogos {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-500%, 0);
  }
}

.content {
  margin: auto;
  /* border: 1px solid red; */
  max-width: 1128px;

  > h1 {
    color: #eceef4;
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -0.04em;
    text-align: center;
  }

  > p {
    color: #eceef4;
    font-weight: 500;
    font-size: 24px;
    line-height: auto;
    text-align: center;
    margin-top: 32px;
  }

  > div:nth-of-type(1) {
    display: flex;
    margin-top: 90px;
    font-weight: 600;
    color: #eceef4;
    
    > p {
      flex: 1;
      text-align: center;
    }

    > p:nth-of-type(2) {
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    > a {
      > button {
        cursor: pointer;
        background-color: #f6f6f6;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.04em;
        color: #3b4d80;
        border-radius: 40px;
      }
    }

    > a > button:hover {
      background-color: #d1d1d6;
    }
  }
}

.logos {
  margin-top: 80px;

  > p {
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
  }

  > .logo-container {
    margin-top: 40px;
    display: flex;
    gap: 48px;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    position: relative;

    > img {
      height: 23px;
      width: 100px;
      flex-shrink: 0;
      animation: moveLogos 10s linear infinite;
    }
  }
}

@media screen and (max-width: 1023px) {
  .content {
    margin: auto;
    max-width: 320px;
    margin-bottom: 106px;

    > h1 {
      color: #eceef4;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: -0.04em;
      text-align: center;
    }

    > p {
      color: #eceef4;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-top: 32px;
    }

    > div:nth-of-type(1) {
      display: grid;
      justify-content: center;
      row-gap: 18px;
      margin-top: 48px;
      font-weight: 600;
      color: #ffffff;

      > p {
        text-align: start;
        padding-left: 8px;
        border-left: 1px solid #fff;
        /* height: 27px; */
        display: flex;
        align-items: center;
      }

      > p:nth-of-type(2) {
        border-left: 1px solid #fff;
        border-right: none;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      margin-top: 48px;

      > button {
        background-color: #f6f6f6;
        font-size: 17.15px;
        line-height: 24.5px;
        letter-spacing: -0.04em;
        font-weight: 500;
        color: #3b4d80;
        border-radius: 40px;
      }
    }
  }

  .logos {
    margin-top: 66px;

    > p {
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.04em;
    }

    > .logo-container {
      margin-top: 40px;
      display: flex;
      gap: 48px;
      align-items: center;
      justify-content: start;
      overflow: hidden;
      position: relative;

      > img {
        height: 23px;
        width: 100px;
        flex-shrink: 0;
        animation: moveLogos 10s linear infinite;
      }
    }
  }
}
