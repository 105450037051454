.DetailedBreakdownContainer {
  border-radius: 32px;
  border: 1px solid #d3dbec;
  padding: 32px;
  margin: 24px 0px;
  background-color: white;
}

.DetailedBreakdownContainer > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Div2Title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.04em;
}

.graphDiv {
  height: 416px;
  border: 1px solid #e3e3e3;
  margin-top: 24px;
  border-radius: 24px;
  padding: 26px 24px;
}

.whatInGraph {
  display: flex;
  gap: 32px;
}

.whatInGraph > div {
  display: flex;
  gap: 8px;
}

.colorBox1,
.colorBox2 {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.colorBox1 {
  background-color: #41558d;
}

.colorBox2 {
  background-color: #82b770;
}
/* Desktop View */
/* @media screen and (min-width: 1024px) {...} */

/* Tablet View */
/* @media screen and (min-width: 768px) and (max-width: 1023px) {...} */

/* Mobile View */
@media screen and (max-width: 767px) {
  .DetailedBreakdownContainer {
    border-radius: 32px;
    border: none;
    padding: 32px;
    margin: 5% 0px;
    background-color: white;
  }

  .Div2Title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.04em;
  }
}

@media screen and (max-width: 867px) {
  .DetailedBreakdownContainer {
    border-radius: 0px;
    border: 1px solid #d3dbec;
    padding: 32px;
    margin: 24px 0px;
    background-color: white;
  }
}
