.content {
  max-width: 1128px;
  margin: auto;
  /* border: 1px solid red; */
}

.heading {
  text-align: center;
  padding-top: 72px;
  > h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: #1b243b;
  }

  > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26.04px;
    color: #242f4e;
    margin-top: 16px;
    margin-bottom: 40px;
  }
}



@media screen and (max-width: 1023px) {
  .content {
    max-width: 350px;
    margin: auto;
  }

  .heading {
    text-align: center;
    padding-top: 72px;

    > h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.04em;
      color: #1b243b;
    }

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20.83px;
      color: #242f4e;
      margin-top: 12px;
    }
  }

  
}
