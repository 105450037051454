.container {
    max-width: 1128px;
    margin: auto;
}

.content {
    margin: 72px 0px;

    >h1 {
        font-size: 32px;
        padding-left: 56px;
        text-align: left;
    }
}

.faqContainer {
    margin-top: 48px;
}

.ruler {
    color: var(--primary-200);
}

.diffUl {
    list-style-type: lower-alpha;

    >li>span {
        font-size: 16px;
        font-weight: 600;
    }
}

.freecall {
    display: none;
    /* Default to hidden */
    margin-top: 20px;
    gap: 30px;
}

/* Show the buttons for mobile and tablet screens */
@media screen and (max-width: 1024px) {
    .freecall {
        display: flex;
        justify-content: center;
        padding: 10px;
    }

    .btn {
        color: white;
        background-color: rgb(30, 50, 104);
        border-radius: 30px;
        padding: 15px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}

.btn {
    color: white;
    background-color: rgb(30, 50, 104);
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 16px 30px;
    font-size: 19px;
}

.btn:hover {
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(4, 14, 69, 0.2);
}

.answer9 {
    >p {
        font-size: 16px;
        font-weight: 600;
    }

    >ul {
        list-style-type: decimal;
    }
}

.answer6Table {

    th,
    td {
        border: 1px solid black;
        padding: 8px;
        text-align: left;
    }

    >thead>tr>th {
        text-align: center;
    }
}

.answer12 {
    list-style-type: decimal;

    >ul>li>span {
        font-size: 16px;
        font-weight: 600;
    }
}

@media screen and (max-width: 767px) {
    .container {
        max-width: 1128px;
        margin: auto;
        padding: 0px 16px;
    }

    .freecall {
        justify-content: center;
        padding: 10px;
    }

    .btn {
        color: white;
        background-color: rgb(30, 50, 104);
        border-radius: 30px;
        padding: 15px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}