.container {
    margin: 0;
    padding: 96px 24px 0px 24px;
    background-color: rgba(36, 47, 78, 1);
    background-image: url('../../../assets/Gold_ETF_Page/Diwali_gold.svg');

    background-size: cover;
    /* or contain, depending on how you want the image to fit */
    /* Center the background image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: normal;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    /* Add this to allow absolute positioning of inner elements */
}

.btn:hover {
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(32, 24, 79, 0.963);
    /* Adds shadow effect */
}

.left {
    padding: 20px 1px 0px 40px;
}



.left>p {

    font-family: DM Sans;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    display: block;
    margin-top: 30px;
    color: rgba(246, 246, 246, 1);
    ;
}

.left>span {
    font-family: 'DM Sans', sans-serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: -0.04em;
    color: rgba(255, 255, 255, 1);
    margin: 0;

}

.Diwali_Img {
    flex: 1;
    max-width: 50%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.Diwali_Img img {
    max-width: 100%;
    height: 415px;

}

.freecall {
    margin-top: 45px;
}

.btn {
    width: 340px;
    height: 59px;
    padding: 19px 23px;
    gap: 10px;
    border-radius: 40px;
    background-color: #f6f6f6;
    cursor: pointer;
}

.btn>span {
    font-family: DM Sans;
    font-size: 30px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: center;
}

/* Tablet view */
@media (max-width: 768px) {
    .container {
        padding: 37px 18px 0px 18px
    }

    .content {
        flex-direction: column;
        /* Stack the content */
        align-items: center;
        /* Center align content */
        text-align: center;
        /* Center text */
    }

    .left {
        max-width: 100%;
        /* Full width on tablet */
        padding: 0px;
        /* Uniform padding */
    }

    .left>p {
        font-family: DM Sans;
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        display: block;
        margin-top: 30px;
        color: rgba(246, 246, 246, 1);
        
    }

    .left>span {
        font-family: 'DM Sans', sans-serif;
        font-size: 60px;
        font-weight: 600;
        line-height: 65px;
        letter-spacing: -0.04em;
        color: rgba(255, 255, 255, 1);
        margin: 0;
    }

    .Diwali_Img {
        margin-top: 24px;
        max-width: 100%;
        /* Full width */
    }
}

/* Mobile view */
@media (max-width: 480px) {
    .container {
        padding: 37px 18px 0px 18px;
    }

    .left>p {
        font-family: DM Sans;
        font-size: 25px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        display: block;
        margin-top: 30px;
        color: rgba(246, 246, 246, 1);
    }

    .left>span {
        font-family: 'DM Sans', sans-serif;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: -0.04em;
        color: rgba(255, 255, 255, 1);
        margin: 0;
    }

    .freecall {
        margin-top: 30px;
        /* Reduced margin */
        width: 100%;
        /* Full width for stacking */
    }

    .btn {
        width: 100%;
        /* Full width for button */
        padding: 12px 0;
        /* Full height */
    }

    .Diwali_Img img {
        height: auto;
        /* Responsive image height */
        max-height: 300px;
        /* Limit max height */
    }
}