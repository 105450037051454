.container {
  padding: 72px 0px 80px 0px;
  display: flex;
  justify-content: center;

  >h1 {
    width: 768px;
    font-family: DM Sans;
    font-size: 42px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.04em;
    text-align: center;
    color: var(--black-500);
  }
}

.featuresContent {
  background-color: var(--black-50);
  height: 400px;
  padding-bottom: 116px;
  border-radius: 24px;
  display: flex;
  gap: 27px;
  position: relative;



  >div:nth-of-type(1) {
    color: var(--black-500);
    padding: 48px 48px 100px 48px;

    >h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;
    }

    >p {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    >ul {
      list-style-type: none;
      margin-top: 62px;
      display: grid;
      gap: 21px;

      >span {
        font-family: DM Sans;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        margin-top: -25px;
        text-align: left;
        color: rgba(45, 45, 45, 1);
      }

      >li {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;        
      }
    }
  }

  >div:nth-of-type(2) {
    height: 468px;
    width: 220.67px;
    border-radius: 24px;
    background: linear-gradient(0deg, #2E3C64, #2E3C64),
      linear-gradient(167.4deg, #6777A4 -12.27%, rgba(103, 119, 164, 0) 84.33%);
    box-shadow: 0px 4px 24px 0px rgba(36, 47, 78, 0.16);

    padding: 64px 20px;
    color: #ffffff;
    position: absolute;
    top: -40px;
    left: 21%;


    >div:nth-of-type(1) {
      height: 104px;
      display: grid;
      justify-items: center;
      align-content: space-between;

      /* >img {
        font-family: DM Sans;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.04em;
        text-align: center;
      } */

      >div {
        >p {
          padding: 10px 24px 10px 24px;
          margin-top: 10px;
          font-family: DM Sans;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          color: rgba(59, 77, 128, 1);
          background: rgba(229, 184, 11, 1);

        }
      }
    }

    >ul {
      list-style-type: none;
      margin-top: 24px;
      display: grid;
      gap: 24px;

      >li {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  >div:nth-of-type(3) {
    width: 200.67px;
  }

  >div:nth-of-type(4) {
    padding: 42px 15px 120px 15px;

    margin-left: 2%;

    >h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: center;
      color: var(--black-500);
    }

    >ul {
      list-style-type: none;
      display: grid;
      gap: 24px;
      justify-items: center;
      margin-top: 74px;

      >li {
        text-align: center;
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--black-500);
      }
    }
  }

  >div:nth-of-type(5) {
    padding: 42px 15px 120px 15px;

    margin-left: -2%;
    ;

    >h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: center;
      color: var(--black-500);
    }

    >ul {
      list-style-type: none;
      display: grid;
      gap: 24px;
      justify-items: center;
      margin-top: 74px;

      >li {
        text-align: center;
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--black-500);
      }


    }
  }

  >div:nth-of-type(6) {
    padding: 42px 15px 120px 15px;

    margin-left: -2%;
    ;

    >h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: center;
      color: var(--black-500);
    }

    >ul {
      list-style-type: none;
      display: grid;
      gap: 24px;
      justify-items: center;
      margin-top: 74px;

      >li {
        text-align: center;
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--black-500);
      }


    }
  }
}

.featuresContent_mobile {
  display: none;
}

.featuresContent_tab {
  display: none;
}

@media screen and (min-width: 617px) and (max-width: 1053px) {
  .container {
    padding: 48px 16px 64px 16px;
    display: flex;
    justify-content: center;

    >h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--black-500);
    }
  }

  .featuresContent {
    display: none;
  }

  .featuresContent_mobile {
    display: none;
  }

  .featuresContent_tab {
    display: flex;
    box-sizing: border-box;
    margin: 0px 16px;

    >div:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      gap: 22px;
      width: 180px;
      height: 493px;
      padding: 48px 20px;
      border-radius: 20px;
      background: linear-gradient(0deg, #2e3c64, #2e3c64),
        linear-gradient(167.4deg,
          #6777a4 -12.27%,
          rgba(103, 119, 164, 0) 84.33%);
      box-shadow: 0px 4px 24px 0px #242f4e3d;
      position: relative;

      .heading {
        display: grid;
        gap: 8px;

        >h1 {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: center;
          color: #ffffff;
        }

        >div {
          display: grid;
          gap: 8px;

          >p {
            padding: 10px 24px 10px 24px;
            font-family: DM Sans;
            font-size: 14px;
            font-weight: 500;
            line-height: 23px;
            text-align: center;
            color: rgba(59, 77, 128, 1);
            background: rgba(229, 184, 11, 1);

          }

        }


      }

      .feature>div {
        height: 72px;

        >div {
          display: flex;
          align-items: center;
          /* width: 70px; */
          gap: 5px;

          >div:nth-of-type(1) {
            color: var(--primary-100);

            >span {
              font-size: 16px;
            }
          }

          >div:nth-of-type(2) {
            font-family: DM Sans;

            >p:nth-of-type(1) {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: var(--primary-100);
            }

            >p:nth-of-type(2) {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }

        >p {
          display: block;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: var(--primary-100);
          margin-left: 21px;
        }
      }

      .nextBtn {
        display: flex;
        /* width: 160px; */
        padding: 12px 20px;
        border-radius: 0px 40px 40px 0px;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        position: absolute;
        bottom: 48px;
        left: 0px;
        box-sizing: border-box;
        cursor: pointer;

        >p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--primary-600);
        }

        >span {
          color: var(--primary-500);
        }
      }
    }

    >div:nth-of-type(2) {
      width: 100%;
      background-color: var(--black-50);
      margin: 40px 0px;
      display: flex;
      padding: 24px 16px;
      border-radius: 0px 20px 20px 0px;


      >div {

        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        >div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: center;
          color: var(--black-500);
        }

        >div:nth-of-type(2) {
          margin-top: 64px;
        }
      }

      /* > div:nth-of-type(2) {
          border: 1px solid green;
        } */
    }
  }

  .featureEPF {
    height: 72px;
    display: grid;
    align-items: center;

    >div {
      display: flex;
      gap: 4px;

      >div:nth-of-type(1)>span {
        font-size: 21px;
      }
    }

    >p {
      margin-left: 25px;
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
}

@media screen and (max-width: 617px) {
  .container {
    padding: 48px 16px 64px 16px;
    display: flex;
    justify-content: center;

    >h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--black-500);
    }
  }

  .featuresContent {
    display: none;
  }

  .featuresContent_tab {
    display: none;
  }

  .featuresContent_mobile {
    display: flex;
    box-sizing: border-box;
    margin: 0px 16px;

    >div:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      gap: 29px;
      /* width: 180px; */
      height: 464px;
      padding: 33px 20px;
      border-radius: 20px;
      background: linear-gradient(0deg, #2e3c64, #2e3c64),
        linear-gradient(167.4deg,
          #6777a4 -12.27%,
          rgba(103, 119, 164, 0) 84.33%);
      box-shadow: 0px 4px 24px 0px #242f4e3d;
      position: relative;

      .heading {
        display: grid;
        gap: 8px;

        >h1 {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: center;
          color: #ffffff;
        }

        >div {
          display: grid;
          gap: 8px;

          >p {
            padding: 10px 24px 10px 24px;
            gap: 8px;
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
            color: rgba(59, 77, 128, 1);
            background: rgba(229, 184, 11, 1);

          }
        }
      }

      .feature>div {
        height: 72px;

        >div {
          display: flex;
          align-items: center;
          /* width: 70px; */
          gap: 5px;

          >div:nth-of-type(1) {
            color: var(--primary-100);

            >span {
              font-size: 16px;
            }
          }

          >div:nth-of-type(2) {
            font-family: DM Sans;

            >p:nth-of-type(1) {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: var(--primary-100);
            }

            >p:nth-of-type(2) {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }

        >p {
          display: block;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: var(--primary-100);
          margin-left: 21px;
        }
      }




    }

    >div:nth-of-type(2) {
      width: 68%;
      background-color: var(--black-50);
      display: flex;
      padding: 10px 5px 10px 5px;
      border-radius: 20px 20px 20px 20px;
      position: relative;

      >div {
        /* border: 1px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        >div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: center;
          color: var(--black-500);
        }

        >div:nth-of-type(2) {
          margin-top: 88px;
        }

        .nextCardBtn {
          /* width: 100px; */
         
          margin-top: 20px;
          width: 24px;
          height: 24px;
          padding: 4px;
          /* border-radius: 40px; */
          border-radius: 50%;
          background-color: #d9d9d9;
          display: flex;
          justify-content: center;
          align-items: center;

          >span {
            font-size: 15px;
          }
        }

        .left {
          position: absolute;
          bottom: 30px;
          left: 20px;
        }

        .right {
          position: absolute;
          bottom: 30px;
          right: 20px;
        }
      }
    }
  }

  .featureEPF {
    height: 72px;
    display: grid;
    align-items: center;

    >div {
      display: flex;
      gap: 4px;
      text-align: center;

      >div:nth-of-type(1)>span {
        font-size: 21px;
      }
    }

    >p {
      margin-left: 25px;
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
    }
  }
}