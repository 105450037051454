.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper {
  padding: 5px;
  z-index: 0;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  border: 1px solid #d3dbec;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.swiper-slide {
  width: 70%;
  height: 150px;
}

.swiper-slide:nth-child(2n) {
  width: 70%;
}

.swiper-slide:nth-child(3n) {
  width: 70%;
}

.swipper__slide-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
