/* Base Styles */
.container {
  height: 384px;
  background-color: rgba(36, 47, 78, 1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 23px 63px 0px 51px;
}

.content {
  display: flex;
  width: 86%;
  position: relative;
}

.left {
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  padding-right: 396px;
  padding-top: 90px;
}

.left > p {
  font-family: "DM Sans", sans-serif;
  font-size: 56px;
  font-weight: 600;
  line-height: 64px;
  color: rgba(255, 255, 255, 1);
}

.left > span {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.04em;
  color: rgba(196, 202, 220, 1);
  margin-top: 20px;
}

.logo {
  display: flex;
  height: 68px;
}

.mag {
  margin-left: -43px;
  margin-top: -3px;
  height: 51px;
}

.formContainer {
  position: fixed;
  top: 80px;
  /* Reduced from 80px for better visibility */
  right: 50px;
  width: 300px;
  /* Adjusted width for compact design */
  padding: 15px 20px;
  /* Reduced padding */
  border-radius: 16px;
  /* Slightly smaller radius */
  background: #ffffff;
  border: 1px solid #d3dbec;
  box-shadow: 0px 4px 24px 0px #41558d1f;
  z-index: 100;
  overflow-y: auto;
  /* Allows scrolling if content exceeds the height */
  /* max-height: 85vh; */
  /* Restrict height to ensure form fits the viewport */
  /* scrollbar-width: thin;
    scrollbar-color: #d1d5db #f1f5f9; */
}

.form {
  display: flex;
  flex-direction: column;
}

.form h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #24304e;
  margin-bottom: 2px;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.form label {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #24304e;
  margin-top: 8px;
  text-align: left;
}

/* Adjusting reCAPTCHA */
.formContainer > div {
  margin-top: 12px;
  /* Match the form element spacing */
}

/* Ensure ReCAPTCHA's styling matches form elements */
.recaptcha-wrapper iframe {
  width: 100% !important;
  border-radius: 4px;
  margin-top: 8px;
}

.form input,
.form select {
  max-width: 100%;
  /* Prevent overflow issues */
  font-family: DM Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  border: 1px solid #d3dbec;
  box-shadow: 0px 1.5px 2px 0px #41558d0a;
  padding: 3px 15px;
  border-radius: 40px;
}

.form button {
  margin-top: 10px;
  padding: 10px 24px;
  border-radius: 24px;
  background: #41558d;
  color: #ffffff;
  margin-bottom: 25px;
}

.form button:hover {
  cursor: pointer;
}

.contime {
  display: flex;
  gap: 16px;
  /* Space between the items */
  width: 100%;
  /* Ensure it stretches across the available space */
  margin-top: 10px;
}

.contime select {
  flex: 1;
  /* Make all children take up equal space */
  max-width: 100%;
  /* Prevent overflow issues */
  font-family: DM Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  border: 1px solid #d3dbec;
  box-shadow: 0px 1.5px 2px 0px #41558d0a;
  padding: 7px 13px;
  border-radius: 40px;
}

/* Thank You Message */
.thankYouMessage {
  text-align: center;
  padding: 20px;
}

.gifContainer img {
  width: 80px;
  /* Adjust size as needed */
  margin-bottom: 15px;
}

.thankYouMessage h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  color: #24304e;
  margin-bottom: 8px;
}

.thankYouMessage p {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  color: #24304e;
  margin-bottom: 20px;
}

.appButton {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #24304e;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.appButton:hover {
  background-color: #1f2a46;
}

/* Responsive Styles for Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    flex-direction: column;
    padding: 20px;
    height: auto;
    align-items: center;
  }

  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left {
    padding: 0;
  }

  .left > p {
    font-size: 36px;
    line-height: 44px;
  }

  .left > span {
    font-size: 14px;
    line-height: 22px;
  }

  .formContainer {
    position: static;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: none;
    border: 1px solid #d3dbec;
    border-radius: 16px;
  }

  .formContainer {
    max-height: 100%;
    /* Prevent scrolling */
    overflow-y: hidden;
  }

  .form h3 {
    font-size: 20px;
  }
}

/* Responsive Styles for Mobile */
@media screen and (max-width: 767px) {
  .container {
    padding: 0px 9px 9px 9px;
    flex-direction: column;
    height: 277px;
    align-items: center;
    justify-content: center;
  }

  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .left {
    padding: 0;
    margin-bottom: 15px;
  }

  .left > p {
    font-family: DM Sans;
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .left > span {
    font-size: 16px;
    line-height: 18px;
  }

  .formContainer {
    display: none;
  }
}
