/* Base styles for desktop */
.container {
  padding: 32px;
  gap: 48px;
  border-radius: 32px;
  border: 1px solid #d3dbec;
  background: #ffffff;
}

.topDiv {
  max-width: 1056px;
}

.topDiv > h1 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #2d2d2d;
  margin-bottom: 24px;
}

.topDiv > p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.topDiv > p > ul {
  margin-left: 40px;
}

.subInnerContainer {
  display: flex;
  max-height: 1448px;
  gap: 48px;
  margin-top: 48px;
}

.FAQside {
  max-width: 100%;
  padding: 16px;
  border-radius: 24px;
  border: 1px solid #d3dbec;
  background: #f4f7fe;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.stepsTitle {
  text-decoration: underline;
  margin-top: 16px;
}

.FAQques1 {
  /* width: 380px; */
  /* height: 48px; */
  padding: 12px 16px;
  gap: 10px;
  border-bottom: 1px solid #d3dbec;
}

.FAQques1 > h2 {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #6e6e6e;
}

.scrollOffset {
  scroll-margin-top: 60px; /* This property adjusts the scroll position */
}

.FAQquesAns {
  overflow: auto;
  max-height: 1448px;
  padding-left: 10px;
  gap: 32px;
  margin-left: 40px;
  width: 65%;
}

.FAQ1,
.FAQ2,
.FAQ3,
.FAQ4,
.FAQ5,
.FAQ6,
.FAQ7,
.FAQ8,
.FAQ9,
.FAQ10,
.FAQ11,
.FAQ12,
.FAQ13,
.FAQ14 {
  width: 596px;
  gap: 16px;
  margin-top: 32px;
}

.FAQ1 > h1,
.FAQ2 > h1,
.FAQ3 > h1,
.FAQ4 > h1,
.FAQ5 > h1,
.FAQ6 > h1,
.FAQ7 > h1,
.FAQ8 > h1,
.FAQ9 > h1,
.FAQ10 > h1,
.FAQ11 > h1,
.FAQ12 > h1,
.FAQ13 > h1,
.FAQ14 > h1 {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #41558d;
}

.FAQ1 > p,
.FAQ2 > p,
.FAQ3 > p,
.FAQ4 > p,
.FAQ5 > p,
.FAQ6 > p,
.FAQ7 > p,
.FAQ8 > p,
.FAQ9 > p,
.FAQ10 > p,
.FAQ11 > p,
.FAQ12 > p,
.FAQ13 > p,
.FAQ14 > p {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2d2d2d;
  margin-top: 16px;
  margin-left: 0;
  padding-left: 0;
}

.FAQ1 > ul,
.FAQ2 > ul,
.FAQ3 > ul,
.FAQ4 > ul,
.FAQ5 > ul,
.FAQ6 > ul,
.FAQ7 > ul,
.FAQ8 > ul,
.FAQ9 > ul,
.FAQ10 > ul,
.FAQ11 > ul,
.FAQ12 > ul,
.FAQ13 > ul,
.FAQ14 > ul {
  list-style-type: disc;
  margin-top: 16px;
  margin-left: 20px;
  padding-left: 0;
}

.FAQ1 > ul > li,
.FAQ2 > ul > li,
.FAQ3 > ul > li,
.FAQ4 > ul > li,
.FAQ5 > ul > li,
.FAQ6 > ul > li,
.FAQ7 > ul > li,
.FAQ8 > ul > li,
.FAQ9 > ul > li,
.FAQ10 > ul > li,
.FAQ11 > ul > li,
.FAQ12 > ul > li,
.FAQ13 > ul > li,
.FAQ14 > ul > li {
  margin-bottom: 8px;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2d2d2d;
}

.boldText {
  font-weight: 600;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
  .topDiv {
    width: 100%;
    height: auto;
  }

  .subInnerContainer {
    flex-direction: column;
  }

  .FAQside {
    width: 100%; /* Ensure it takes full width */
    padding: 12px; /* Adjust padding for better fit */
    max-width: 100%;
    box-shadow: none; /* Remove shadow if needed */
  }

  .FAQ1,
  .FAQ2,
  .FAQ3,
  .FAQ4,
  .FAQ5,
  .FAQ6,
  .FAQ7,
  .FAQ8,
  .FAQ9,
  .FAQ10,
  .FAQ11,
  .FAQ12,
  .FAQ13,
  .FAQ14 {
    width: 100%;
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 867px) {
  .container {
    padding: 16px;
    height: auto;
    border-radius: 0px;
  }

  .topDiv {
    width: 100%;
  }

  .FAQside {
    width: 100%; /* Ensure it takes full width */
    padding: 5px; /* Further reduce padding for smaller screens */
    max-width: 100%;
    border-radius: 16px; /* Smaller radius for smaller screens */
  }

  /* .FAQques1 {
    width: 100%;
  } */

  .FAQ1,
  .FAQ2,
  .FAQ3,
  .FAQ4,
  .FAQ5,
  .FAQ6,
  .FAQ7,
  .FAQ8,
  .FAQ9,
  .FAQ10,
  .FAQ11,
  .FAQ12,
  .FAQ13,
  .FAQ14 {
    width: 100%;
  }

  .FAQquesAns {
    margin-left: 0;
  }
}
