.container {
  width: 90%;
  max-width: 1128px;
  margin: auto;
}

.content {
  margin: 72px 0px;

  > h1 {
    font-size: 32px;
    text-align: center;
  }
}

.faqContainer {
  margin-top: 48px;
}

.ruler {
  color: var(--primary-200);
}

.link {
  color: #1b243b;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.list {
  > li {
    color: #575757;
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    > span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .container {
    max-width: 350px;
    margin: auto;
  }
}
