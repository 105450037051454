.kra-container {
  margin-top: 70px;
  line-height: 1.6;
  padding: 5% 10%;
  max-width: 100%;
  margin: 60px auto;
}

.kra-container h1 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.kra-container span {
  color: black;
}

.kra-container h2 {
  font-size: 1.2em;
  color: #555;
  margin-top: 20px;
}

.kra-container p {
  color: #666;
  margin-bottom: 10px;
}

.kra-container ol,
.kra-container ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

.kra-container a {
  color: #0066cc;
  text-decoration: none;
}

.kra-container a:hover {
  text-decoration: underline;
}

.kra-container ol li,
.kra-container ul li {
  margin-bottom: 5px;
}
