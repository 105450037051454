.contentContainer {
  margin: 72px 0px;
  padding: 48px;
  /* height: 340px; */
  background-color: var(--primary-800);
  border-radius: 23px;
  box-sizing: border-box;

  > h1 {
    font-family: DM Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #ffffff;
  }

  > div:nth-of-type(2) {
    color: var(--primary-200);
    font-size: 6px;
    > ul {
      list-style-type: none;

      > li {
        font-size: 10px;
      }
    }
  }
}

.calcContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 48px;

  > div:nth-of-type(1) {
    > div:nth-of-type(1) {
      display: flex;
      gap: 24px;
      justify-content: space-between;

      > p {
        font-family: DM Sans;
        width: 130px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: right;
        color: #ffffff;
        margin-top: 20px;
      }

      > div {
        width: 336px;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      gap: 24px;
      justify-content: space-between;

      > p {
        font-family: DM Sans;
        width: 130px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: right;
        color: #ffffff;
        margin-top: 20px;
      }
      > div {
        width: 336px;
      }
    }

    > div:nth-of-type(3) {
      display: flex;
      gap: 24px;
      justify-content: space-between;

      > p {
        font-family: DM Sans;
        width: 130px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: right;
        color: #ffffff;
        margin-top: 20px;
      }
      > div {
        width: 336px;
      }
    }
  }
}

.graphContainer {
  /* background-color: red; */
  position: relative;
  width: 402px;
  > div:nth-of-type(1) {
    display: flex;
    gap: 8px;

    > div:nth-of-type(1) {
      height: 32px;
      background-color: #eeae18;
      border-radius: 12px;
      /* width: 105px; */
    }
    > div:nth-of-type(2) {
      /* width: 289px; */
      height: 32px;
      background-color: var(--secondary-600);
      border-radius: 12px;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      gap: 8px;

      > div:nth-of-type(1) {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #ffffff;
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
          cursor: pointer;
        }
      }
    }

    > div:nth-of-type(2) {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #ffffff;
    }
  }

  > div:nth-of-type(3) {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    > div:nth-of-type(1) {
      display: flex;
      gap: 8px;
      color: #ffffff;

      > div:nth-of-type(1) {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }
    }

    > div:nth-of-type(2) {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #ffffff;
    }
  }
}

.tooltipContent {
  display: grid;
  height: 50px;
  > div {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.legend_1 {
  height: 16px;
  width: 16px;
  background-color: #eeae18;
  border-radius: 4px;
}

.legend_2 {
  height: 16px;
  width: 16px;
  background-color: #74a766;
  border-radius: 4px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contentContainer {
    margin: 0px;
    padding: 48px 16px;
    /* height: 550px; */
    background-color: var(--primary-800);
    border-radius: 0px;
    box-sizing: border-box;
    > h1 {
      font-size: 24px;
      line-height: 32px;
    }

    > div:nth-of-type(2) {
      margin-top: 20px;
    }
  }

  .calcContainer {
    display: block;
    /* gap: 138px; */
    margin-top: 48px;

    > div:nth-of-type(1) {
      > div:nth-of-type(1) {
        display: block;

        > p {
          width: 100%;
          text-align: left;
          color: #ffffff;
        }

        > div {
          width: 100%;
        }
      }

      > div:nth-of-type(2) {
        display: block;

        > p {
          width: 100%;
          text-align: left;
        }
        > div {
          width: 100%;
        }
      }

      > div:nth-of-type(3) {
        display: block;

        > p {
          width: 100%;
          text-align: left;
        }
        > div {
          width: 100%;
        }
      }
    }
  }

  .graphContainer {
    /* background-color: red; */
    margin-top: 48px;
    width: 100%;
    > div:nth-of-type(1) {
      display: flex;
      gap: 8px;

      > div:nth-of-type(1) {
        height: 32px;
        background-color: #eeae18;
        border-radius: 12px;
        /* width: 105px; */
      }
      > div:nth-of-type(2) {
        /* width: 289px; */
        height: 32px;
        background-color: var(--secondary-600);
        border-radius: 12px;
      }
    }
    > div:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      > div:nth-of-type(1) {
        display: flex;
        gap: 8px;

        > div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }
      }

      > div:nth-of-type(2) {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #ffffff;
      }
    }

    > div:nth-of-type(3) {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      > div:nth-of-type(1) {
        display: flex;
        gap: 8px;

        > p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }
      }

      > div:nth-of-type(2) {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .contentContainer {
    margin: 0px;
    padding: 48px 16px;
    /* height: 550px; */
    background-color: var(--primary-800);
    border-radius: 0px;
    box-sizing: border-box;
    > h1 {
      font-size: 24px;
      line-height: 32px;
    }

    > div:nth-of-type(2) {
      margin-top: 20px;
    }
  }

  .calcContainer {
    display: block;
    /* gap: 138px; */
    margin-top: 48px;

    > div:nth-of-type(1) {
      > div:nth-of-type(1) {
        display: block;

        > p {
          width: 100%;
          text-align: left;
          /* color: #ffffff; */
        }

        > div {
          width: 100%;
        }
      }

      > div:nth-of-type(2) {
        display: block;

        > p {
          width: 100%;
          text-align: left;
        }
        > div {
          width: 100%;
        }
      }

      > div:nth-of-type(3) {
        display: block;

        > p {
          width: 100%;
          text-align: left;
        }
        > div {
          width: 100%;
        }
      }
    }
  }

  .graphContainer {
    /* background-color: red; */
    margin-top: 48px;
    width: 100%;
    > div:nth-of-type(1) {
      display: flex;
      gap: 8px;

      > div:nth-of-type(1) {
        height: 32px;
        background-color: #eeae18;
        border-radius: 12px;
        /* width: 105px; */
      }
      > div:nth-of-type(2) {
        /* width: 289px; */
        height: 32px;
        background-color: var(--secondary-600);
        border-radius: 12px;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      > div:nth-of-type(1) {
        display: flex;
        gap: 8px;

        > div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }
      }

      > div:nth-of-type(2) {
        display: flex;
        gap: 8px;

        > div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }
      }
    }

    > div:nth-of-type(3) {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      > div:nth-of-type(1) {
        display: flex;
        gap: 8px;

        > p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }
      }

      > div:nth-of-type(2) {
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #ffffff;
      }
    }
  }
}
