.accordion {
    /* background-color: #eee; */
    color: #444;
    cursor: pointer;
    padding: 18px;
    /* width: 100%; */
    /* border-bottom: 1px solid black; */
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    display: flex;
    align-items: center;
    gap: 18px;
    font-size: 16px;
  }
  
  .panel {
    padding: 0 16px 0 60px;
    background-color: white;
    overflow: hidden;
    margin-bottom: 16px;
  }
  
  ul > li {
    font-size: 16px;
  }
  