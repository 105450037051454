/* General Form Container Styles */
.formContainer {
  width: 90%;
  margin-top: 20px;
  max-width: 760px;
  padding: 15px;
  box-sizing: border-box;
  /* border-radius: 16px; */
  border: none;
  background: #ffffff;
  box-shadow: none;
  z-index: 100;
  /* overflow-y: auto; Allows scrolling if content exceeds the height */
  /* max-height: 85vh; Restrict height to ensure form fits the viewport */
  /* scrollbar-width: thin; scrollbar-color: #d1d5db #f1f5f9; */
}

.formContainer {
  /* margin: 10px; */
  /* padding: 10px; */
  /* max-width: 103%; */
  /* border: none; */
  /* box-shadow: none; */
  /* display: none; */
}

/* Form Styles */
.form {
  display: flex;
  flex-direction: column;
}

.form h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #24304e;
  margin-bottom: 2px;
}

.form label {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #24304e;
  margin-top: 8px;
  text-align: left;
}

.form input,
.form select {
  max-width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #d3dbec;
  box-shadow: 0px 1.5px 2px 0px #41558d0a;
  padding: 11px;
  border-radius: 40px;
  height: 40px;
  box-sizing: border-box;
}

.form button {
  margin-top: 10px;
  padding: 11px;
  border-radius: 24px;
  background: #41558d;
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 25px;
}

.form button:hover {
  cursor: pointer;
}

/* Error Message Styles */
.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* reCAPTCHA Styles */
.formContainer > div {
  margin-top: 12px; /* Match the form element spacing */
}

.recaptcha-wrapper iframe {
  width: 100% !important;
  border-radius: 4px;
  margin-top: 8px;
}

/* Time Container Styles */
.contime {
  display: flex;
  justify-content: space-between;
  gap: 16px; /* Space between the items */
  width: 100%; /* Ensure it stretches across the available space */
  margin-top: 10px;
}

.contime > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.contime select {
  max-width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  /* text-align: center; */
  border: 1px solid #d3dbec;
  box-shadow: 0px 1.5px 2px 0px #41558d0a;
  padding: 7px 13px;
  /* border-radius: 40px; */
  appearance: none; /* Removes default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: white;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4gPHBhdGggZD0iTTEgNUwxNCA1TDEwLjUgOUw3IDEzTDEgNSIgZmlsbD0iIzMzMyIvPiA8L3N2Zz4="); /* Custom arrow SVG */
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 10px 10px;
  padding-right: 35px; /* Add space for the custom arrow */
  border-radius: 20px;
  height: 40px;
}

/* Thank You Message Styles */
.thankYouMessage {
  text-align: center;
  padding: 20px;
}

.gifContainer img {
  width: 80px; /* Adjust size as needed */
  margin-bottom: 15px;
}

.thankYouMessage h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  color: #24304e;
  margin-bottom: 8px;
}

.thankYouMessage p {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  color: #24304e;
  margin-bottom: 20px;
}

/* Button Styles */
.appButton {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #24304e;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.appButton:hover {
  background-color: #1f2a46;
}
