.accordion {
  color: #444;
  cursor: pointer;
  padding: 18px;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 16px;

  > span {
    color: #2d2d2d;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.04em;
  }
}

.panel {
  padding: 0 16px 0 50px;
  background-color: white;
  overflow: hidden;
  margin-bottom: 16px;

  > span {
    color: #575757;
    font-family: DM Sans;
    /* font-size: 14px; */
    font-weight: 400;
    line-height: 20px;
  }
}
