.content {
  max-width: 1089px;
  margin: auto;
  /* border: 1px solid red; */

  > h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    line-height: -0.04em;
    text-align: center;
    margin-top: 40px;
  }

  > div {
    max-width: 1512px;

    > img {
      width: 100%;
    }
  }
}

.img2 {
  display: none;
}

@media screen and (max-width: 1023px) {

  .img1{
    display: none;
  }

  .img2{
    display: block;
  }

  .content {
    max-width: 390px;
    margin: auto;

    > h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      line-height: -0.04em;
      text-align: center;
      margin-top: 20px;
    }
  }
}
