.container {
  width: 100%;
  max-width: 320px;
}

.contentContainer {
  margin: 0 auto;
  max-width: 320px;
  /* padding: 0 1rem; */
}

.tabsContainer {
  position: relative;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tabsContainer::-webkit-scrollbar {
  display: none;
}

.tabsList {
  display: flex;
  gap: 1rem;
  white-space: nowrap;
}

.tab {
  position: relative;
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  transition: color 0.2s;
  color: #1b243b80;
  background-color: var(--primary-50);
  padding: 10px 0px;
}

.activeTab {
  color: #41558d;
  border-bottom: 1px dashed #41558d;
  border-radius: 0px;
}

.tabIndicator {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.titleSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.description {
  font-size: 1.125rem;
  color: var(--muted-foreground);
  width: 320px;
}

.imageContainer {
  height: 372px;
  background: linear-gradient(
    190.28deg,
    #ffffff 28.92%,
    rgba(112, 142, 228, 0.15) 413.12%
  );
  border: 1px solid #eceef4;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;

  > img {
    width: 170.91px;
    height: 310.57px;
  }
}

.image {
  margin: 0 auto;
}

.learnMore {
  padding-top: 48px;
}

.learnMoreButton {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.23px;
  letter-spacing: -0.04em;
  text-decoration: underline;
  text-underline-offset: 10px;
  color: #41558D;
  border-radius: 0px;
  background-color: var(--primary-50);
  padding: 0;
}
