/* Base styles for desktop */
.container {
  padding: 30px 96px 60px 96px;
  background-color: #f4f7fe;
}

.breadCrumb {
  display: flex;
  align-items: center;
  margin: 48px 0 24px;
  cursor: pointer;
}

.breadCrumb > h1 {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #2d2d2d;
}
/* Tablet View (768px and below) */
@media (max-width: 768px) {
  .container {
    padding: 40px 0px;
  }

  .breadCrumb > h1 {
    /* font-size: 18px; */
    line-height: 26px;
  }
}

/* Mobile View (480px and below) */
@media (max-width: 480px) {
  .container {
    padding: 60px 0px;
  }

  .breadCrumb {
    margin: 24px 0 16px;
  }

  .breadCrumb > h1 {
    /* font-size: 16px; */
    line-height: 24px;
  }
}
