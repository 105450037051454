.insurance {
  margin-top: 60px;
  padding: 5% 8%;
  background-color: #f4f7fe;
}

.insurance__button {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 40px;
  border: none;
}

.insurance__button-mobile {
  width: 100%;
  background-color: #41558d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
}

.insurance__header {
  background-color: #2e3c64;
  border-radius: 32px;
  padding: 48px;
  display: flex;
  justify-content: space-between;
  background-image: url("../../public/Insurance/lines.svg");
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}

.insurance__header div:first-child h1 {
  color: white;
  font-size: 42px;
}

.insurance__header p {
  color: #c4cadc;
  font-size: 16px;
}

.insurance__contact {
  display: flex;
  column-gap: 20px;
  margin-top: 50px;
}

.insurance__button--primary {
  color: #3b4d80;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
}

.insurance__button--secondary {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.insurance__ditto {
  background-color: white;
  width: 30%;
  border-radius: 24px;
  border: 1px solid #d3dbec;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  z-index: 10;
}

.insurance__ditto > div:first-child {
  display: flex;
  justify-content: space-between;
}

.insurance__ditto > div:first-child img:first-child {
  height: 48px;
  width: 48px;
}

.insurance__ditto h3 {
  font-weight: 600;
  font-size: 24px;
}

.insurance__trusts {
  margin: 50px auto;
}

.insurance__trusts h1 {
  font-size: 32px;
  text-align: center;
}

.insurance__trusts > div {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.insurance__trusts > div h2 {
  text-align: center;
}

.insurance__divider {
  border-left: 1px solid #c4cadc;
  height: 30px;
}

.insurance__get-started {
  padding: 32px;
  border: 1px solid #c4cadc;
  border-radius: 32px;
  margin-top: 40px;
  background-color: white;
}

.insurance__get-started span {
  display: inline-block;
  height: 42px;
  width: 42px;
  border: 1px solid #c4cadc;
  border-radius: 50%;
  background-color: #edeef4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance__get-started > div > div {
  display: flex;
  column-gap: 10px;
}

.insurance__get-started h2 {
  margin-top: 5px;
}

#a {
  color: #41558d;
  text-decoration: underline;
}

.insurance__plans {
  margin-top: 50px;
}

.insurance__plans > div {
  display: flex;
  justify-content: space-between;
  gap: 10%;
  margin-bottom: 50px;
}

.insurance > div img {
  height: auto;
  width: auto;
}

.insurance__button--tertiary {
  background-color: white;
  color: #41558d;
  border: 1px solid #cdd5e5;
}

@media all and (max-width: 767px) {
  .insurance {
    padding: 20px;
  }

  .insurance__header {
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    background-image: url("../../public/Insurance/lines-mobile.svg");
    background-size: contain;
    background-position: center 105%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .insurance__button--secondary {
    background-color: #3b4d80;
  }

  .insurance__header div:first-child h1 {
    font-size: 32px;
  }

  .insurance__header p {
    font-size: 14px;
  }

  .insurance__ditto {
    width: auto;
    margin: 20px auto;
    padding: 20px;
  }

  .insurance__ditto h3 {
    font-size: 20px;
  }

  .insurance__ditto p {
    color: #727272;
    font-size: 14px;
  }

  .insurance__contact {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .insurance__trusts {
    padding: 10px;
  }

  .insurance__trusts > div {
    flex-direction: column;
    row-gap: 30px;
    justify-content: start;
    align-items: start;
   
    padding-left: 30px;
    margin: 20px auto;
  }

  .insurance__trusts h1 {
    font-size: 24px;
    text-align: start;
  }

  .insurance__trusts div h2 {
    text-align: start;
    font-size: 20px;
  }

  .insurance__get-started h1 {
    font-size: 24px;
  }

  .insurance__get-started h2 {
    font-size: 20px;
  }

  .insurance__get-started p {
    font-size: 14px;
  }

  .insurance__divider {
    margin: 10px auto;
    border: none;
    width: 30%;
    border-bottom: 1px solid #c4cadc;
  }

  .insurance__trusts .insurance__divider {
    display: none;
  }

  .insurance__plans h1 {
    font-size: 24px;
  }

  .insurance__plans p {
    font-size: 14px;
  }

  .insurance__plans > div:nth-child(1) {
    flex-direction: column;
    row-gap: 10px;
  }

  .insurance__plans > div:nth-child(2) {
    flex-direction: column-reverse;
    row-gap: 10px;
  }

  .insurance > div img {
    max-width: 100%;
    height: auto;
    width: auto;
  }
}

.insurance__bottom {
  background-color: #2e3c64;
  border-radius: 32px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  background-image: url("../../public/Insurance/lines.svg");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.insurance__bottom > h1,
.insurance__bottom > p {
  color: white;
  text-align: center;
  margin-bottom: 5px;
}
