.termsandconditionsheeding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 30px; */
  margin-bottom: 30px;
  font-size: 26px;
  margin: 0 10%;
  /* padding-top: 60px; */
}
.font__green {
  color: #198754;
}
.contentstartshere {
  display: flex;
  flex-direction: column;
  text-align: justify;
  justify-content: flex-start;
  align-items: flex-start;
}
.termsandconditionspage {
  padding: 60px 10%;
}
.sharewaysecuritieslimited {
  font-size: 24px;
  font-weight: 600;
  /* padding-top: 20px; */
}
.mt-5 {
  margin-top: 20px;
  margin-bottom: 16px;
}
.investercharter {
  justify-content: center;
  width: 80%;
  display: flex;
  align-items: center;
}
.heeding3standcpage {
  margin: 8px 0;
  font-size: 28px;
}
.copyrightssection {
  font-size: 16px;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
}
.bigBoldFont {
  font-size: 1.5em;
  font-weight: 600;
}
/* .table-investorcharter {
  width: 100vh;
  margin: 20px 0;
} */
.table-investorcharter td,
.table-investorcharter th {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
}
.table-investorcharter tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-investorcharter th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgb(248, 248, 199) !important;
  color: black;
}
.olol {
  list-style-type: decimal;
  margin-left: 40px;
}
.sharewaysecuritieslimitedininvestorcomplainspage {
  font-size: 24px;
  font-weight: 600;
  padding-top: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.contentstartshereinvestor {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.linksandbuttonsofincvestorcomplaints {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  align-items: center;
  margin-top: 20px;
}

.cardsbodyforinvestorpage {
  padding: 10px 20px;
  background-color: black;
  margin: 10px;
  max-width: 200px;
  text-align: center;
  font-size: 18px;
  font-weight: none;
}
.text-light {
  color: white;
}

@media (min-width: 600px) {
  .linksandbuttonsofincvestorcomplaints {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 900px) {
  .linksandbuttonsofincvestorcomplaints {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 600px) {
  .cardsbodyforinvestorpage {
    margin: 10px 10px 10px 0px;
    padding: 12px;
    font-size: 16px;
  }

  .termsandconditionspage {
    padding: 60px 2%;
  }
}

.Trendofanualdepoits {
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
}

/* Optional: Set table styles for better presentation */
.table-investorcharter {
  width: 100%; /* Set table width to 100% of its container */
  /* table-layout: fixed; Fix column widths for horizontal scrolling */
  overflow-x: auto;
}

.annualtrendtable {
  overflow: hidden;
  text-overflow: ellipsis;
}
