:root {
  --primary-50: #eceef4;
  --primary-100: #c4cadc;
  --primary-200: #a8b1cb;
  --primary-300: #808db3;
  --primary-400: #6777a4;
  --primary-500: #41558d;
  --primary-600: #3b4d80;
  --primary-700: #2e3c64;
  --primary-800: #242f4e;
  --primary-900: #1b243b;

  --secondary-50: #f2f8f1;
  --secondary-100: #d8e9d3;
  --secondary-200: #c5debd;
  --secondary-300: #aacf9f;
  --secondary-400: #99c68d;
  --secondary-500: #80b870;
  --secondary-600: #74a766;
  --secondary-700: #5b8350;
  --secondary-800: #46653e;
  --secondary-900: #364d2f;

  --error-50: #fff0f0;
  --error-100: #ffd0d0;
  --error-200: #ffb9b9;
  --error-300: #ff9898;
  --error-400: #ff8585;
  --error-500: #ff6666;
  --error-600: #e85d5d;
  --error-700: #b54848;
  --error-800: #8c3838;
  --error-900: #6b2b2b;

  --warning-50: #fdf7e8;
  --warning-100: #fae6b7;
  --warning-200: #f7da95;
  --warning-300: #f4c964;
  --warning-400: #f1be46;
  --warning-500: #eeae18;
  --warning-600: #d99e16;
  --warning-700: #a97c11;
  --warning-800: #83600d;
  --warning-900: #64490a;

  --black-50: #eaeaea;
  --black-100: #bebebe;
  --black-200: #9e9e9e;
  --black-300: #727272;
  --black-400: #575757;
  --black-500: #2d2d2d;
  --black-600: #292929;
  --black-700: #202020;
  --black-800: #191919;
  --black-900: #131313;
}

.container {
  padding-top: 60px;
}

.herosection {
  /* background: linear-gradient(
    72.62363656667253deg,
    #242f4e 0%,
    rgba(112, 142, 228, 1) 100%
  );
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #242f4e;
  padding-top: 92px;
  padding-bottom: 88px;
  /* height: 768px; */
}

.grow {
  background-color: var(--primary-50);

  > img {
    display: none;
  }
}

.link {
  color: #41558d;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.04px;
  letter-spacing: -0.04em;
  text-decoration: underline;
  text-underline-offset: 10px;
  cursor: pointer;
}

.blog {
  background-color: var(--primary-50);
}

/* .pricing {
  height: 957.33px;
} */

.download {
  background-color: #f2f8f1;
}

.getStarted {
  background-color: var(--primary-50);
}

@media screen and (max-width: 1023px) {
  .herosection {
    background-color: #242f4e;
    padding-top: 56px;
    /* height: 768px; */
  }

  .grow {
    background-color: var(--primary-50);
    position: relative;

    > img {
      display: block;
      position: absolute;
    }
  }

  .tab1 {
    display: block;
    justify-content: center;

    > div:nth-of-type(1) {
      width: 320px;
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;

      > a {
        color: #41558d;
        font-size: 16px;
        font-weight: 600;
        line-height: 26.04px;
        letter-spacing: -0.04em;
        text-decoration: underline;
        text-underline-offset: 10px;
        cursor: pointer;
      }
    }

    > div:nth-of-type(2) {
      width: 350px;
      height: 372px;

      > img {
        width: 170.91px;
        height: 310.57px;
      }
    }
  }

  .page {
    /* min-height: 100vh; */
    background-color: var(--background);
  }
}
