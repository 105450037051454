.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.aboutustopsection {
  background-color: #f4f7fe;
  display: flex;
  gap: 24px;
  justify-content: center;
  padding-top: 60px;
}
.leftpartoftopsection {
  border: #d3dbec 1px solid;
  background-color: #fff;
  padding: 32px;
  border-radius: 32px;
  margin-top: 92px;
  margin-bottom: 144px;
  box-shadow: 0px 8px 24px 0px #41558d1f;
}
.decideyourfuture {
  letter-spacing: -2.24px;
  font-size: 56px;
  max-width: 13ch;
  color: #1b243b;
}
.rightpartoftopsection {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.fabitsstorey {
  color: #575757;
  font-size: 16px;
  max-width: 44ch;
}
.meetourteam {
  padding: 10px 24px;
  margin-top: 68px;
  border-radius: 40px;
  font-size: 16px;
  border: #2d2d2d 1px solid;
  background-color: #fff;
  color: #2d2d2d;
}
.righttposection {
  border-radius: 32px;
  display: flex;
  margin-top: 92px;
  box-shadow: 0px 8px 24px 0px #41558d1f;
}
.experenceandplaystore {
  display: flex;
}
.satisfiedusersandgains {
  display: flex;
}
.experence {
  font-size: 32px;
  font-weight: 800;
  color: #1b243b;
}
.experencepara {
  color: #727272;
  font-size: 16px;
  padding-top: 8px;
}
.datainsideboxes {
  background-color: #f4f7fe;
  padding: 16px 24px;
  width: 50%;
  border-radius: 16px;
}
.rightbottemsection {
  background-color: #fff;
  padding: 24px;
  border: #d3dbec 1px solid;
  border-radius: 32px;
  box-shadow: 0px 8px 24px 0px #41558d1f;
}
#experenceheadr {
  margin: 0 24px 24px 0;
}
#ratingheater {
  margin: 0 0 24px 0;
}
#satisifyedusers {
  margin: 0 24px 0 0;
}
#etfbaseddiversi {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  color: #727272;
  font-size: 16px;
}
.aboutussecondsection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ourpurpose {
  color: #1b243b;
  font-size: 32px;
  margin: 72px 0 8px 0;
}
.aboutusheading {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.visionandmission {
  color: #575757;
  font-size: 16px;
}
#mission {
  margin-bottom: 72px;
}
.knowourteamtext {
  font-size: 32px;
  color: #1b243b;
  margin-bottom: 8px;
}
.knowourteamsection {
  padding: 72px 20px;
  background-color: #f4f7fe;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.knowourteambelowtextpara {
  font-size: 16px;
  color: #2d2d2d;
  padding-bottom: 48px;
}
.aboutuspagephotssection {
  display: grid;
  grid-template-columns: auto auto auto;
}

.imagesonaboutuspage {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: filter 0.15s ease-in-out;
  max-width: 312px;
  max-height: 397px;
  height: 100%;
  width: auto;
  margin: auto;
  object-fit: cover;
}

.photosections {
  display: flex;
  background-color: #c4cadc;
  border: 1px solid #d3dbec;
  margin: 12px;
  border-radius: 24px;
  padding: 24px 24px 0 24px;
  position: relative;
  overflow: hidden;
}

.photosections:hover .imagesonaboutuspage,
.linkdinarea:hover ~ .photosections .imagesonaboutuspage,
.photosections.active .imagesonaboutuspage {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.linkdinarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px #2d2d2d1f;
  border-radius: 16px;
  padding: 12px 16px;
  margin: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(calc(100% + 16px));
  transition: transform 0.15s ease-in-out;
  max-height: 0; /* Add this line */
  overflow: hidden; /* Add this line */
}
.Bio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.photosections:hover .linkdinarea,
.photosections.active .linkdinarea {
  transform: translateY(0);
  max-height: 100px; /* Add this line */
}

#frame455 {
  border-radius: 32px;
  box-shadow: 0px 8px 24px 0px #41558d29;
  max-width: 552px;
  max-height: 300px;
}

.linkedinicon {
  margin-left: auto;
}

.linkdinlink {
  text-decoration: none;
  display: flex;
  width: 100%;
  align-items: center;
}

.nameofperson {
  color: #1b243b;
  font-size: 20px;
}

.designationofperson {
  color: #575757;
  font-size: 16px;
}
@media (max-width: 700px) {
  .aboutustopsection {
    padding: 60px 20px 0 20px;
    flex-direction: column;
    align-items: center;
  }
  .meetourteam {
    margin-top: 24px;
    width: 100%;
  }
  .leftpartoftopsection {
    margin-top: 48px;
    margin-bottom: 0;
    padding: 24px;
    border-radius: 24px;
  }
  .righttposection {
    margin-top: 24px;
  }
  .decideyourfuture {
    font-size: 36px;
  }
  #etfbaseddiversi {
    margin: 24px 0;
    font-size: 10px;
  }
  #frame455 {
    width: 100%;
    border-radius: 24px;
  }
  .datainsideboxes {
    padding: 12px;
  }
  .experence {
    font-size: 22px;
  }
  .experencepara {
    font-size: 12px;
  }
  .rightbottemsection {
    margin-left: 0;
    border-radius: 24px;
  }
  .knowourteambelowtextpara {
    font-size: 14px;
  }
  .ourpurpose {
    margin: 48px 0 8px 0;
    font-size: 24px;
  }
  .aboutussecondsection {
    text-align: center;
  }
  .knowourteamsection {
    padding: 48px 20px;
    text-align: center;
  }
  .aboutuspagephotssection {
    grid-template-columns: auto;
  }
  .visionandmission {
    max-width: 30ch;
  }
  #mission {
    margin-top: 8px;
    margin-bottom: 48px;
  }
  .photosections {
    justify-content: center;
    margin: 12px 0 12px 0;
  }
  .fabitsstorey {
    font-size: 14px;
  }
}
@media (min-width: 501px) and (max-width: 1247px) {
  .aboutustopsection {
    flex-direction: column;
    padding: 60px 20px;
  }
  .leftpartoftopsection {
    margin-bottom: 24px;
  }
  .righttposection {
    margin-top: 24px;
  }
  .rightbottemsection {
    margin-left: 0;
  }
  #frame455 {
    width: 100%;
    margin-left: 0;
  }
  #etfbaseddiversi {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .fabitsstorey {
    max-width: 100%;
  }
  .aboutuspagephotssection {
    grid-template-columns: auto;
  }
}
.pricingtopfirstsection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242f4e;
  gap: 96px;
  padding: 130px 160px 96px 160px;
}
.pricingtopleftsectionh3 {
  color: #f6f6f6;
  font-size: 56px;
  max-width: 18ch;
}
.pricingtopleftsectionp {
  color: #c4cadc;
  font-size: 16px;
}
.pricingtopleftsection {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.pricingslidersection {
  display: flex;
  flex-direction: column;
}
#savingpiggy {
  padding: 8px;
  background-color: #c89ac5;
  border-radius: 40px;
}
.checkoutyoursavingssection {
  display: flex;
  align-items: center;
  gap: 16px;
}
.checkoutyoursaving {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}
#myRange {
  appearance: none;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  height: 6px;
}
#myRange::-webkit-slider-thumb {
  box-shadow: 0px 1.5px 4px 0px #99c68d1f;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background-color: #80b870;
  cursor: pointer;
  border-radius: 50%;
  border: 6px solid #ffffff;
}

.pricingtoprightsection {
  background-color: #2e3c64;
  padding: 32px;
  width: 50%;
  border-radius: 32px;
  border: 1px solid #3b4d80;
}
.amoutyouwillinvest {
  margin-top: 16px;
  color: #c4cadc;
  display: flex;
  font-size: 16px;
  align-items: flex-end;
}
.thesavedamount {
  color: #fff;
  margin-left: 8px;
  font-size: 20px;
}
.pricingsliderlabel {
  color: #a8b1cb;
  margin-bottom: 8px;
}
.pricingslidersectionbottomp {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  color: #c4cadc;
}
.checkoutyoursavingssectionbelowheading {
  margin: 48px 0px 30px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #6777a4;
  display: flex;

}
.checkoutyoursavingmf {
  color: #fff;
  font-size: 20px;
  white-space: nowrap;
}
.mutualfundsaving {
  color: #c4cadc;
  font-size: 16px;
}
.ifyouinvestindirectmf {
  border-right: 1px dotted #6777a4;
  padding-right: 24px;
  width: 50%;
}
.ifyouinvestinfabits {
  padding-left: 24px;
  width: 50%;
}
.pricingtopleftsectionbutton {
  background-color: #f6f6f6;
  padding: 10px 24px;
  font-size: 16px;
  border: 0;
  border-radius: 40px;
  color: #3b4d80;
  cursor: pointer;
}
.pricingtopleftsectionbutton1 {
  background-color: #242f4e;
  border: 0;
  font-size: 16px;
  border-bottom: #f6f6f6 1px dashed;
  color: #f6f6f6;
  cursor: pointer;
  margin-left: 24px;
}
.mutualfundsavingbelowheeding {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.mutualfundsavingbelowheedingp {
  color: #c4cadc;
  font-size: 16px;
}
.mutualfundsavingbelowheedingp1 {
  color: #fff;
  font-size: 16px;
}
.fabitssavingbelowheeding {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 30px;
  white-space: nowrap;
}
.fabitssavingbelowheedingp {
  color: #99c68d;
  text-align: center;
  align-items: center;
  font-size: 16px;
  border-bottom: #99c68d 1px dashed;
}
.fabitssavingbelowheedingp1 {
  color: #fff;
  font-size: 16px;
}
.yearsavinginfabitsp {
  font-size: 16px;
  color: #c4cadc;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.yearsavinginfabitsp1 {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
}
#yesrsaving {
  /* margin-top: 24px; */
  margin-bottom: 12px;
}
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  background-color: #41558d;
  color: #fff;
  text-align: center;
  border-radius: 16px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);
  opacity: 0;
  transition: opacity 0.3s;
}
.planssectionpricecomparations-mobile {
  display: none;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #41558d transparent;
}

.tooltip .show {
  visibility: visible;
  opacity: 1;
  display: flex;
  gap: 8px;
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px #00000029;
  width: 344px;
  margin-left: 40px;
}
.tooltiptextcontent1 {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.tooltiptextcontent1p {
  color: #c4cadc;
  font-size: 14px;
}
.tooltiptextcontent1p1 {
  color: #fff;
  font-size: 16px;
  width: 40%;
  text-align: end;
}
.savingyear {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saving10years {
  display: flex;
  justify-content: space-between;
}
#savingin10years {
  display: flex;
  align-items: center;
  max-width: 22ch;
}
.Checkoutourplanspricingpage {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #2d2d2d;
}
.planssectionpricecomparations {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 48px;
  margin-bottom: 24px;
}
.planssectionpricingpage {
  background-color: #f4f7fe;
  padding: 72px 192px;
}
.cardssectionpricingpage {
  background-color: #fff;
  padding: 16px;
  border-radius: 20px;
  margin-top: 42px;
  width: 20%;
}
.hikingimage {
  padding: 8px;
  border-radius: 40px;
  background-color: #eaeaea;
}
.allergyimage {
  padding: 8px;
  border-radius: 40px;
  background-color: #fdf7e8;
}
.pricingtopleftsectionbutton1-mobile {
  display: none;
}
.psychiatryimage {
  padding: 8px;
  border-radius: 40px;
  background-color: #f2f8f1;
}
.parkimage {
  padding: 8px;
  border-radius: 40px;
  background-color: #c4cadc;
}
.awardstart {
  padding: 8px;
  border-radius: 40px;
  background-color: #ece3fb;
}
.Starterdesktoppricingh2 {
  padding-top: 24px;
  font-size: 24px;
  line-height: 32px;
  color: #2d2d2d;
  font-weight: 600;
}
.Starterdesktoppricingh4 {
  font-size: 42px;
  line-height: 54px;
  color: #2d2d2d;
  font-weight: 600;
  padding-top: 4px;
}
.Starterdesktoppricingp {
  font-size: 16px;
  line-height: 24px;
  color: #9e9e9e;
  font-weight: 500;
}
.Starterdesktoppricingbutton {
  /* margin-top: 48px; */
  width: 100%;
  padding: 10px 24px;
  background-color: #41558d;
  cursor: pointer;
  border: 0;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
}
.comparationtablepricingpage {
  padding: 0;
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  border-radius: 32px;
}

th {
  text-align: center;
  padding: 32px 32px 16px 32px;
  font-size: 16px;
  color: #2d2d2d;
}
td {
  text-align: center;
}
.featuresheedingpricingpage1 {
  text-align: start;
}
.featuresidepricingpage {
  padding: 16px 32px;
  font-size: 16px;
  text-align: start;
  color: #575757;
}
tr {
  border-bottom: 1px solid #d3dbec;
}
.showallbenefitspricingpage {
  border: none;
  background-color: #fff;
  color: #41558d;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 26px;
  border-bottom: 2px dashed #41558d;
}
.showallbelowtablepricingpage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0;
}
.pricingtopleftsectionbutton-mobile {
  display: none;
}
.pricingpagebottomsection {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  /* border-radius: 32px; */
  /* border: 1px solid #d3dbec; */
  padding: 80px 32px;
}
.Additioinalinfoheadingpricingpage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}
.tableheedingadditionalinformationsection {
  text-align: start;
  padding: 16px 24px;
}
.tabledataoftheadditionalsection {
  text-align: start;
}
table {
  border-radius: 32px;
}
.additionnalinformationtablesectiontable {
  border-collapse: collapse;
  border-radius: 24px;
  width: 100%;
}
.additionalinformationtablesection {
  border: 1px solid #d3dbec;
  width: 50%;
  border-radius: 24px;
  height: 50%;
}
.leftpartthing {
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 600;
}
.tabledataoftheadditionalsection {
  padding: 12px 24px;
  border-bottom: 1px solid #d3dbec;
}
.lastboxofadditionalinformation {
  border-bottom: none;
}
.additionalinformationandtextbeside {
  display: flex;
  gap: 48px;
}
.textsectionbesideadditionalinformationp {
  max-width: 50ch;
  color: #727272;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}
.textsectionbesideadditionalinformationh4 {
  font-size: 16px;
  color: #2d2d2d;
  font-weight: 600;
  margin-bottom: 4px;
}
.lasttableitempricepage {
  border-bottom: none;
}
.lastparaoftheadditionalsection {
  /* border-bottom: 1px dashed #d3dbec; */
  padding-bottom: 24px;
  margin: 0;
}
.textsectionbesideadditionalsectionlastphrase {
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 600;
  padding-top: 24px;
}
.yearlybuttonsforincestment {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.yearlybuttonsforincestmentbutton {
  background-color: #f3f3f3;
  padding: 8px 16px;
  font-size: 14px;
  border: 0;
  border-radius: 40px;
  color: #3b4d80;
  cursor: pointer;
}
.phoneandmessage {
  display: flex;
  justify-content: space-between;
}
.yearlybuttonsforincestmentbutton.active {
  border: 1px solid white;
  background-color: #242f4e;
  color: white;
}
@media (max-width: 980px) {
  .pricingtopleftsection {
    width: 100%;
    gap: 48px;
  }
  .pricingtopfirstsection {
    flex-direction: column;
    gap: 48px;
    padding: 112px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pricingtopleftsectionh3 {
    display: flex;
    font-size: 32px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    max-width: 30ch;
    text-align: center;
  }
  .pricingtopleftsectionp {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    /* max-width: 40ch; */
  }
  .pricingpagetopbuttensectionare {
    display: none;
  }
  .pricingtopleftsectionbutton-mobile {
    display: block;
    background-color: #f6f6f6;
    padding: 10px 24px;
    font-size: 14px;
    border: 0;
    border-radius: 40px;
    color: #3b4d80;
    cursor: pointer;
    margin-top: 48px;
    width: 100%;
  }
  .yearlybuttonsforincestment {
    justify-content: center;
  }
  .pricingtoprightsection {
    width: 93%;
    padding: 20px;
  }
  .checkoutyoursavingssection {
    /* flex-direction: column; */
    gap: 16px;
    align-items: center;
  }
  .checkoutyoursaving {
    font-size: 20px;
    font-weight: 600;
  }
  .amoutyouwillinvest {
    justify-content: space-between;
    align-items: flex-start;
  }
  .checkoutyoursavingssectionbelowheading {
    flex-direction: column;
    margin-top: 24px;
    padding-bottom: 24px;
    width: 100%;
  }
  .ifyouinvestinfabits {
    padding-left: 0;
    width: 100%;
    padding-top: 16px;
  }
  .pricingslidersection {
    padding: 0 20px;
  }
  .ifyouinvestindirectmf {
    padding-right: 0;
    border-right: none;
    border-bottom: 1px dashed #6777a4;
    width: 100%;
    padding-bottom: 16px;
  }
  .thesavedamount {
    color: #fff;
    /* margin-left: 8px; */
    font-size: 20px;
  }
  .tooltiptext {
    left: 140%;
  }
  .tooltiptext::after {
    left: 20%;
  }
  .planssectionpricecomparations {
    display: none;
  }
  .cardssectionpricingpage {
    display: none;
  }
  .conparationsectionpricingpage {
    display: none;
  }
  .pricingpagebottomsection {
    display: none;
  }
  .Checkoutourplanspricingpage {
    font-size: 24px;
  }
  .planssectionpricingpage {
    padding: 48px 0;
  }
  .pricingtopleftsectionbutton1-mobile {
    display: flex;
    justify-content: center;
    background-color: #242f4e;
    border: 0;
    font-size: 16px;
    border-bottom: #f6f6f6 1px dashed;
    color: #f6f6f6;
    cursor: pointer;
  }
  .planssectionpricecomparations-mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    overflow-x: auto;
    padding: 24px 0 20px 20px;
    scroll-padding-left: 20px; /* Add this line */
    position: relative;
  }
  .cardssectionpricingpage-mobile {
    background-color: #fff;
    width: 100%;
    padding: 16px;
    border-radius: 24px;
    height: 560px;
  }
  .Starterdesktoppricingp {
    padding-bottom: 16px;
    border-bottom: 1px solid #d3dbec;
  }
  .featuressectionmobiletingwithticks {
    padding-top: 16px;
  }
  .featuresidepricingpage-mobile {
    font-size: 14px;
    color: #575757;
    white-space: nowrap;
    padding-bottom: 8px;
  }
  /* .Starterdesktoppricingbutton {
    margin-top: 90%;
  } */
  /* .firstbuttonofthestartersectionpricingpage {
    margin-top: 48px;
  } */
  /* .Growthchoosebuttonpricingpage {
    margin-top: 105px;
  } */
  /* .premiumpircingbuttonpricingpage {
    margin-top: 70%;
  } */
  /* .elitepremiumpricingmobilebutton {
    margin-top: 100%;
  } */
  .Elitedesktoppricing-mobile {
    margin-right: 20px;
  }
  .pricingpagebottomsection {
    display: flex;
    border-radius: 0;
  }
  .additionalinformationandtextbeside {
    flex-direction: column;
    gap: 24px;
  }
  .additionalinformationtablesection {
    width: 100%;
  }
  .Additioinalinfoheadingpricingpage {
    justify-content: flex-start;
  }
  .tabledataoftheadditionalsection {
    padding: 0 24px;
    display: flex;
    border: none;
  }
  .leftpartthing {
    padding-top: 12px;
  }
  .rightanswertothequestionthing {
    padding-bottom: 12px;
  }
}

@media (max-width: 1323px) and (min-width: 769px) {
  .pricingtopfirstsection {
    padding: 110px 60px 96px 60px;
  }
  .pricingtopleftsectionh3 {
    font-size: 42px;
  }
  .pricingpagetopbuttensectionare {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .pricingtopleftsectionbutton1 {
    margin-left: 0;
  }
  .planssectionpricingpage {
    padding: 72px 60px;
  }
}
@media (min-width: 1323px) and (max-width: 1369px) {
  .pricingtopleftsectionbutton1 {
    margin-left: 0;
  }
  .pricingpagetopbuttensectionare {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1028px) and (min-width: 769px) {
  .checkoutyoursavingmf {
    font-size: 16px;
  }
  .planssectionpricingpage {
    padding: 72px 0;
  }
  .pricingtopfirstsection {
    padding: 110px 20px 96px 20px;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  background-color: #41558d;
  padding-top: 60px;
}
#wish {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #f6f6f6;
  font-size: 56px;
  transform: none;
  margin-top: 92px;
}
#belowwish {
  display: flex;
  position: relative;
  color: #f6f6f6;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
#group39 {
  top: 80px;
  right: 0;
  position: absolute;
}
#frame1 {
  position: absolute;
  top: 250px;
  z-index: 1;
}
#righttopmobile {
  display: none;
}
.buttons {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 96px;
  margin-bottom: 96px;
}
.contact h3 {
  display: flex;
  color: #f6f6f6;
  font-size: 20px;
  font-weight: 200;
}
#arrowlink {
  padding-left: 10px;
}
#chat {
  margin-bottom: 14px;
}
.contact p {
  display: flex;
  color: #c4cadc;
  font-size: 16px;
}
#callicon {
  margin-bottom: 14px;
}
#shedule {
  margin-bottom: 14px;
}
.contact a {
  display: flex;
  text-decoration: none;
}
#chatwithus {
  margin-right: 48px;
  border-right: 1px solid #6777a4;
}
#arrowlink {
  display: flex;
}

#sec {
  padding-right: 48px;
}
#mins {
  padding-right: 48px;
}
#call {
  margin-right: 48px;
  border-right: 1px solid #6777a4;
}
#rightbottem {
  display: none;
}
.contactus {
  min-height: 696px;
  background-color: #eceef4;
}
.contactus h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b243b;
  font-size: 32px;
  padding-top: 48px;
}
#leftmiddle {
  display: none;
}
::placeholder {
  /* Most modern browsers support this */
  color: #808db3;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #808db3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #808db3;
}
input:focus,
textarea:focus {
  outline: none;
}
select:focus textarea:focus {
  outline: none;
}
.contactus p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2d2d2d;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 48px;
}

.contactusform {
  display: flex;
  justify-content: center;
  align-items: center;
}
#contactusforminner {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 32px;
  padding: 32px;
  box-sizing: border-box;
  margin-bottom: 48px;
  z-index: 2;
}

#message {
  min-width: 100%;
  max-height: 112px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 12px;
  background-color: #edf0f7;
  resize: none;
  margin-top: 6px;
}
.form1and2 {
  gap: 24px;
  display: flex;
  margin-bottom: 16px;
}
.form3 {
  gap: 24px;
  display: flex;
  margin-bottom: 16px;
}
#namelabel {
  color: #6e6e6e;
  font-size: 14px;
  margin-bottom: 8px;
}
#name {
  border-radius: 34px;
  width: 328px;
  padding: 8px 16px;
  border: #f6f6f6 2px solid;
}
#emaillabel {
  color: #6e6e6e;
  font-size: 14px;
}
#querylabel {
  color: #6e6e6e;
  font-size: 14px;
  padding-bottom: 8px;
}
#describelabel {
  color: #6e6e6e;
  font-size: 14px;
  padding-bottom: 8px;
}
#email {
  margin-top: 6px;
  border-radius: 34px;
  width: 328px;
  padding: 8px 16px;
  border: #f6f6f6 2px solid;
}
#query {
  margin-top: 6px;
  border-radius: 34px;
  width: 368px;
  padding: 8px 16px;
  border: #f6f6f6 2px solid;
  background-color: #edf0f7;
}
#submit {
  display: flex;
  margin: 0 0 0 auto;
  padding: 10px 24px;
  border-radius: 28px;
  background-color: #41558d;
  color: #fff;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}
#submit.submitted {
  background-color: rgb(105, 160, 105);
  color: #ffffff;
}
#vector {
  position: absolute;
  right: 13px;
  top: 750px;
  z-index: 1;
}
#vectorleft {
  position: absolute;
  left: 62px;
  top: 1050px;
}
@media (min-width: 451px) and (max-width: 800px) {
  #wish {
    text-align: center;
  }
  #belowwish {
    text-align: center;
  }
  #name {
    width: 90%;
  }
  #email {
    width: 90%;
  }
  #submit {
    width: 100%;
    margin: 0;
    justify-content: center;
  }
  #message {
    width: 100%;
    background-color: #fff;
  }
  #query {
    background-color: #fff;
  }
  .contactus h3 {
    font-size: 24px;
    display: block;
    padding: 48px 0 8px 20px;
  }
  .contactus p {
    font-size: 14px;
    display: block;
    padding: 0 0 20px 20px;
    margin: 0;
  }
}
@media (max-width: 450px) {
  #group39 {
    display: none;
  }
  #frame1 {
    display: none;
  }
  #wish {
    z-index: 2;
    font-size: 36px;
    margin-top: 56px;
    margin: 56px 6% 8px 6%;
    text-align: center;
  }
  #belowwish {
    font-size: 14px;
    margin: 8px 20px 8px 20px;
    text-align: center;
  }
  .buttons {
    flex-direction: column;
    margin-top: 48px;
    margin-bottom: 48px;
    align-items: flex-start;
    margin-left: 20px;
  }
  #arrowlink {
    padding-left: 0;
    position: absolute;
    right: 20px;
  }
  #chat {
    margin-bottom: 0;
    margin-right: 16px;
  }
  #callicon {
    margin-bottom: 0;
    margin-right: 16px;
  }
  #shedule {
    margin-bottom: 0;
    margin-right: 16px;
  }
  #chatwithus {
    margin-bottom: 48px;
    margin-right: 0;
    border-right: none;
  }
  #call {
    margin-right: 0;
    border-right: none;
  }
  #chatwithus {
    display: flex;
  }
  #call {
    display: flex;
    margin-bottom: 48px;
  }
  #calldiv {
    display: flex;
    flex-direction: column;
  }
  #chatdiv {
    display: flex;
    flex-direction: column;
  }
  #sheduledivin {
    display: flex;
    flex-direction: column;
  }
  #leftmiddle {
    display: block;
    position: absolute;
    top: 170px;
  }
  #shedulediv {
    display: flex;
  }
  #righttopmobile {
    z-index: 1;
    display: block;
    position: absolute;
    right: 0;
  }
  #rightbottem {
    display: block;
    position: absolute;
    right: 0;
    top: 375px;
  }
  .form1and2 {
    flex-direction: column;
  }
  .form3 {
    flex-direction: column;
  }
  form {
    width: 90%;
  }
  #vector {
    display: none;
  }
  #vectorleft {
    display: none;
  }
  #name {
    width: 90%;
  }
  #email {
    width: 90%;
  }
  #submit {
    width: 100%;
    margin: 0;
    justify-content: center;
  }
  #message {
    width: 100%;
    background-color: #fff;
  }
  #query {
    width: 100%;
    background-color: #fff;
  }
  .contactus h3 {
    font-size: 24px;
    display: block;
    padding: 48px 0 8px 20px;
  }
  .contactus p {
    font-size: 14px;
    display: block;
    padding: 0 0 20px 20px;
    margin: 0;
  }
}
.financial-planner {
  padding: 20px;
}

.results-section {
  margin-top: 20px;
}

.results-section h2 {
  text-align: center;
  margin: 20px 0;
}

.slider-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.investment-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%; /* Adjusted to 100% */
  height: 4px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
}

/* For the filled part of the slider */
.investment-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-radius: 5px;
}

.investment-slider::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-radius: 5px;
  background: #d3d3d3;
}

.investment-slider::-moz-range-progress {
  background: #4a90e2;
  border-radius: 5px;
}

.investment-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -8px; /* New line, adjust the thumb to slide in the center of the track */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

.investment-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

/* Hover effect */
.investment-slider::-webkit-slider-thumb:hover,
.investment-slider::-moz-range-thumb:hover {
  background: #1e5ba2;
}

/* Bar Chart styling */
#bar-chart {
  position: absolute;
  top: 0px;
  border: 5px solid #f3f3f3;
  border-radius: 24px;
  overflow: hidden; /* To ensure the rounded corners contain the chart */
  padding: 10px; /* Optional: if you need space between the chart and the border */
  left: 120%;
}

#bar-chart .recharts-bar-rectangle {
  stroke: none; /* Removing the stroke (border) from the bars */
}

#Heding {
  text-align: center;
  margin: 72px 0 48px 0;
}

#before {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#submit {
  display: block;
  margin: auto;
  margin-top: 20px;
  padding: 10px 16px;
  background-color: #41558d;
  border-radius: 40px;
  border: 0;
  color: white;
  font-size: 16px;
}
.topsection {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 756px;
  background-color: #242f4e;
  color: #242f4e;
  position: relative;
}
/* .mainpage {
  padding-top: 60px;
} */
#Vector33,
#Vector69,
#Vector70,
#Group,
#First-line-Mobile,
#Second-line-Mobile,
#Third-line-Mobile {
  display: none;
}

#topsvg {
  z-index: 1;
  margin: 130px 0 227px 0;
}
#rightsvg {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 100px;
}
#leftbottem {
  position: absolute;
  z-index: 1;
  left: 0px;
  bottom: 50px;
}

#First-line,
#Second-line {
  position: absolute;
  z-index: 2;
  letter-spacing: 0.05em;
  transition: all 0.5s;
  font-weight: 600;
  font-size: 36px;
  position: absolute;
  z-index: 2;
}

#First-line {
  top: 21%;
}
#First-line:hover {
  transform: scale(1.05);
}
#Second-line:hover {
  transform: scale(1.05);
}

#Second-line {
  top: 40%;
}

#Third-line {
  position: absolute;
  z-index: 2;
  letter-spacing: 0.05em;
  transition: all 0.5s;
  font-weight: 600;
  font-size: 48px;
  font-weight: 600;
  position: absolute;
  top: 59%;
  z-index: 2;
  transition: all 0.5s;
}
#Third-line:hover {
  transform: scale(1.05);
}

#cash-mobile {
  position: absolute;
  top: 1830px;
  z-index: 2;
  left: 78%;
}

#cash-desktop {
  position: absolute;
  top: 1078px;
  z-index: 2;
  left: 38%;
}
#Third-line:hover,
#Bulbe:hover,
#Plus1:hover {
  transform: scale(1.05);
}

#Bulbe,
#Plus1 {
  position: absolute;
  z-index: 1;
  transition: all 0.3s;
}

#Bulbe {
  left: 240px;
  top: 250px;
}

#Plus1 {
  left: 1370px;
  top: 600px;
}

#Circle-Img,
#Circle-Img1 {
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
}

#Circle-Img {
  left: 288px;
  top: 282px;
}

#Circle-Img1 {
  left: 1420px;
  top: 180px;
}

#rightsvg {
  right: 0px;
  top: 100px;
}

#Tick {
  position: absolute;
  z-index: 2;
  left: 73%;
  top: 180px;
}

#leftbottem {
  bottom: 50px;
}

#Know-More {
  position: absolute;
  background-color: #7499d6;
  color: white;
  z-index: 2;
  padding: 16px 30px;
  border-radius: 40px;
  border: 0px;
  bottom: 120px;
  font-size: 16px;
}

#Know-More:hover {
  background-color: #5c7fae;
  cursor: pointer;
}

.Card-Section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 519px;
  background-color: #7499d6;
  color: #2d3d6b;
}

#puzle,
#Coin,
#green-background,
#experienced-businessman,
#Get-Experts,
#Get-Experts-para,
#Get-Started,
#botten-some-design,
#Frame,
#key {
  position: absolute;
  z-index: 2;
}

#Get-Experts {
  color: #ffffff;
}

#Get-Experts-para {
  color: #ffffff;
}

#puzle {
  left: 56px;
  top: 1408px;
}

#Beside-Card {
  color: white;
  font-size: 24px;
  margin: 0 330px;
  z-index: 2;
}

#Coin {
  top: 1408px;
  left: 1430px;
}

#green-background {
  right: 0;
  top: 1288px;
  padding-bottom: 100px;
  z-index: 1;
}

pre {
  font-style: italic;
  color: #2d3d6b;
  background: #fff;
  padding: 50px 70px;
  border-radius: 40px;
  margin-left: 90px;
  transform-style: preserve-3d;
  transform: perspective(5000px) rotateY(var(--rotateX)) rotateX(var(--rotateY));
}

pre::before,
pre::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

pre::before {
  inset: 0.75rem;
  background: black;
  transform: translateZ(-50px);
  filter: blur(30px);
  opacity: 0.7;
}
#Card {
  min-width: 800px;
}
.consumers-have {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#indian-consumers {
  color: #1b243b;
  padding: 72px 0;
  display: flex;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  max-width: 45ch;
}
#group94 {
  position: absolute;
  top: 63px;
}
#group93 {
  position: absolute;
  left: 56.5%;
}
#Card-title,
#Latin,
#noun,
#Def {
  font-size: 48px;
  font-weight: 550;
  letter-spacing: 0.05em;
}

#Latin {
  font-size: 24px;
}

#noun {
  font-size: 28px;
  padding: 30px 0;
}

#Def {
  font-size: 24px;
  margin: 0;
}

.language-css {
  white-space: normal;
}

#Def p {
  display: flex;
  font-size: 22px;
}

#experienced-businessman {
  top: 1588px;
  right: 260px;
}

#Vector71 {
  display: none;
}

#Get-Experts {
  font-size: 32px;
  top: 1728px;
  left: 30%;
}

#Get-Experts-para {
  font-size: 16px;
  top: 1780px;
  left: 30%;
  margin-right: 690px;
}

#Get-Started {
  background-color: #fff;
  color: #1b243b;
  border-radius: 40px;
  font-size: 16px;
  left: 30%;
  top: 1910px;
  padding: 14px 28px;
  border: 0px;
}

#Get-Started:hover {
  background-color: #f2f8f1;
}

#botten-some-design {
  left: 50%;
  top: 1904px;
}

#Frame {
  left: 59%;
  top: 1898px;
}

#key {
  left: 49%;
  top: 1948px;
}

#Vector1 {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 2058px;
  /* height: 100%; */
  width: 1380px;
}

#Rectangle33 {
  display: none;
}

#Frame1 {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 2058px;
  z-index: 1;
}

#businessman-area {
  min-height: 570px;
}

#design1 {
  height: 700px;
  background-color: #f2f8f1;
  z-index: 1;
}

#Design1-para {
  display: flex;
  text-align: center;
  font-size: 35px;
  color: #242f4e;
  z-index: 2;
  font-weight: 600;
  position: absolute;
  max-width: 22ch;
  top: 2430px;
  left: 38%;
}
#Design1-para1 {
  display: flex;
  text-align: center;
  font-size: 20px;
  color: #f6f6f6;
  z-index: 2;
  font-weight: 500;
  position: absolute;
  top: 2530px;
  left: 36.5%;
}
#Plan-a-goal {
  position: absolute;
  left: 47%;
  top: 2658px;
  z-index: 2;
  background-color: #7499d6;
  color: white;
  z-index: 2;
  padding: 16px 30px;
  border-radius: 40px;
  border: 0px;
  font-size: 16px;
  transition: all 0.3s;
}
#Plan-a-goal:hover {
  background-color: #5c7fae;
  cursor: pointer;
  /* transform: scale(1.3); */
}

#green-dupe {
  width: 8%;
  position: absolute;
  top: 1628px;
}

#Fream-32 {
  position: absolute;
  top: 2778px;
  right: 40px;
}

#dollar {
  position: absolute;
  top: 2078px;
  z-index: 2;
  left: 310px;
}

#plan {
  position: absolute;
  top: 2478px;
  z-index: 2;
  left: 120px;
}

#pencil {
  position: absolute;
  top: 2308px;
  right: 65px;
  z-index: 2;
}

#search {
  position: absolute;
  top: 2728px;
  right: 290px;
}

#coin1 {
  position: absolute;
  top: 2878px;
  left: 95%;
}

#arrow {
  position: absolute;
  z-index: 2;
  top: 2578px;
  left: 54%;
}

.dontlet {
  height: 460px;
}

.dontlet h3 {
  color: #2d2d2d;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  text-align: center;
  letter-spacing: 2px;
  margin: 0 18% 0;
}

#insurances-button {
  position: absolute;
  z-index: 2;
  background-color: #7499d6;
  color: white;
  z-index: 2;
  padding: 16px 30px;
  border-radius: 40px;
  border: 0px;
  font-size: 16px;
  transition: all 0.3s;
  top: 3175px;
  left: 47%;
}

#insurances-button:hover {
  background-color: #5c7fae;
  cursor: pointer;
  /* transform: scale(1.3); */
}

#Counselling-section {
  background-color: #f4f7fe;
  height: 685px;
}

#Counselling-section h3 {
  position: absolute;
  color: #2d2d2d;
  font-size: 32px;
  top: 3528px;
  left: 400px;
  max-width: 19ch;
  font-weight: 500;
}

#Counselling-section-button {
  position: absolute;
  z-index: 2;
  background-color: #7499d6;
  color: white;
  z-index: 2;
  padding: 16px 30px;

  border-radius: 40px;
  border: 0px;
  font-size: 16px;
  transition: all 0.3s;
  left: 400px;
  top: 3678px;
}

#Counselling-section-button:hover {
  background-color: #5c7fae;
  cursor: pointer;
  /* transform: scale(1.3); */
}

#group81 {
  position: absolute;
  right: 150px;
  top: 3378px;
  z-index: 1;
}

#greenpart {
  background-color: #82b770;
  height: 536px;
}

#greenpart h3 {
  color: #f6f6f6;
  font-size: 32px;
  padding: 63px 0 50px 250px;
}

#Group57 {
  padding: 0 0 0 250px;
}

#Group59 {
  position: absolute;
  right: 0%;
}

#Frame59 {
  position: absolute;
  right: 100px;
  top: 4188px;
}

#Group82 {
  position: absolute;
  left: 0px;
  top: 4208px;
}

#Rectangle19 {
  position: absolute;
  left: 53%;
  width: 33%;
  border-radius: 32px;
}
.Rectangle19mainpage {
  top: 4048px;
}
#Supported-banks {
  width: 100%;
}

@media (max-width: 600px) {
  .topsection {
    min-height: 604px;
  }
  h2 {
    font-size: 20px;
  }
  #topsvg {
    display: none;
  }
  #First-line {
    font-size: 20px;
    top: 2%;
  }
  #Second-line {
    font-size: 20px;
  }
  #Third-line {
    font-size: 16px;
  }
  #Bulbe {
    position: absolute;
    z-index: 1;
    left: 5%;
    top: 50px;
    width: 17.5%;
  }
  #Circle-Img {
    display: none;
  }
  #rightsvg {
    display: none;
  }
  #Tick {
    position: absolute;
    z-index: 2;
    right: 18%;
    top: 37%;
    width: 45px;
  }
  #Circle-Img1 {
    display: none;
  }
  #Plus1 {
    position: absolute;
    z-index: 2;
    left: 10%;
    top: 80%;
    width: 15%;
  }
  #group94 {
    top: 20px;
    left: 3%;
    width: 53px;
  }
  #indian-consumers {
    font-size: 20px;
    padding: 24px 20px;
  }
  #leftbottem {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 25%;
  }
  #Vector33 {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
  }
  #Group {
    display: block;
  }
  #Know-More {
    display: block;
    padding: 10px 24px;
    font-size: 14px;
    bottom: 90px;
  }
  #First-line {
    display: none;
  }
  #Second-line {
    display: none;
  }
  #Third-line {
    font-size: 14px;
    position: absolute;
    top: 70%;
    color: #ffffff;
    padding: 0 80px;
  }
  #First-line-Mobile {
    position: absolute;
    display: block;
    font-size: 16px;
    top: 31%;
    left: 20vw;
  }

  .Card-Section {
    height: 700px;
  }

  #Second-line-Mobile {
    position: absolute;
    display: block;
    font-size: 16px;
    top: 45.5%;
  }

  #Third-line-Mobile {
    display: block;
    font-size: 20px;
    position: absolute;
    top: 59.5%;
  }
  pre {
    padding: 0;
    transform-style: none;
    transform: none;
    padding: 59px 29px;
    border-radius: 32px;
    z-index: 2;
    width: 75%;
    margin: 3%;
    position: absolute;
    top: 858px;
  }
  pre::before,
  pre::after {
    display: none;
  }
  #group93 {
    display: none;
  }
  #Card-title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  #Latin {
    font-size: 20px;
  }

  #noun {
    font-size: 28px;
    padding: 30px 0;
  }
  #Coin {
    left: 80%;
    z-index: 2;
  }
  #Def p {
    font-size: 14px;
    margin: 0 15px 0 0;
  }
  #Beside-Card {
    display: flex;
    position: absolute;
    font-size: 14px;
    z-index: 2;
    color: #ffffff;
    margin: 0 10% 0 10%;
    left: 0;
    top: 1298px;
  }

  #puzle {
    top: 1218px;
    left: 5%;
    width: 15%;
  }
  #green-background {
    display: none;
  }
  #Rectangle19 {
    display: flex;
    position: absolute;
    left: 10%;
    width: 80%;
    top: 4318px;
    border-radius: 20px;
  }
  #Vector69 {
    position: absolute;
    top: 1430px;
    display: block;
    z-index: 1;
    left: 10%;
    margin: auto;
  }
  #Third-line {
    display: none;
  }
  #experienced-businessman {
    display: none;
  }
  #green-dupe {
    display: none;
  }
  #Get-Experts {
    font-size: 24px;
    left: 0px;
    margin: 0 20% 0 15%;
    top: 1608px;
  }
  #cash-mobile {
    top: 1958px;
    z-index: 2;
    left: 60%;
    width: 66px;
  }
  #cash-desktop {
    position: absolute;
    top: 966px;
    z-index: 2;
    left: 78%;
    width: 20%;
  }
  #Coin {
    width: 13%;
    top: 1548px;
    left: 9%;
  }
  #key {
    width: 13%;
    top: 1410px;
    left: 60%;
  }
  #Frame {
    width: 20%;
    left: 65%;
    top: 1830px;
  }
  #Get-Experts-para {
    font-size: 14px;
    top: 1690px;
    left: 15%;
    margin: 0 125px 0 0;
  }
  #Get-Started {
    left: 15%;
    top: 1845px;
    padding: 10px 20px;
    font-size: 14px;
  }
  #botten-some-design {
    display: none;
  }
  #Frame1 {
    display: none;
  }
  #Vector1 {
    display: none;
  }
  #Fream-32 {
    display: none;
  }
  #Vector70 {
    display: block;
    position: absolute;
    top: 1960px;
    right: 8%;
  }
  #Plan-a-goal {
    top: 2580px;
    padding: 14px 20px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #search {
    display: none;
  }
  #insurances-button {
    position: relative;
    top: -120px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #pencil {
    top: 2050px;
    right: 6%;
    width: 18%;
    width: 12%;
  }
  #dollar {
    top: 2240px;
    left: 12%;
    width: 13%;
  }
  #Design1-para {
    position: relative;
    font-size: 20px;
    font-weight: 300;
    left: 0;
    margin: auto 50px;
    top: 60px;
    color: #d0f19a;
    max-width: 25ch;
  }
  #Design1-para1 {
    font-size: 14px;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 30ch;
    position: relative;
    text-align: center;
    left: 0;
    top: 80px;
  }
  #Rectangle33 {
    display: flex;
    position: relative;
    top: 240px;

    margin: auto;
    width: 80%;
  }
  #arrow {
    top: 2485px;
    right: 28%;
    width: 15%;
  }
  #Vector71 {
    display: block;
    position: absolute;
    top: 2650px;
    left: 8%;
  }
  #coin1 {
    width: 8%;
    top: 2630px;
    left: 6%;
  }
  #plan {
    top: 2720px;
    left: 28%;
    width: 15%;
  }
  .dontlet h3 {
    font-size: 20px;
    max-width: 24ch;
    margin: auto;
    font-weight: 700;
  }
  #group81 {
    top: 10px;
    width: 80%;
    left: 10%;
    position: relative;
  }
  #Counselling-section h3 {
    position: relative;
    font-size: 24px;
    top: 100px;
    left: 0;
    text-align: center;
    margin: auto;
  }
  #Counselling-section-button {
    position: relative;
    transform: translate(-50%, -50%);
    top: 160px;
    left: 50%;
    padding: 14px 20px;
  }
  #greenpart h3 {
    font-size: 24px;
    padding: 63px 0 50px 10%;
  }
  #Group57 {
    padding: 0 0 0 10%;
    width: 85%;
  }
  #Group59 {
    display: none;
  }
  #Frame59 {
    display: none;
  }
  #Group82 {
    display: none;
  }
  #greenpart {
    height: 727px;
  }
}

/* Tab Device */

@media (min-width: 601px) and (max-width: 1149px) {
  .topsection {
    min-height: 0px;
  }
  #topsvg {
    width: 550px;
  }
  pre {
    transform-style: none;
    transform: none;
  }
  #Card {
    min-width: 0;
  }

  #Circle-Img1 {
    display: block;
    left: 80%;
    top: 350px;
    width: 7%;
  }
  #Plus1 {
    left: 83%;
    top: 370px;
    width: 7%;
  }
  #Tick {
    left: 70.5%;
    width: 90px;
    top: 230px;
  }
  #Circle-Img {
    left: 6%;
    top: 180px;
    width: 8%;
  }
  #Bulbe {
    left: 2%;
    top: 150px;
    width: 8%;
  }
  #group94 {
    width: 80px;
  }
  #cash-desktop {
    width: 80px;
    top: 1000px;
  }
  #indian-consumers {
    font-size: 26px;
  }
  #rightsvg {
    width: 10%;
  }
  #leftbottem {
    width: 10%;
  }
  #Vector33 {
    display: none;
  }
  #First-line {
    font-size: 22px;
    top: 25.5%;
  }
  #Second-line {
    font-size: 22px;
    top: 39%;
  }
  #Tick {
    display: none;
  }
  #Third-line {
    font-size: 30px;
    padding: 0 80px;
    top: 52%;
  }
  #Know-More {
    padding: 12px 16px;
    bottom: 130px;
    font-size: 14px;
    left: 43%;
  }
  #Coin {
    left: 85%;
    top: 1250px;
    z-index: 3;
    width: 7%;
  }
  #Beside-Card {
    font-size: 16px;
    margin: 0;
    position: absolute;
    left: 70%;
  }
  pre {
    margin-left: 0px;
    max-width: 360px;
    padding: 30px;
    margin-left: 5%;
  }
  #Card-title {
    font-size: 34px;
    font-weight: 500;
  }
  #Latin {
    font-size: 18px;
  }
  #Def p {
    font-size: 14px;
  }

  #puzle {
    left: 56px;
    top: 1240px;
    width: 7%;
  }
  #green-background {
    width: 95%;
    height: 400px;
  }
  #green-dupe {
    display: none;
  }
  #Get-Experts {
    left: 15%;
    top: 1510px;
    margin: 0 250px 0 0;
    font-size: 20px;
  }
  #Get-Experts-para {
    top: 1540px;
    left: 15%;
    margin: 0 280px 0 0;
    font-size: 14px;
    max-width: 40ch;
  }
  #Get-Started {
    left: 15%;
    top: 1635px;
    padding: 10px 20px;
    font-size: 14px;
  }
  #botten-some-design {
    top: 1608px;
    left: 40%;
    width: 135px;
    z-index: 2;
  }
  #Frame {
    top: 1620px;
    left: 55%;
    width: 5%;
  }
  #key {
    top: 1650px;
    left: 40%;
    width: 4%;
  }
  #experienced-businessman {
    width: 210px;
    top: 1427px;
    z-index: 3;
    left: 60%;
  }
  #businessman-area {
    min-height: 400px;
  }
  #design1 {
    height: 330px;
  }
  #dollar {
    top: 1710px;
    left: 100px;
    width: 5%;
  }
  #cash-mobile {
    width: 7%;
    top: 1710px;
  }
  #plan {
    top: 1930px;
    left: 70px;
    width: 8%;
  }

  #pencil {
    top: 1840px;
    right: 20px;
    width: 5%;
  }
  #Vector1 {
    display: block;
    position: absolute;
    top: 1720px;
    width: 78vw;
    height: 250px;
  }
  #Frame1 {
    display: block;
    position: absolute;
    top: 1720px;
    right: 0;
    width: 20%;
  }
  #Fream-32 {
    top: 2050px;
    right: 40px;
    width: 200px;
  }
  #Design1-para {
    margin: auto;
    position: relative;
    font-size: 20px;
    top: 122px;
    left: 0;
    max-width: 22ch;
    font-weight: 500;
  }
  #Design1-para1 {
    display: none;
  }
  #Plan-a-goal {
    margin: auto;
    top: 2000px;
    left: 42%;
    padding: 12px 16px;
  }
  #arrow {
    top: 1950px;
    left: 54%;
    width: 60px;
  }
  #coin1 {
    top: 2120px;
    left: 92%;
    width: 4%;
  }
  #search {
    top: 2030px;
    right: 200px;
    width: 6%;
  }
  .dontlet {
    height: 400px;
  }
  .dontlet h3 {
    font-size: 30px;
    margin: 0 3% 0;
  }
  #insurances-button {
    top: -100px;
    left: 38%;
    position: relative;
  }
  #group81 {
    top: 2520px;
    right: 50px;
    width: 35%;
  }
  #Counselling-section {
    height: 300px;
  }
  #Counselling-section h3 {
    top: 2550px;
    left: 60px;
    margin: 0 50% 0 0;
    z-index: 2;
    font-size: 25px;
  }
  #Counselling-section-button {
    top: 2670px;
    left: 60px;
    padding: 12px 16px;
    font-size: 14px;
  }
  #Group57 {
    padding: 0 0 0 100px;
  }
  #greenpart h3 {
    padding: 63px 0 50px 100px;
    font-size: 24px;
  }
  #Frame59 {
    top: 2850px;
    right: 100px;
  }
  #Group82 {
    top: 3600px;
  }
  #Rectangle19 {
    top: 2921px;
    left: 53%;
    width: 40%;
  }
  #Group57 {
    width: 40%;
  }
  #Group82 {
    width: 10%;
    top: 2980px;
  }
}

@media (min-width: 860px) and (max-width: 1150px) {
  .topsection {
    min-height: 0px;
  }
  #topsvg {
    width: 750px;
  }
  pre {
    transform-style: none;
    transform: none;
  }
  #Circle-Img1 {
    display: block;
    left: 80%;
    top: 420px;
    width: 8%;
  }
  #Plus1 {
    left: 84%;
    top: 450px;
    width: 8%;
  }
  #Tick {
    left: 65.5%;
    width: 90px;
    top: 230px;
  }
  #Circle-Img {
    left: 6%;
    top: 280px;
    width: 8%;
  }
  #Bulbe {
    left: 2%;
    top: 250px;
    width: 8%;
  }
  #rightsvg {
    width: 12%;
  }
  #leftbottem {
    width: 12%;
  }
  #Vector33 {
    display: none;
  }
  #First-line {
    font-size: 32px;
    top: 22.5%;
  }
  #Second-line {
    font-size: 32px;
    top: 39%;
  }
  #Third-line {
    font-size: 38px;
    padding: 0 80px;
    top: 56%;
  }
  #Know-More {
    padding: 14px 20px;
    bottom: 110px;
  }
  #Coin {
    left: 80%;
    top: 1250px;
    z-index: 3;
  }
  #Beside-Card {
    font-size: 22px;
    margin: 0;
    position: absolute;
    left: 580px;
  }
  pre {
    margin-left: 0px;
    max-width: 430px;
    padding: 30px;
    margin-left: 5%;
  }
  #Card-title {
    font-size: 40px;
    font-weight: 500;
  }
  #Latin {
    font-size: 20px;
  }
  #Def p {
    font-size: 16px;
  }

  #puzle {
    left: 56px;
    top: 1320px;
    width: 5%;
  }
  #green-background {
    width: 95%;
    height: 550px;
  }
  #green-dupe {
    display: none;
  }
  #Get-Experts {
    left: 15%;
    top: 1620px;
    margin: 0 400px 0 0;
    font-size: 24px;
  }
  #Get-Experts-para {
    top: 1670px;
    left: 15%;
    margin: 0 500px 0 0;
    font-size: 14px;
  }
  #Get-Started {
    left: 15%;
    top: 1770px;
    padding: 10px 20px;
    font-size: 14px;
  }
  #botten-some-design {
    top: 1750px;
    left: 40%;
    width: 135px;
    z-index: 2;
  }
  #Frame {
    top: 1770px;
    left: 50%;
    width: 5%;
  }
  #key {
    top: 1790px;
    left: 40%;
    width: 4%;
  }
  #experienced-businessman {
    width: 250px;
    top: 1525px;
    z-index: 3;
    left: 60%;
  }
  #businessman-area {
    min-height: 500px;
  }
  #design1 {
    height: 430px;
  }
  #dollar {
    top: 1850px;
    left: 155px;
    width: 5%;
  }
  #plan {
    top: 2050px;
    left: 70px;
    width: 8%;
  }
  #pencil {
    top: 2070px;
    right: 50px;
    width: 5%;
  }
  #cash-mobile {
    top: 1900px;
  }
  #Vector1 {
    display: block;
    position: absolute;
    top: 1870px;
    width: 77vw;
    height: 370px;
  }
  #Frame1 {
    display: block;
    position: absolute;
    top: 1900px;
    right: 0;
    width: 20%;
  }
  #Fream-32 {
    top: 2100px;
    right: 40px;
    width: 250px;
  }
  #Design1-para {
    position: relative;
    margin: auto;
    top: 170px;
    font-size: 24px;
  }
  #Design1-para1 {
    display: none;
  }
  #Plan-a-goal {
    margin: auto;
    top: 2250px;
    left: 44.5%;
    padding: 14px 20px;
  }
  #arrow {
    top: 2200px;
    left: 54%;
    width: 60px;
  }
  #coin1 {
    top: 2320px;
    left: 92%;
    width: 4%;
  }
  #search {
    top: 2100px;
    right: 260px;
    width: 6%;
  }
  .dontlet h3 {
    font-size: 40px;
    margin: 0 3% 0;
  }
  #Fream-32 {
    top: 2340px;
  }
  #insurances-button {
    position: relative;

    top: -100px;
    left: 42%;
    padding: 14px 20px;
  }
  #group81 {
    top: 2850px;
    right: 50px;
    width: 45%;
  }
  #Counselling-section {
    height: 600px;
  }
  #Counselling-section h3 {
    top: 3000px;
    left: 100px;
    margin: 0 50% 0 0;
    z-index: 2;
  }
  #Counselling-section-button {
    top: 3140px;
    left: 100px;
    padding: 14px 20px;
  }
  #Group57 {
    padding: 0 0 0 100px;
  }
  #greenpart h3 {
    padding: 63px 0 50px 100px;
  }
  #Frame59 {
    top: 3400px;
    right: 100px;
  }
  #Group82 {
    top: 3700px;
  }
  #Rectangle19 {
    top: 3500px;
    left: 55%;
    width: 40%;
  }
  #Group57 {
    width: 40%;
  }
  #Group82 {
    width: 10%;
    top: 3550px;
  }
}

/* Desktop Device */
@media (max-width: 1540px) and (min-width: 1149px) {
  #Tick {
    display: block;
    left: 1070px;
  }
  #Plus1 {
    left: 84%;
  }
  #Circle-Img {
    left: 6%;
  }
  #Circle-Img1 {
    left: 80%;
  }
  #Coin {
    left: 90%;
  }
  #Rectangle19 {
    display: block;
    top: 3750px;
    left: 50%;
    width: 30%;
  }
  #Design1-para {
    position: relative;
    top: 100px;
    margin: auto;
    left: 0;
  }
  #Bulbe {
    left: 2%;
  }
  #Beside-Card {
    z-index: 2;
    margin: 0 3% 0 0;
    font-size: 22px;
    left: 750px;
    position: absolute;
  }
  pre {
    max-width: 450px;
  }
  #Def {
    font-size: 20px;
  }
  #Card-title {
    font-size: 40px;
  }
  #Latin {
    font-size: 24px;
  }

  #green-dupe {
    display: none;
  }
  #green-background {
    display: block;
    width: 90%;
    height: 650px;
    padding-bottom: 0;
  }
  #Get-Experts {
    display: block;
    left: 240px;
    top: 1650px;
    margin: 0 500px 0 0;
  }
  #Get-Experts-para {
    top: 1700px;
    left: 240px;
    margin: 0 650px 0 0;
  }
  #Get-Started {
    left: 240px;
    top: 1820px;
  }
  #botten-some-design {
    top: 1820px;
    left: 500px;
  }
  #key {
    top: 1880px;
    left: 500px;
  }
  #Frame {
    top: 1800px;
    left: 650px;
  }
  #experienced-businessman {
    width: 282px;
    left: 60%;
  }
  #Vector1 {
    display: block;
    position: absolute;
    top: 1970px;
    width: 73vw;
    left: 0;
    height: 370px;
  }
  #Design1-para1 {
    display: none;
  }
  #Frame1 {
    display: block;
    position: absolute;
    top: 1970px;
    right: 0;
  }
  #businessman-area {
    min-height: 470px;
  }
  #design1 {
    height: 450px;
  }
  #dollar {
    top: 2000px;
    left: 190px;
    width: 5%;
  }
  #plan {
    top: 2230px;
    left: 70px;
    width: 8%;
  }

  #pencil {
    top: 2010px;
    right: 65px;
    width: 5%;
  }
  #search {
    top: 2400px;
    right: 260px;
    width: 6%;
  }
  #coin1 {
    top: 2500px;
    left: 92%;
    width: 3%;
  }
  #arrow {
    top: 2320px;
    left: 54%;
    width: 5%;
  }
  #Fream-32 {
    top: 2430px;
    right: 40px;
    width: 250px;
  }
  #Design1-para {
    padding: 0 28% 0;
    position: relative;
    top: 160px;
  }
  #Plan-a-goal {
    position: relative;
    margin: auto;
    top: 240px;
  }
  .dontlet h3 {
    margin: 0 1% 0;
  }
  #insurances-button {
    top: 2800px;
    left: 44%;
  }
  #group81 {
    top: 3000px;
  }
  #Counselling-section h3 {
    top: 3100px;
    left: 100px;
    margin: 0 56% 0 0;
    z-index: 2;
  }
  #Counselling-section-button {
    top: 3250px;
    left: 100px;
  }
  #Group57 {
    padding: 0 0 0 100px;
  }
  #greenpart h3 {
    padding: 63px 0 50px 100px;
  }
  #Frame59 {
    top: 3600px;
    right: 100px;
  }
  #Group82 {
    top: 3700px;
  }
}
html {
  scroll-behavior: smooth;
}
.partnerships {
  font-size: 62.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #242f4e;
  padding: 17em 0 9.6em 0;
}
#spread {
  font-size: 5.6em;
  max-width: 15ch;
  color: #f6f6f6;
  font-weight: 600;
  z-index: 2;
}
#belowspread {
  font-size: 1.6em;
  color: #c4cadc;
  font-weight: 400;
  padding-bottom: 48px;
  z-index: 2;
}
.partnershipsbuttons {
  display: flex;
  flex-direction: row;
  gap: 2.4em;
  z-index: 2;
}
#group52 {
  position: absolute;
  right: 0;
  z-index: 1;
}
#frame52 {
  position: absolute;
  left: 0;
  z-index: 1;
}
#group1 {
  position: absolute;
  z-index: 1;
  top: 392px;
  left: 250px;
}
.partnerships2 {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f2f8f1;
  padding: 7.2em 0 7.2em 0;
}
#mutual {
  font-size: 3.2em;
  color: #364d2f;
  font-weight: 600;
  z-index: 2;
  margin-bottom: 8px;
}
#belowmutual {
  font-size: 1.6em;
  color: #575757;
  font-weight: 400;
  padding-bottom: 48px;
  z-index: 2;
}
.partnership2top2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: start;
  gap: 2.4em;
  z-index: 2;
}
#partnership2top2left {
  background-color: #fff;
  z-index: 2;
  max-width: 36em;
  /* height: 28.4em; */
  border-radius: 3.2em;
  padding: 3.2em 2.4em;
}
#partnership2top2right {
  background-color: #fff;
  z-index: 2;
  max-width: 74.4em;
  height: 100%;
  border-radius: 3.2em;
  padding: 3.2em 2.4em;
}
#group43mobile {
  display: none;
}
.partnership2top2lefttext {
  position: relative;
  z-index: 2;
  font-size: 2.4em;
  color: #364d2f;
  padding-bottom: 0.4em;
}
.partnership2top2leftpara {
  font-size: 1.6em;
  color: #575757;
}
.partnership2top2leftbutton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 3.2em;
  border: none;
  background-color: white;
  color: #74a766;
  gap: 0.4em;
}
#clientspara {
  padding: 0 2.4em 0 0;
}
#offerpara {
  padding: 0 16.4em 0 0;
}
.linksnefn {
  text-decoration: none;
}
.form1 {
  display: flex;
  flex-direction: column;
}
.companyandemail {
  display: flex;
  flex-direction: row;
  gap: 2.4em;
}
.nameanddesignation {
  display: flex;
  flex-direction: row;
  gap: 2.4em;
}
#partnership2top2left {
  position: relative;
}
#group42 {
  position: absolute;
  right: 0;
  z-index: 1;
}
#partnership2top2right {
  position: relative;
}
#group42mobile {
  display: none;
}
#group54mobile {
  display: none;
}
#group67 {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 3.2em;
  z-index: 1;
}
.partnership2bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: start;
  gap: 4em;
  z-index: 2;
  margin-top: 4.8em;
}
.partnership2bottom1 {
  display: flex;
  flex-direction: column;
  max-width: 36em;
}
.partnership2bottom2 {
  display: flex;
  flex-direction: column;
  max-width: 36em;
}
.partnership2bottom3 {
  display: flex;
  flex-direction: column;
  max-width: 36em;
}
#group67mobile {
  display: none;
}
.partnership2bottomtext {
  font-size: 2.4em;
  color: #364d2f;
  padding-bottom: 0.8em;
  display: flex;
  align-items: center;
  gap: 0.6em;
}
.partnership2bottompara {
  font-size: 1.6em;
  color: #575757;
  padding: 0 1em 0 4em;
}
#partnership2bottompara21 {
  font-size: 1.6em;
  color: #575757;
  padding-right: 2em;
}
#vector711 {
  position: absolute;
  left: 0;
  z-index: 1;
}
.partnerships3 {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #eceef4;
  padding: 7.2em 0 7.2em 0;
  position: relative;
}
#corporate {
  font-size: 3.2em;
  color: #1b243b;
  font-weight: 600;
  z-index: 2;
  margin-bottom: 8px;
}
#corporatepara {
  font-size: 1.6em;
  color: #575757;
  font-weight: 400;
  padding-bottom: 48px;
  z-index: 2;
}
.partnerships3top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: start;
  gap: 2.4em;
  z-index: 2;
}
.partnerships3topop {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  z-index: 2;
}
.partnershipnumbers {
  font-size: 3.2em;
  color: #1b243b;
  font-weight: 600;
}
.partnershipheader {
  font-size: 2.8em;
  color: #1b243b;
  font-weight: 600;
  padding: 48px 48px 48px 24px;
}
.partnerships3text {
  font-size: 1.6em;
  max-width: 600px;
  color: #575757;
}
#partnerships4 {
  position: relative;
  z-index: 3;
  background-color: white;
  font-size: 10px;
  padding: 72px 192px 72px 192px;
}
#talktous {
  border-radius: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #242f4e;
  z-index: 2;
}
#talktoush2 {
  position: relative;
  color: #f6f6f6;
  font-size: 3.2em;
  font-weight: 500;
  margin-top: 54px;
}
#talktouspara {
  color: #c4cadc;
  font-size: 1.6em;
  padding-bottom: 2.4em;
  z-index: 2;
}
.formlabels {
  color: #a8b1cb;
  font-size: 1.6em;
}
.forminputs {
  background-color: #2e3c64;
  border: #3b4d80 1px solid;
  color: #a8b1cb;
  padding: 0;
  width: 285px;
  font-size: 1.6em;
  margin: 8px 0 24px 0;
  padding: 8px 16px;
  border-radius: 32px;
}
#submitpatnership {
  background-color: #f6f6f6;
  border: none;
  color: #3b4d80;
  padding: 8px 24px;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 42px auto 54px auto;
}
#submitpatnership.submitted {
  background-color: #3b4d80;
  color: #f6f6f6;
}
#group43 {
  position: absolute;
  left: 0;
  z-index: 1;
}
#group54 {
  position: absolute;
  right: 0;
  z-index: 1;
}
.partnerships3topop3 {
  border-top: #c4cadc 1px solid;
}
#partnerships3topop1 {
  border-bottom: #c4cadc 1px solid;
}
#partnershps3test {
  padding: 0 0 0 34px;
}
#partnership3textparaw2 {
  padding: 0 0 0 18px;
}
#group404 {
  position: absolute;
  top: 78%;
  left: 0;
  z-index: 1;
  border-radius: 24px;
}
#vector404 {
  position: absolute;
  z-index: 1;
  border-radius: 24px;
  top: 73.8%;
  left: 158px;
}
#vector505 {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 70%;
}
.talktousform {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 2;
}
#frame5 {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 3;
}

@media (max-width: 767px) {
  .phoneandmessage {
    flex-direction: column;
  }
  #spread {
    font-size: 3.6em;
    max-width: 20ch;
    font-weight: 600;
  }
  #belowspread {
    font-size: 1.4em;
    max-width: 30ch;
  }
  .partnershipsbuttons {
    gap: 2em;
  }
  #dashboard {
    width: 150px;
  }
  #learnmore {
    width: 150px;
  }
  #group52 {
    display: none;
  }
  #frame52 {
    display: none;
  }
  #group1 {
    display: none;
  }
  #group321 {
    top: 8em;
  }
  #Group1mobile {
    top: 43em;
  }
  #mutual {
    font-size: 2.4em;
    text-align: start;
    padding-left: 20px;
  }
  #belowmutual {
    font-size: 1.4em;
    text-align: start;
    padding: 8px 20px 20px 20px;
  }
  .partnership2top2 {
    justify-content: center;
    flex-direction: column;
    gap: 2em;
  }
  #partnership2top2left {
    max-width: 100%;
    padding: 1.6em;
    border-radius: 24px;
    margin: 0 2em;
  }
  #partnership2top2right {
    max-width: 100%;
    padding: 1.6em;
    border-radius: 24px;
    margin: 0 2em;
  }
  .partnership2top2lefttext {
    font-size: 2em;
  }
  .partnership2top2leftpara {
    font-size: 1.4em;
  }
  .partnership2top2leftbutton {
    margin-top: 20px;
  }
  #group42 {
    display: none;
  }
  #group42mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 3.2em;
    z-index: 1;
  }
  #group67 {
    display: none;
  }
  .partnership2bottom {
    flex-direction: column;
    gap: 2.4em;
    margin-top: 2em;
    padding: 0 2em;
  }
  #offerpara {
    padding: 0 30px 0 0;
  }
  #clientspara {
    padding: 0 30px 0 0;
  }
  #group67mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 2.4em;
    z-index: 1;
  }
  .partnerships2 {
    padding: 4.8em 0 4.8em 0;
  }
  .partnership2bottomtext {
    font-size: 2em;
  }
  .partnership2bottompara {
    font-size: 1.4em;
  }
  #partnership2bottompara21 {
    font-size: 1.4em;
  }
  #vector711 {
    display: none;
  }
  .partnerships3 {
    padding: 4.8em 2em 4.8em 2em;
    align-items: flex-start;
  }
  #corporate {
    font-size: 2.4em;
  }
  #corporatepara {
    font-size: 1.4em;
    padding-bottom: 2em;
  }
  .partnerships3top {
    flex-direction: column;
    gap: 2em;
  }
  .partnerships3topop {
    max-width: 100%;
  }
  .partnershipnumbers {
    padding: 24px 0 4px 0;
    font-size: 2.8em;
  }
  .partnershitr {
    display: flex;
    flex-direction: column;
  }
  .partnershipheader {
    font-size: 2.4em;
    padding: 0;
  }
  .partnerships3text {
    font-size: 1.4em;
    max-width: 100%;
    display: block;
  }
  #partnership3textparaw2 {
    padding: 16px 0 24px 0;
  }
  #partnershps3test {
    padding: 16px 0 24px 0;
  }
  #vector505 {
    display: none;
  }
  #group404 {
    display: none;
  }
  #vector404 {
    display: none;
  }
  .forminputs {
    width: 80%;
  }
  .partnerships3text {
    padding: 16px 0 24px 0;
  }
  #partnerships4 {
    padding: 48px 20px 48px 20px;
  }
  #talktous {
    padding: 54px 20px 54px 20px;
  }

  #talktoush2 {
    font-size: 2.4em;
    margin-top: 0;
    display: flex;
    margin: auto;
  }
  #talktouspara {
    font-size: 1.4em;
    margin: auto;
    padding-bottom: 2em;
  }
  #talktousform {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-right: 0;
    min-width: 0;
    width: 100%;
  }
  .formlabels {
    font-size: 1.2em;
  }
  #submitpatnership {
    margin: 48px 0 0 0;
    width: 100%;
  }
  #group43 {
    display: none;
  }
  #group54 {
    display: none;
  }
  #group43mobile {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  #group54mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .nameanddesignation {
    flex-direction: column;
    gap: 0;
  }
  .companyandemail {
    flex-direction: column;
    gap: 0;
  }
}

@media (min-width: 767px) and (max-width: 1100px) {
  .form1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phoneandmessage {
    flex-direction: column;
  }
  .nameanddesignation {
    gap: 0;
    flex-direction: column;
  }
  .companyandemail {
    gap: 0;
    flex-direction: column;
  }
  #group54 {
    display: none;
  }
  #group43 {
    display: none;
  }
  #group43mobile {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  #group54mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  #vector404 {
    display: none;
  }
  #group404 {
    top: 85%;
  }
  .partnership2bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #frame370 {
    display: none;
  }
  .partnership2bottompara {
    padding: 0;
  }
}
.container {
  background-color: #242f4e;
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  min-height: 20%;
  padding-top: 60px;
}

#discover {
  align-self: center;
  text-align: center;
  margin: 100px 300px 8px 300px;
  font-size: 56px;
  font-weight: 600;
  z-index: 2;
  position: relative;
  transition: all 0.3s;
  line-height: 64px;
  max-width: 20ch;
}
#discover:hover {
  transform: scale(1.05);
}
.GoldBondsmob {
  display: none;
}
#belowh2 {
  /* margin-top: 250px; */
  font-size: 16px;
  font-weight: 200;
  z-index: 2;
  transition: all 0.3s;
  line-height: 24px;
  color: #c4cadc;
}
#belowh2:hover {
  transform: scale(1.05);
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* Adjust this to control the space between the buttons */
  margin-top: 48px;
}

#dashboard {
  padding: 10px 24px;
  align-self: center;
  font-size: 16px;
  border-radius: 40px;
  border: none;
  color: #3b4d80;
  font-weight: bold;
  transition: all 0.1s;
  z-index: 2;
}
#dashboard:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.learnmorebuttonaiasarea {
  text-decoration: none;
  display: flex;
  justify-content: center;
  background-color: white;
  color: #2d2d2d;
  border: 1px solid #2d2d2d;
  border-radius: 40px;
  padding: 10px 24px;
  z-index: 2;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.1s;
  margin-top: 16px;
}
.aiasheedingpara1 {
  max-width: 63ch;
  color: #2d2d2d;
  font-size: 16px;
}
.aiasheedingpara {
  display: flex;
}
.textsectionrobotsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newcoponentfromthedesign {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 72px 0;
}
.aiasheedingtext1 {
  color: #364d2f;
  font-size: 20px;
  font-weight: 700;
  padding: 24px 0 16px 0;
}
.aiasheedingpara {
  margin: 8px 0 8px 0;
  color: #2d2d2d;
  font-size: 16px;
}
.aiasheedingtext {
  color: #364d2f;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 8px;
}
#app_badging {
  margin-right: 16px;
}
#learnmore {
  text-decoration: none;
  display: flex;
  justify-content: center;
  background-color: #242f4e;
  color: white;
  border: white 2px solid;
  border-radius: 40px;
  padding: 10px 24px;
  z-index: 2;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.1s;
}

#learnmore:hover {
  cursor: pointer;
  transform: scale(1.1);
}
#baskets {
  margin: 80px 0 0 0;
  z-index: 2;
  position: relative;
  top: 0.5px;
  width: 76%;
}
#container2designmobile {
  display: none;
}
#group1 {
  position: absolute;
  top: 380px;
  z-index: 1;
  left: 60%;
}
#Rectangle191 {
  top: 2770px;
  position: absolute;
  width: 30%;
  left: 50%;
  border-radius: 32px;
}
#Frame59 {
  top: 2900px;
}
#Group82 {
  top: 3000px;
}
.etfbasketmobile {
  display: none;
}
#group32 {
  position: absolute;
  z-index: 1;
  right: 0;
}
#Frame394mobile {
  display: none;
}
#frame {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 150px;
}
#Baskets1mobile {
  display: none;
}
#signup {
  display: none;
}
#group321 {
  display: none;
}
#Elevate1 {
  display: none;
}
#getstarted1 {
  display: none;
}
#Framemobile {
  display: none;
}
#Group1mobile {
  display: none;
}
#basketsetfmobile {
  display: none;
}

#comprehensive {
  display: flex;
  font-size: 32px;
  color: #1b243b;
  justify-content: center;
  padding: 72px 0 48px 0;
}

#Vectorcontainer2right {
  position: absolute;
  z-index: 1;
  right: 0;
}
#insuranceimgmob {
  display: none;
}
#ipoimgmob {
  display: none;
}
.container2 {
  min-height: 1126px;
  background-color: #f4f7fe;
}

#Vectorcontainer2left {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 1800px;
}

.goalplanningandexpertadvisory {
  display: flex;
  justify-content: center;
  gap: 10%;
}
#container1designmobile {
  display: none;
}
.goalplanning {
  max-width: 420px;
  justify-content: center;
  align-items: center;
}

.expertadvisory {
  max-width: 420px;
  justify-content: center;
  align-items: center;
}
#ads_click {
  display: none;
}
#goalplanning {
  display: flex;
  font-size: 24px;
  color: #1b243b;
  justify-content: center;
  padding: 8px 0 4px 0;
}
#goalplanningtext {
  display: flex;
  font-size: 16px;
  color: #575757;
  text-align: center;
}
#goldbondsmob1 {
  display: none;
}
#group398 {
  display: flex;
  margin: auto;
  max-width: 80px;
}
#group395 {
  display: flex;
  margin: auto;
  max-width: 80px;
}
#expertadvisory {
  display: flex;
  font-size: 24px;
  color: #1b243b;
  justify-content: center;
  padding: 8px 0 4px 0;
}

#expertadvisorytext {
  padding: 0 20px;
  display: flex;
  font-size: 16px;
  color: #575757;
  text-align: center;
}
.ipoandinvestment {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0 24px 0;
  position: relative;
  z-index: 2;
}
.ipo {
  max-width: 552px;
  border: 1px solid #c4cadc;
  background-color: white;
  border-radius: 32px;
  margin-right: 12px;
}
#ipo {
  font-size: 24px;
  color: #1b243b;
  padding: 24px 0 8px 24px;
}
#workspace_premium {
  display: none;
}
#ipotext {
  font-size: 16px;
  color: #575757;
  padding: 0 50px 24px 24px;
}
#ipoimg {
  display: flex;
  margin: auto;
  margin-bottom: 24px;
}

.Insurance {
  max-width: 552px;
  border: 1px solid #c4cadc;
  background-color: white;
  border-radius: 32px;
  margin-left: 12px;
}
#Insurance {
  font-size: 24px;
  color: #1b243b;
  padding: 24px 0 8px 24px;
}
#Insurancetext {
  font-size: 16px;
  color: #575757;
  padding: 0 24px 24px 24px;
}
#insuranceimg {
  display: flex;
  margin: auto;
  margin-bottom: 24px;
}
.etfbasket {
  display: flex;
  justify-content: center;
  max-width: 360px;
  background-color: white;
  border-radius: 32px;
  border: 1px solid #c4cadc;
  z-index: 2;
  padding-top: 24px;
}
.etfbaskets {
  display: flex;
  justify-content: center;
  max-height: 320px;
  padding-bottom: 70px;
}
#basketsetf {
  padding: 24px 0 24px 0;
}
#etfbasket {
  font-size: 24px;
  color: #1b243b;
  padding-bottom: 8px;
}
#etfbaskettext {
  font-size: 16px;
  color: #575757;
}
.etfbaskettext {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
}
.Elevate {
  display: flex;
  background-color: #41558d;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  min-width: 360px;
  border-radius: 32px;
  height: 188px;
  margin: auto 24px auto 24px;
}
#Elevate {
  max-width: 237px;
  padding: 32px 0 0 0;
  max-width: 227px;
  border-radius: 32px;
  display: flex;
  color: white;
  font-size: 24px;
  text-align: center;
  transform: none;
  font-weight: 600;
  letter-spacing: -0.96px;
}
.signuptab {
  display: none;
}
.GoldBonds {
  display: flex;
  justify-content: center;
  padding: 0 24px;
  z-index: 2;
  flex-direction: column;
  background-color: white;
  max-width: 360px;
  border: 1px solid #c4cadc;
  box-sizing: border-box;
  border-radius: 32px;
}
#GoldBonds {
  font-size: 24px;
  color: #1b243b;
  padding-bottom: 8px;
  padding-top: 24px;
}
#Goldonds {
  font-size: 16px;
  color: #575757;
}
#goldbonds {
  padding: 24px 0 24px 0;
  z-index: 2;
}
#getstarted {
  padding: 10px 24px;
  height: 44px;
  max-width: 141px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 40px;
  border: none;
  color: #3b4d80;
  font-weight: bold;
  cursor: pointer;
}
.container3 {
  display: flex;
  background-color: #41558d;
  min-height: 516px;
  justify-content: center;
}
.EasySteps {
  padding-right: 96px;
}
#Easysteps {
  display: flex;
  font-size: 32px;
  color: white;
  padding: 72px 0 48px 0;
  transform: none;
  position: relative;
  letter-spacing: -0.96px;
}
.step1 {
  background-color: #f6f6f6;
  max-width: 456px;
  margin-bottom: 24px;
  border-radius: 16px;
}
.step1 p {
  font-size: 18px;
  font-weight: 500;
  color: #3b4d80;
  padding: 24px;
  letter-spacing: -0.64px;
}
.step2 {
  background-color: #3b4d80;
  max-width: 456px;
  margin-bottom: 24px;
  border-radius: 16px;
}
.step2 p {
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  padding: 24px;
  letter-spacing: -0.64px;
}
.step3 {
  background-color: #3b4d80;
  max-width: 456px;
  border-radius: 16px;
}
.step3 p {
  font-size: 18px;
  font-weight: 500;
  color: #f6f6f6;
  padding: 24px;
  letter-spacing: -0.64px;
}
#Rectangle22 {
  cursor: pointer;
  display: flex;
  border-radius: 32px;
  max-width: 50%;
  margin-top: 72px;
  z-index: 2;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
}
.container3img {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}
#Frame394 {
  position: absolute;
  z-index: 1;
  right: 0;
}

@media screen and (max-width: 700px) {
  .container {
    min-height: 300px;
  }

  #discover {
    margin: 56px 20px 8px 20px;
    font-size: 36px;
    font-weight: 600px;
    line-height: 42px;
  }
  #belowh2 {
    padding: 8px 25px 16px 25px;
    font-size: 14px;
    line-height: 20px;
    color: #c4cadc;
  }
  #group32 {
    display: none;
  }
  #group321 {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
  }
  #frame {
    display: none;
  }
  #Framemobile {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 200px;
  }
  #group1 {
    display: none;
  }
  #Group1mobile {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 470px;
  }
  #baskets {
    width: 90%;
    margin: 80px 0;
    z-index: 2;
  }
  #comprehensive {
    padding: 48px 0 20px 20px;
    font-weight: bold;
    font-size: 24px;
    justify-content: flex-start;
  }
  #Vectorcontainer2right {
    display: none;
  }
  #goalplanning {
    align-items: center;
    padding: 0;
    font-size: 20px;
  }
  #goalplanningtext {
    display: none;
  }
  #expertadvisory {
    align-items: center;
    padding: 0;
    font-size: 20px;
  }
  #expertadvisorytext {
    display: none;
  }
  .goalplanningandexpertadvisory {
    display: block;
    flex-direction: column;
  }
  .goalplanning {
    margin: 0;
    display: flex;
    margin-bottom: 16px;
  }
  .expertadvisory {
    margin: 0;
    display: flex;
  }
  #group395 {
    margin: 0;
  }
  #group398 {
    margin: 0;
  }
  #container2designmobile {
    position: absolute;
    display: block;
    right: 0;
  }
  .Elevate {
    display: none;
  }
  #signup {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .ipo {
    min-width: 292px;
    border-radius: 24px;
    margin-right: 0;
  }
  .signuptab {
    display: none;
  }
  #ipoimg {
    display: none;
  }
  #ipo {
    font-size: 20px;
    padding: 16px 0 4px 16px;
  }
  #ipotext {
    font-size: 14px;
    padding: 0 16px 16px 16px;
  }
  .Insurance {
    min-width: 292px;
    margin-left: 0;
    border-radius: 24px;
  }
  #insuranceimg {
    display: none;
  }
  #insuranceimgmob {
    display: block;
    width: 90%;
    padding-bottom: 16px;
    margin: auto;
  }
  #Insurance {
    font-size: 20px;
    padding: 16px 0 4px 16px;
  }
  #Insurancetext {
    font-size: 14px;
    padding: 0 16px 16px 16px;
  }
  #group398 {
    display: none;
  }
  #workspace_premium {
    display: block;
    padding: 4px;
    border-radius: 100%;
    background-color: #3b4d80;
    margin-left: 20px;
    box-shadow: 0 2px 8px #41558d12;
    margin-right: 12px;
  }
  .etfbasket {
    display: none;
  }
  #container1designmobile {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 1370px;
  }
  .etfbasketmobile {
    min-width: 292px;
    display: flex;
    justify-content: center;
    background-color: white;
    border: 1px solid #c4cadc;
    z-index: 2;
    border-radius: 24px;
  }
  #etfbasketmobile {
    font-size: 20px;
    color: #1b243b;
    padding: 16px 0 4px 16px;
  }
  #etfbaskettextmobile {
    font-size: 14px;
    color: #575757;
    padding: 0 16px 16px 16px;
  }
  #basketsetfmob {
    display: flex;
    width: 90%;
    margin: auto;
    padding-bottom: 16px;
  }
  .Rectangle19productpage {
    top: 2500px;
  }
  #basketsetfmobile {
    display: none;
  }
  .GoldBonds {
    display: none;
  }
  #baskets {
    display: none;
  }
  #Baskets1mobile {
    display: block;
    margin-top: 64px;
    z-index: 2;
    position: relative;
    top: 0.7px;
  }
  #group395 {
    display: none;
  }
  #ads_click {
    display: block;
    padding: 4px;
    border-radius: 100%;
    background-color: #3b4d80;
    margin-left: 20px;
    box-shadow: 0 2px 8px #41558d12;

    margin-right: 12px;
  }
  .GoldBondsmob {
    display: flex;
    min-width: 292px;
    background-color: white;
    border-radius: 24px;
    border: 1px solid #c4cadc;
    z-index: 2;
    flex-direction: column;
  }

  #ipoimgmob {
    display: block;
    width: 90%;
    padding-bottom: 16px;
    margin: auto;
  }
  #GoldBondsmob {
    font-size: 20px;
    color: #1b243b;
    padding: 16px 0 4px 16px;
  }
  #Goldondsmob {
    font-size: 14px;
    color: #575757;
    padding: 0 16px 16px 16px;
  }
  #goldbondsmob1 {
    display: block;
    width: 90%;
    z-index: 2;
    margin: 0 auto;
    padding-bottom: 16px;
  }
  #goldbondsmob {
    display: none;
  }
  #getstartedbackground {
    display: none;
  }
  #Frame404mobileonly {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin: auto 20px auto 0;
  }
  .ipoandinvestment {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    overflow-x: auto;
    padding: 24px 0 20px 20px;
    scroll-padding-left: 20px; /* Add this line */
  }
  #robotflyingimage {
    display: none;
  }
  .paraaferstrongthingproductpage {
    display: none;
  }
  .aiasheedingtext {
    font-size: 24px;
  }
  .aiasheedingpara {
    font-size: 14px;
  }
  .newcoponentfromthedesign {
    padding: 48px 20px;
  }
  .signup {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .goalplanning {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .expertadvisory {
    justify-content: flex-start;
    align-items: flex-start;
  }
  #Elevate {
    display: none;
  }
  #getstarted {
    display: none;
  }
  #Rectangle22 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 300%;
    border-radius: 32px;
  }
  .linktomywelthfabitsinsection3 {
    width: 100%;
  }
  .container3img {
    width: 100%;
  }
  .EasySteps {
    padding: 0;
  }
  #Rectangle191 {
    top: 2500px;
    position: absolute;
    width: 80%;
    left: 10%;
    margin: auto;
    border-radius: 20px;
  }
  #Easysteps {
    padding: 48px 20px 20px 20px;
    font-size: 24px;
  }
  #signup {
    background-color: #f4f7fe;
    border: none;
  }
  .container3 {
    flex-direction: column;
  }
  .step1 {
    margin: 20px;
    width: 80%;
  }
  .step2 {
    margin: 20px;
    width: 80%;
  }
  .step3 {
    margin: 20px;
    width: 80%;
  }
  .step1 p {
    font-size: 16px;
    padding: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
  }
  .step2 p {
    font-size: 16px;
    padding: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
  }
  .step3 p {
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    letter-spacing: -0.64px;
  }
  #Vectorcontainer2left {
    display: none;
  }
  .container2 {
    min-height: 600px;
  }
  #Frame394 {
    display: none;
  }
  #dashboard {
    font-size: 14px;
    padding: 10px 10px;
    font-weight: 500;
    letter-spacing: -0.56px;
    line-height: 20px;
  }
  #learnmore {
    font-size: 14px;
    padding: 10px 10px;
    line-height: 20px;
    letter-spacing: -0.56px;
    font-weight: 500;
  }
  .buttons-container {
    gap: 20px;
  }
}
@media screen and (min-width: 420px) and (max-width: 699px) {
  #discover {
    margin: 56px 20px;
    font-size: 38px;
  }
}
@media screen and (min-width: 701px) and (max-width: 1200px) {
  #discover {
    margin: 100px 20px;
  }
  #ipoimg {
    width: 312px;
    margin-bottom: 24px;
  }
  #ipotext {
    padding: 0 24px 24px 24px;
  }
  #insuranceimg {
    width: 312px;
    margin-bottom: 24px;
  }
  .ipo {
    max-width: 350px;
  }
  .etfbasket {
    min-width: 350px;
  }
  .GoldBonds {
    min-width: 350px;
  }
  .Insurance {
    max-width: 350px;
  }
  .container {
    min-height: 800px;
  }
  .etfbaskets {
    gap: 20px;
    padding-bottom: 20px;
    justify-content: center;
  }
  .signuptab {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }
  #signuptab {
    border: none;
  }
  #Frame404mobileonlytab {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signup {
    display: block;
  }
  #basketsetf {
    display: block;
  }
  .container3 {
    flex-direction: column;
  }
  .Elevate {
    display: none;
  }
  #Easysteps {
    padding: 48px 20px 20px 20px;
    font-size: 34px;
  }
  .step1 {
    margin: 20px;
    width: 80%;
    z-index: 2;
  }
  .step2 {
    margin: 20px;
    width: 80%;
  }
  .step3 {
    margin: 20px;
    width: 80%;
  }
  .step1 p {
    font-size: 16px;
    padding: 16px;
  }
  .step2 p {
    font-size: 16px;
    padding: 16px;
  }
  .step3 p {
    font-size: 16px;
    padding: 16px;
  }
  .EasySteps {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 60px;
  }
  #Rectangle22 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 60%;
  }
  #group32 {
    width: 40%;
  }
  #frame {
    width: 30%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1325px) {
  .container {
    min-height: 800px;
  }
}

@media screen and (min-width: 699px) and (max-width: 940px) {
  .container {
    min-height: 20%;
  }
}
.footer-container b {
  color: #eaeaea;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 8px;
}
.footer-container p {
  color: #eaeaea;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 8px;
}
.footer-container h1 {
  color: #eaeaea;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 8px;
}
.footer-container ul {
  color: #eaeaea;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 8px;
}
.footer-container h2 {
  color: #eaeaea;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 8px;
}
article {
  color: #eaeaea;
  line-height: 22px;
  font-weight: 300;
}
article a {
  color: #8ed5df;
  line-height: 22px;
  font-weight: 300;
}

.footer-header {
  display: flex;
  justify-content: space-between;
}

.nav-link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link-container a {
  color: #eaeaea;
}
.footer-logo {
  width: 13%;
}

.dashed-line {
  padding: 0px;
  border: 1px dashed #41558d;
  margin: 40px 0px;
}
.escalate-matrix {
  border-collapse: collapse;
  width: 60%; /* Adjusts table width to content */
  table-layout: auto; /* Fixes column widths for scrolling */
  margin: 10px auto; /* Centers table horizontally */
}

.escalate-matrix th,
.escalate-matrix td {
  padding: 10px;
  border: 1px solid #edf0f7;
}

.title {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.heading {
  font-weight: normal;
}

.content {
  text-align: center;
}

/* Centering links within table cells */
.content a {
  display: block;
  margin: 0 auto;
}

/* New rule for bold row */
.bold-row th {
  font-weight: bold;
  background-color: #f5f5f5;
}

.escalate-matrix-container {
  /* width: 100vw; Ensures full width within its container */
  overflow-x: auto; /* Enables horizontal scroll */
}
