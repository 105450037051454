.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin-top: 0;
}

.error-image {
  width: 100%;
  max-width: 426.25px;
  height: auto;
  opacity: 1;
}

.error-text {
  text-align: center;
  margin-top: 20px;
}

.error-text p:first-child {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.01em;
}

.small-text {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #2d2d2d;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  gap: 8px;
  padding: 5px;
  margin-bottom: 25px;
}

.error_btn_learn,
.error_btn_explore {
  cursor: pointer;
  gap: 8px;
  padding: 10px 24px;
  border-radius: 40px;
  min-width: 150px;
}

.error_btn_learn {
  background: #ffffff;
  color: black;
  border: 2px solid #2d2d2d;
}

.error_btn_explore {
  background: #41558d;
  color: white;
}

@media (min-width: 768px) {
  /* Media query for desktop screens */
  .not-found-container {
    margin-top: 90px;
  }
}
