.container {
  display: flex;
  justify-content: space-between;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    max-width: 386px;
    /* height: 300px; */
  }

  > div:nth-of-type(2) {
    width: 549px;
    height: 577px;
    background: linear-gradient(
      190.28deg,
      #ffffff 28.92%,
      rgba(112, 142, 228, 0.15) 90%
    );
    border: 1px solid #eceef4;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 250.4px;
      height: 455.01px;
    }
  }
}

.tabDiv{
  height: 300px;
  /* display: grid;
  gap: 10px; */
}

.link {
  color: #41558d;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.04em;
  text-decoration: underline;
  text-underline-offset: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.header {
  box-sizing: border-box;
  padding: 16px;
}

.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
  /* margin-bottom: 0.5rem; */
}

.subtitle {
  font-size: 16px;
  line-height: 20.83px;
  margin-top: 12px;
  color: #475569;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accordionItem {
  /* border: 1px solid #e2e8f0; */
  /* border-radius: 0.5rem; */
  /* background-color: white; */
  overflow: hidden;
  margin-top: 20px;
}

.accordionTrigger {
  background-color: #f1f5f9;
  width: 386px;
  padding: 1rem;
  text-align: left;
  /* background: none; */
  border: none;
  font-size: 1.125rem;
  color: #475569;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordionTrigger:hover {
  background-color: white;
}

/* .accordionTrigger::after {
  content: "+";
  font-size: 1.5rem;
} */

/* .accordionTrigger[data-state="open"]::after {
  content: "-";
} */

.accordionContent {
  padding: 1rem;
  font-size: 1rem;
  color: #64748b;
  background-color: #ffffff;
}
