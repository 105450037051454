.Slider {
  display: flex;
  gap: 20px;
  align-items: baseline;
}

.SliderContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.slider {
  width: 100%;
}

.Cost {
  border: 1px solid #d3dbec;
  width: 116px;
  height: 36px;
  padding: 6px 16px 6px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricingslidersection {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #c4cadc;
}

.sliderspan {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2d2d2d;
}

/* Slider css */

/* Style the slider track */
input[type="range"] {
  -webkit-appearance: none; /* Remove default appearance */
  width: 100%; /* Full width */
  height: 8px; /* Height of the slider */
  background: #ddd; /* Slider track color */
  border-radius: 5px; /* Rounded corners */
  outline: none; /* Remove outline */
  opacity: 1.7; /* Transparency */
  -webkit-transition: 0.2s; /* Transition for changes */
  transition: opacity 0.2s;
  position: relative; /* To enable absolute positioning for the progress */
}

/* Style the slided part of the track */
input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #41558d 0%,
    #41558d var(--progress),
    #ddd var(--progress),
    #ddd 100%
  );
  height: 8px; /* Ensure consistent height */
  border-radius: 5px; /* Ensure consistent border radius */
}

/* For Firefox */
input[type="range"]::-moz-range-progress {
  background: #41558d;
  height: 8px; /* Ensure consistent height */
}

input[type="range"]::-moz-range-track {
  background: #ddd;
  height: 8px; /* Ensure consistent height */
  border-radius: 5px; /* Ensure consistent border radius */
}

/* Style the slider thumb (button) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default appearance */
  appearance: none;
  width: 25px; /* Width of the thumb */
  height: 25px; /* Height of the thumb */
  background: #41558d; /* Thumb color */
  border: 6px solid #ffffff; /* Thumb border */
  box-shadow: 0px 1.5px 4px 0px #0000001f; /* Thumb shadow */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50%; /* Rounded thumb */
  position: relative; /* To enable box-shadow positioning */
  top: -10px; /* Move thumb up */
}

/* Style the slider thumb (button) for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 25px; /* Width of the thumb */
  height: 25px; /* Height of the thumb */
  background: #41558d; /* Thumb color */
  border: 6px solid #ffffff; /* Thumb border */
  box-shadow: 0px 1.5px 4px 0px #0000001f; /* Thumb shadow */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50%; /* Rounded thumb */
  position: relative; /* To enable box-shadow positioning */
  top: -6px; /* Move thumb up */
}

/* Optional: Style the slider track when it has focus */
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #41558d 0%,
    #41558d var(--progress),
    #ccc var(--progress),
    #ccc 100%
  );
  height: 8px; /* Ensure consistent height */
  border-radius: 5px; /* Ensure consistent border radius */
}
