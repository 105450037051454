.tabs-container {
  margin: 20px auto;
  max-width: 1128px;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.tab {
  cursor: pointer;
  padding: 12px 20px;
  border-radius: 32px;
  color: #41558d;
  border: 1px solid #41558d;
}

.active {
  background: linear-gradient(90deg, #708ee4 0%, #242f4e 100%);
  color: #ffffff;
  border: none;
}

.tab-content {
  margin-top: 54px;
  padding-bottom: 72px;
}

@media screen and (max-width: 1023px) {
  .tabs-container {
    margin: 20px auto;
    max-width: 350px;
  }

  .tabs {
    display: flex;
    justify-content: center;
    gap: 13px;
  }

  .tab {
    cursor: pointer;
    padding: 12px;
    border-radius: 20.51px;
    color: #41558d;
    border: 1px solid #41558d;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: -0.04em;
  }

  .active {
    background: linear-gradient(90deg, #708ee4 0%, #242f4e 100%);
    color: #ffffff;
    border: none;
  }

  .tab-content {
    margin-top: 48px;
    padding-bottom: 48px;
  }
}
