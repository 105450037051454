.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 60px;
  padding: 68px 96px;
}

.card {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  > div:nth-of-type(1) {
    > p:nth-of-type(1) {
      font-size: 24px;
      font-weight: bolder;
    }

    > p:nth-of-type(2) {
      font-size: 14px;
    }
  }
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.05);
}

/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 60px;
    padding: 68px 20px;
  }
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-top: 60px;
    padding: 68px 20px;
  }
}
