.container {
  padding: 72px 0px 88px 0px;
  display: flex;
  justify-content: center;

  > h1 {
    width: 768px;
    font-family: DM Sans;
    font-size: 42px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -0.04em;
    text-align: center;
    color: var(--black-500);
  }
}

.featuresContent {
  background-color: var(--black-50);
  height: 661px;
  padding-bottom: 116px;
  margin-bottom: 112px;
  border-radius: 24px;
  display: flex;
  gap: 48px;
  position: relative;

  > div:nth-of-type(1) {
    color: var(--black-500);
    padding: 48px 48px 116px 48px;
    > h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;
    }

    > p {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    > ul {
      list-style-type: none;
      margin-top: 68px;
      display: grid;
      gap: 24px;

      > li {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  > div:nth-of-type(2) {
    height: 741px;
    width: 250.67px;
    border-radius: 24px;
    background: linear-gradient(0deg, #2e3c64, #2e3c64),
      linear-gradient(167.4deg, #6777a4 -12.27%, rgba(103, 119, 164, 0) 84.33%);
    box-shadow: 0px 4px 24px 0px #242f4e29;
    padding: 64px 20px;
    color: #ffffff;
    position: absolute;
    top: -40px;
    left: 33%;

    > div:nth-of-type(1) {
      height: 104px;
      display: grid;
      justify-items: center;
      align-content: space-between;

      > h1 {
        font-family: DM Sans;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.04em;
        text-align: center;
      }

      > div {
        > p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: var(--primary-100);
        }
      }
    }

    > ul {
      list-style-type: none;
      margin-top: 48px;
      display: grid;
      gap: 24px;
      > li {
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      > button {
        cursor: pointer;
      }
    }
  }
  > div:nth-of-type(3) {
    width: 250.67px;
  }
  > div:nth-of-type(4) {
    padding: 48px 20px 120px 20px;
    > h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: center;
      color: var(--black-500);
    }

    > ul {
      list-style-type: none;
      display: grid;
      gap: 24px;
      justify-items: center;
      margin-top: 92px;

      > li {
        text-align: center;
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--black-500);
      }

      > li:nth-of-type(6) {
        /* background-color: red; */
        margin-top: 38px;
      }
    }
  }
  > div:nth-of-type(5) {
    padding: 48px 20px 120px 20px;
    > h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: center;
      color: var(--black-500);
    }

    > ul {
      list-style-type: none;
      display: grid;
      gap: 24px;
      justify-items: center;
      margin-top: 92px;

      > li {
        text-align: center;
        font-family: DM Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--black-500);
      }

      > li:nth-of-type(6) {
        /* background-color: red; */
        margin-top: 38px;
      }
    }
  }
}

.featuresContent_mobile {
  display: none;
}

.featuresContent_tab {
  display: none;
}

@media screen and (min-width: 617px) and (max-width: 1053px) {
  .container {
    padding: 48px 16px 64px 16px;
    display: flex;
    justify-content: center;

    > h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--black-500);
    }
  }

  .featuresContent {
    display: none;
  }

  .featuresContent_mobile {
    display: none;
  }

  .featuresContent_tab {
    display: flex;
    box-sizing: border-box;
    margin: 0px 16px;
    > div:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      gap: 40px;
      /* width: 180px; */
      height: 662px;
      padding: 48px 20px;
      border-radius: 20px;
      background: linear-gradient(0deg, #2e3c64, #2e3c64),
        linear-gradient(
          167.4deg,
          #6777a4 -12.27%,
          rgba(103, 119, 164, 0) 84.33%
        );
      box-shadow: 0px 4px 24px 0px #242f4e3d;
      position: relative;
      .heading {
        display: grid;
        gap: 8px;
        > h1 {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: center;
          color: #ffffff;
        }

        > div {
          display: grid;
          gap: 8px;
          > p {
            font-family: DM Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: var(--primary-100);
          }
        }
      }

      .feature > div {
        height: 72px;
        > div {
          display: flex;
          align-items: center;
          /* width: 70px; */
          gap: 5px;

          > div:nth-of-type(1) {
            color: var(--primary-100);
            > span {
              font-size: 16px;
            }
          }

          > div:nth-of-type(2) {
            font-family: DM Sans;
            > p:nth-of-type(1) {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: var(--primary-100);
            }

            > p:nth-of-type(2) {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }

        > p {
          display: block;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: var(--primary-100);
          margin-left: 21px;
        }
      }

      .nextBtn {
        display: flex;
        /* width: 160px; */
        padding: 12px 20px;
        border-radius: 0px 40px 40px 0px;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        position: absolute;
        bottom: 48px;
        left: 0px;
        box-sizing: border-box;
        cursor: pointer;

        > p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--primary-600);
        }

        > span {
          color: var(--primary-500);
        }
      }
    }

    > div:nth-of-type(2) {
      width: 100%;
      background-color: var(--black-50);
      margin: 40px 0px;
      display: flex;
      padding: 24px 16px;
      border-radius: 0px 20px 20px 0px;

      > div {
        /* border: 1px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        > div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: left;
          color: var(--black-500);
        }

        > div:nth-of-type(2) {
          margin-top: 78px;
        }
      }

      /* > div:nth-of-type(2) {
        border: 1px solid green;
      } */
    }
  }

  .featureEPF {
    height: 72px;
    display: grid;
    align-items: center;

    > div {
      display: flex;
      gap: 4px;

      > div:nth-of-type(1) > span {
        font-size: 21px;
      }
    }

    > p {
      margin-left: 25px;
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
}

@media screen and (max-width: 617px) {
  .container {
    padding: 48px 16px 64px 16px;
    display: flex;
    justify-content: center;

    > h1 {
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--black-500);
    }
  }

  .featuresContent {
    display: none;
  }

  .featuresContent_tab {
    display: none;
  }

  .featuresContent_mobile {
    display: flex;
    box-sizing: border-box;
    margin: 0px 16px;
    > div:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      gap: 40px;
      /* width: 180px; */
      height: 662px;
      padding: 48px 20px;
      border-radius: 20px;
      background: linear-gradient(0deg, #2e3c64, #2e3c64),
        linear-gradient(
          167.4deg,
          #6777a4 -12.27%,
          rgba(103, 119, 164, 0) 84.33%
        );
      box-shadow: 0px 4px 24px 0px #242f4e3d;
      position: relative;
      .heading {
        display: grid;
        gap: 8px;
        > h1 {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: center;
          color: #ffffff;
        }

        > div {
          display: grid;
          gap: 8px;
          > p {
            font-family: DM Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: var(--primary-100);
          }
        }
      }

      .feature > div {
        height: 72px;

        > div {
          display: flex;
          align-items: center;
          /* width: 70px; */
          gap: 5px;

          > div:nth-of-type(1) {
            color: var(--primary-100);
            > span {
              font-size: 16px;
            }
          }

          > div:nth-of-type(2) {
            font-family: DM Sans;
            > p:nth-of-type(1) {
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
              color: var(--primary-100);
            }

            > p:nth-of-type(2) {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }

        > p {
          display: block;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: var(--primary-100);
          margin-left: 21px;
        }
      }

      .feature > div:nth-of-type(6) {
        margin-top: 10px;
      }

      .planBtn {
        display: flex;
        width: 100%;
        > button {
          padding: 10px 24px;
          gap: 8px;
          border-radius: 40px;
          > span {
            font-family: DM Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: var(--primary-600);
          }
        }
      }

      .nextBtn {
        display: flex;
        width: 160px;
        padding: 12px 20px;
        border-radius: 0px 40px 40px 0px;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        position: absolute;
        bottom: 48px;
        left: 0px;
        box-sizing: border-box;

        > p {
          font-family: DM Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: var(--primary-600);
        }

        > span {
          color: var(--primary-500);
        }
      }
    }

    > div:nth-of-type(2) {
      width: 100%;
      background-color: var(--black-50);
      margin: 40px 0px;
      display: flex;
      padding: 24px 16px;
      border-radius: 0px 20px 20px 0px;
      position: relative;

      > div {
        /* border: 1px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > div:nth-of-type(1) {
          font-family: DM Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.04em;
          text-align: left;
          color: var(--black-500);
        }

        > div:nth-of-type(2) {
          margin-top: 78px;
        }

        .nextCardBtn {
          /* width: 100px; */
          width: 24px;
          height: 24px;
          padding: 4px;
          /* border-radius: 40px; */
          border-radius: 50%;
          background-color: #d9d9d9;
          display: flex;
          justify-content: center;
          align-items: center;

          > span {
            font-size: 15px;
          }
        }

        .left {
          position: absolute;
          bottom: 30px;
          left: 20px;
        }

        .right {
          position: absolute;
          bottom: 30px;
          right: 20px;
        }
      }
    }
  }

  .featureEPF {
    height: 72px;
    display: grid;
    align-items: center;

    > div {
      display: flex;
      gap: 4px;
      text-align: center;

      > div:nth-of-type(1) > span {
        font-size: 21px;
      }
    }

    > p {
      margin-left: 25px;
      font-family: DM Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }
  }
}
