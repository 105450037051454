
.table-container {
  font-family: arial, sans-serif;
  margin: 20px 0px 0px 0px;
}

.table-container table {
  border-collapse: collapse;
  width: 100%;
}

.table-container td,
.table-container th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

