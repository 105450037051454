.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  bottom: -205%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-500);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.875rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 99;
}

.tooltip-container:hover .tooltip-content {
  opacity: 1;
  visibility: visible;
}
