.content {
  max-width: 1512px;
  margin: auto;
  /* border: 1px solid red; */

  > h1 {
    text-align: center;
    padding-top: 72px;
    > span:nth-of-type(1) {
      color: #80b870;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    margin-top: 49px;
    padding-bottom: 72px;
    gap: 16.16px;

    > button {
      background-color: white;
      padding: 12px 32px;
      > a {
        display: flex;
        align-items: center;
        gap: 5px;
        color: black;
        font-size: 16px;
      }
    }

    > button:hover {
      background-color: var(--black-50);
    }
  }
}

@media screen and (max-width: 1023px) {
  .content {
    max-width: 273px;
    margin: auto;
    /* border: 1px solid red; */

    > h1 {
      text-align: center;
      padding-top: 72px;
      > span:nth-of-type(1) {
        color: #80b870;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      margin-top: 49px;
      padding-bottom: 72px;
      gap: 16.16px;

      > button {
        background-color: white;
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 12px 24px;

        > a {
          font-size: 12px;
        }
      }
    }
  }
}
