.content {
  max-width: 1128px;
  margin: auto;
  border: none;

  > div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 72px;
    margin-bottom: 48px;

    > p:nth-of-type(1) {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.04em;
      color: #1b243b;
    }

    > p:nth-of-type(2) {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.04em;
      border-bottom: 1px solid #41558d;

      > a {
        color: #41558d;
      }
    }
  }

  > div:nth-of-type(2) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 77px;
    padding-bottom: 72px;

    > div {
      display: flex;
      gap: 48px;

      > div:nth-of-type(1) {
        width: 251px;
        height: 98px;
        display: flex;
        justify-content: start;
        align-items: center;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        box-sizing: border-box;
        flex: 1;

        > img {
          width: 100%;
          height: auto;
          filter: grayscale(100%);
        }
      }

      > div:nth-of-type(2) {
        /* margin-left: 48px; */
        flex: 2;
        > p:nth-of-type(1) {
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -0.04em;
          color: #1b243b;
        }

        > p:nth-of-type(2) {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: -0.04em;
          color: #41558d;
          margin-top: 10px;

          > a {
            color: #41558d;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .content {
    max-width: 320px;
    /* border: 1px solid red; */
    margin: auto;

    > div:nth-of-type(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 48px;
      margin-bottom: 48px;

      > p:nth-of-type(1) {
        font-weight: 600;
        font-size: 24px;
        line-height: 48px;
        letter-spacing: -0.04em;
        color: #1b243b;
      }

      > p:nth-of-type(2) {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -0.04em;
        color: #41558d;
        border-bottom: 1px solid #41558d;
      }
    }

    > div:nth-of-type(2) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 48px;

      > div {
        display: block;
        
        > div:nth-of-type(1) {
          width: 320px;
          > img {
            width: 100%;
            /* height: 100%; */
          }
        }
        > div:nth-of-type(2) {
          margin-top: 8px;
          > p:nth-of-type(1) {
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: -0.04em;
            color: #1b243b;
          }

          > p:nth-of-type(2) {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.04em;
            color: #41558d;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
