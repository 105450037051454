.content {
  max-width: 554px;
  /* height: 577.33px; */
  border: 1px solid #aaaaaa;
  margin: auto;
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.heading {
  height: 123.33px;
  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    gap: 12px;
    > div {
      height: 28.33px;
      width: 28.33px;
      background-color: #e8efff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      > span {
        font-size: 16.19px;
        color: #6780c6;
      }
    }
    > p {
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      letter-spacing: -0.04em;
    }
  }

  > p {
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
    color: #575757;
  }
}

.img {
  display: flex;
  justify-content: center;
}

.fee {
  height: 72px;
  > h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.04em;
    color: #242f4e;
  }

  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #636363;
  }
}

.callback {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 83.67px;

  > button {
    background-color: #41558d;
    padding: 12px 16px;
    border-radius: 7px;
    height: 48px;
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    cursor: pointer;
  }
}

@media screen and (max-width: 1023px) {
  .content {
    max-width: 320px;
  }
}
