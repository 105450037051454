.container {
  box-sizing: border-box;
  padding: 72px 0px;

  > h1 {
    font-family: DM Sans;
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #ffffff;
  }

  > p {
    margin-top: 8px;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--primary-100);
  }
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 48px;

  > button {
    width: 135px;
    height: 44px;
    padding: 10px 24px;
    border-radius: 40px;
    background-color: #f6f6f6;
    cursor: pointer;

    > span {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: var(--primary-600);
    }
  }
}
