.revcontainer {
    width: 100%;
    height: 440px;
    background: rgba(236, 238, 244, 1);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.content>span {
    font-family: "DM Sans";
    padding-top: 40px;
    padding-left: 73px;
    font-size: 29px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    color: rgba(45, 45, 45, 1);
}

.content p {
    font-family: DM Sans;
    padding: 10px 40px;
    font-size: 24px;
    font-weight: 600;
    line-height: 55px;
    text-align: left;
    padding-left: 73px;
}

.suit {
    display: flex;
    justify-content: left;
    padding-left: 73px;
    flex-wrap: wrap;
}

.suit>p {
    margin-top: 30px;
    font-family: DM Sans;
    font-size: 25px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: left;
    padding: 10px;
}

/* Tablet view */
@media (max-width: 768px) {
    .revcontainer {
        height: auto;
        /* Dynamic height for responsiveness */
    }


    .content>span {
        font-family: "DM Sans";
        padding-top: 40px;
        padding-left: 73px;
        font-size: 29px;
        font-weight: 600;
        line-height: 42px;
        text-align: center;
        color: rgba(45, 45, 45, 1);
    }

    .content p {
        font-family: DM Sans;   
        padding: 10px 40px;
        font-size: 24px;
        font-weight: 600;
        line-height: 55px;
        text-align: center;
        padding-left: 73px;
    }



    .suit {
        gap: 16px;
        padding: 0 20px;
        /* Add padding for spacing */
    }

    .suit>p {
        font-size: 22px;
        line-height: 36px;
        margin-top: 20px;
        padding: 8px;
        text-align: center;
        /* Center-align on tablets */
        flex: 1 1 45%;
        /* Allows two items per row */
    }
}

/* Mobile view */
@media (max-width: 480px) {
    .revcontainer {
        height: auto;
        /* Allow dynamic height for flexibility */
    }

    .content>span {
        font-size: 22px;
        line-height: 32px;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .content p {
        font-size: 18px;
        line-height: 36px;
        padding: 10px 15px;
        /* Reduced padding for smaller screens */
    }

    .suit {
        gap: 12px;
        padding: 0 10px;
        /* Add padding for spacing */
        flex-direction: column;
        /* Stack items vertically on small screens */
        align-items: center;
    }

    .suit>p {
        font-size: 20px;
        line-height: 32px;
        margin-top: 15px;
        padding: 5px;
        text-align: center;
        /* Center-align text for mobile */
        width: 100%;
        /* Full width for each item */
    }
}