.container {
  padding: 60px 96px 60px 96px;
  background-color: #f4f7fe;
}

.breadCrumb {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 48px;
}

.breadCrumb > h1 {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #2d2d2d;
}

@media screen and (max-width: 867px) {
  .container {
    padding: 60px 0px;
  }
}
