.container {
  max-width: 1128px;
  margin: auto;
}

.content {
  margin: 72px 0px;

  > h1 {
    font-size: 32px;
    text-align: center;
  }
}

.faqContainer {
  margin-top: 48px;
}

.ruler {
  color: var(--primary-200);
}

.diffUl {
  list-style-type: lower-alpha;

  > li > span {
    font-size: 16px;
    font-weight: 600;
  }
}

.answer9 {
  > p {
    font-size: 16px;
    font-weight: 600;
  }

  > ul {
    list-style-type: decimal;
  }
}

.answer6Table {
  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
  > thead > tr > th {
    text-align: center;
  }
}

.answer12 {
  list-style-type: decimal;

  > ul > li > span {
    font-size: 16px;
    font-weight: 600;
  }
}
@media screen and (max-width: 767px) {
  .container {
    max-width: 1128px;
    margin: auto;
    padding: 0px 16px;
  }
}
