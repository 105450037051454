.DetailedBreakdownContainer {
  border-radius: 32px;
  border: 1px solid #d3dbec;
  padding: 32px;
  margin: 24px 0px;
  background-color: white;
}

.DetailedBreakdownContainer > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Div2Title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.04em;
}

.graphDiv {
  height: 416px;
  border: 1px solid #e3e3e3;
  margin-top: 24px;
  border-radius: 24px;
  padding: 26px 24px;
}

.whatInGraph {
  display: flex;
  gap: 32px;
}

.whatInGraph > div {
  display: flex;
  gap: 8px;
}

.colorBox1,
.colorBox2 {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.colorBox1 {
  background-color: #41558d;
}

.colorBox2 {
  background-color: #82b770;
}

/* Base styles (already provided) */

/* Tablet View (768px and below) */
@media (max-width: 768px) {
  .DetailedBreakdownContainer {
    padding: 24px;
    margin: 16px 0;
    border-radius: 0px;
  }

  .Div2Title {
    font-size: 20px;
    line-height: 28px;
  }

  .graphDiv {
    height: 350px;
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;
  }

  .whatInGraph {
    gap: 16px;
  }

  .colorBox1,
  .colorBox2 {
    height: 16px;
    width: 16px;
    border-radius: 4px;
  }
}

/* Mobile View (480px and below) */
@media (max-width: 480px) {
  .DetailedBreakdownContainer {
    padding: 16px;
    margin: 8px 0;
    border-radius: 0px;
  }

  .Div2Title {
    font-size: 18px;
    line-height: 24px;
  }

  .graphDiv {
    height: 300px;
    padding: 16px;
    border-radius: 16px;
    margin-top: 16px;
  }

  .whatInGraph {
    flex-direction: column;
    gap: 8px;
  }

  .colorBox1,
  .colorBox2 {
    height: 14px;
    width: 14px;
  }

  .colorBox1 {
    background-color: #41558d;
  }

  .colorBox2 {
    background-color: #82b770;
  }
}
