.Contain {
    padding: 46px;

    >div:nth-of-type(1) {
        >p {
            padding-left: 193px;
            font-family: DM Sans;
            font-size: 42px;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: -0.04em;
            text-align: left;
            color: rgba(45, 45, 45, 1);
        }
    }
}

.cardsContainer {
    gap: 16px;
    padding-left: 193px;
    padding-top: 20px;
    display: flex; /* Ensure flexbox layout */
    flex-direction: column;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 348px;
    padding: 16px;
    height: 151px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(190, 190, 190, 1);
}

.freecall {
    display: none;
    /* Default: Hide the button for desktop screens */
    padding-left: 73px;
    margin-top: 20px;
}

.btn:hover {
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(4, 14, 69, 0.2);
}

.btn {
    color: white;
    background-color: rgb(30, 50, 104);
    border-radius: 30px;
    padding: 9px 15px;
    font-size: 22px;
}

.top {
    font-family: DM Sans;
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.04em;
    text-align: left;
}

.cont {
    margin-top: 10px;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.para {
    display: flex;
    flex-direction: column;
}

.imageStyle>p {
    color: white;
    font-weight: 500;
    width: 15px;
    padding: 8px;
    border-radius: 50%;
    background-color: black;
    text-align: center;
}

/* Tablet view */
@media (max-width: 1024px) {
    .Contain {
        padding: 12px;
    }

    .Contain>div:nth-of-type(1)>p {
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        padding-left: 0px;
    }

    .cardsContainer {
        padding-left: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        overflow-x: auto;
    }

    .cards {
        max-width: 320px;
        padding: 14px 19px;
        height: auto;
    }

    .top {
        font-size: 20px;
        line-height: 28px;
    }

    .cont {
        font-size: 14px;
        line-height: 22px;
    }

    .imageStyle>p {
        font-weight: 500;
        text-align: center;
        margin-bottom: 5px;
        padding: 11px;
        border-radius: 50%;
        background-color: black;
    }

    .freecall {
        display: block;
        /* Show the button on tablet screens */
        margin-top: 20px;
    }
}

/* Mobile view */
@media (max-width: 480px) {
    .Contain {
        padding: 20px;
        gap: 32px;
    }

    .Contain>div:nth-of-type(1)>p {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        padding-left: 0px;
    }

    .freecall {
        display: block;
        /* Show the button on mobile screens */
        padding-left: 0px;
        margin-top: 10px;
    }

    .btn {
        width: 100%;
        /* Full width for button */
        padding: 12px 0;
        font-size: larger;
    }

    .cardsContainer {
        padding: 0px;
        gap: 16px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .cards {
        max-width: 300px;
        /* Keep cards wider for scrolling */
        padding: 12px;
        height: auto;
        margin-bottom: 7px;
    }

    .top {
        font-size: 18px;
        margin-top: 15px;
        line-height: 24px;
    }

    .cont {
        font-size: 14px;
        line-height: 20px;
    }
}