.container {
  padding: 72px 0px;
  display: flex;
  justify-content: center;
  gap: 96px;

  > div {
    max-width: 516px;
  }
}

.heading {
  > h1 {
    font-family: DM Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #1b243b;
  }

  > p {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--black-500);
  }
}

.iframe {
  margin-top: 48px;
  background: linear-gradient(
      180deg,
      rgba(0, 36, 26, 0) 75.55%,
      rgba(0, 36, 26, 0.5) 103.89%
    ),
    linear-gradient(
      182.25deg,
      rgba(0, 36, 26, 0.5) 3.44%,
      rgba(0, 36, 26, 0) 30.1%
    );

  box-shadow: 0px 32px 32px -32px #00241a52;
  /* width: 516px;
  height: 290px; */
  /* padding: 24px; */
  border-radius: 24px;
}

.rightContent {
  > div {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  > div:nth-of-type(2) {
    margin: 32px 0px;
  }
}

/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    padding: 48px 16px;
    display: block;
    > div {
      max-width: 100%;
    }
  }

  .iframe {
    margin-top: 48px;
    background: linear-gradient(
        180deg,
        rgba(0, 36, 26, 0) 75.55%,
        rgba(0, 36, 26, 0.5) 103.89%
      ),
      linear-gradient(
        182.25deg,
        rgba(0, 36, 26, 0.5) 3.44%,
        rgba(0, 36, 26, 0) 30.1%
      );

    box-shadow: 0px 32px 32px -32px #00241a52;
    width: 100%;
    /* height: 400px; */
    /* padding: 24px; */
    border-radius: 24px;
  }

  .rightContent {
    margin-top: 48px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
    }

    > div:nth-of-type(2) {
      margin: 32px 0px;
    }
  }
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .container {
    padding: 48px 16px;
    display: block;
    > div {
      max-width: 100%;
    }
  }

  .iframe {
    margin-top: 48px;
    background: linear-gradient(
        180deg,
        rgba(0, 36, 26, 0) 75.55%,
        rgba(0, 36, 26, 0.5) 103.89%
      ),
      linear-gradient(
        182.25deg,
        rgba(0, 36, 26, 0.5) 3.44%,
        rgba(0, 36, 26, 0) 30.1%
      );

    box-shadow: 0px 32px 32px -32px #00241a52;
    width: 100%;
    /* height: 300px; */
    /* padding: 24px; */
    border-radius: 24px;
  }

  .rightContent {
    margin-top: 48px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
    }

    > div:nth-of-type(2) {
      margin: 32px 0px;
    }
  }
}
