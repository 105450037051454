/* Base Styles for Mobile and Tablets */
.container {
    width: 90%;
    max-width: 735px;
    margin-left: 49px;
    /* Default margin for smaller screens */
    height: 532px;
}

.content {
    height: 38px;
    padding: 72px 0px;

    >h1 {
        text-align: left;
        height: 94px;
        font-size: 32px;
    }
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 48px;
    height: 49px;
}

@media screen and (min-width: 768px) and (max-width: 1255px) {
    .container {
        width: 90%;
        margin: auto;
        height: 532px;
        padding-left: 0px;
    }

    .content>h1 {
        text-align: left;
        height: 82px;
        font-size: 26px;
        padding-right: 200px;
    }

    .cardContainer {
        display: flex;
        flex-direction: column;
        margin-top: 48px;
        padding-left: 73px;
        height: 492px;
        gap: 24px;
        overflow: scroll;
    }

    .cardContainer::-webkit-scrollbar {
        display: none;
    }
}

/* Desktop and larger screens (above 1255px) */
@media screen and (min-width: 1256px) {
    .container {
        width: 90%;
        max-width: 735px;
        margin-left: 173px;
        /* Margin for desktops */
        height: 532px;
    }
}

@media screen and (min-width: 1890px) {
    .cardContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        height: 300px;
    }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
    .container {
        width: 83%;
        max-width: 1128px;
    }

    .content>h1 {
        text-align: start;
    }

    .cardContainer {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 48px;
        height: 492px;
        gap: 24px;
        overflow: scroll;
        scrollbar-width: none;
    }

    .cardContainer::-webkit-scrollbar {
        display: none;
    }
}